/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { find, flatMap } from 'lodash'

import { ElasticsearchClusterPlan } from '@modules/cloud-api/v1/types'
import type {
  ApmPayload,
  ApmPlan,
  ApmPlanInfo,
  ApmResourceInfo,
  ApmTopologyElement,
  AppSearchPayload,
  AppSearchPlan,
  AppSearchPlanInfo,
  AppSearchResourceInfo,
  AppSearchTopologyElement,
  DeploymentCreateRequest,
  DeploymentCreateResources,
  DeploymentGetResponse,
  DeploymentResources,
  DeploymentUpdateRequest,
  ElasticsearchClusterPlanInfo,
  ElasticsearchClusterSettings,
  ElasticsearchClusterTopologyElement,
  ElasticsearchPayload,
  ElasticsearchResourceInfo,
  EnterpriseSearchPayload,
  EnterpriseSearchResourceInfo,
  EnterpriseSearchTopologyElement,
  IntegrationsServerPayload,
  KibanaClusterPlan,
  KibanaClusterPlanInfo,
  KibanaClusterTopologyElement,
  KibanaPayload,
  KibanaResourceInfo,
} from '@modules/cloud-api/v1/types'
import { AnyResourceInfo } from '@modules/ui-types'
import type {
  AnyClusterPlanInfo,
  AnyPayload,
  AnyPlan,
  AnyPlanConfiguration,
  AnyTopologyElement,
  ResourceInfoForSliderInstanceType,
  SliderInstanceType,
  StackDeployment,
  VersionNumber,
} from '@modules/ui-types'
import { keysOf } from '@modules/ts-utils'
import { getPlatform } from '@modules/utils/platform'

import { getSupportedSliderInstanceTypes } from '../../sliders/support'
import { isEnabledConfiguration } from '../../deployments/conversion'
import { getAllKnownSliderInstanceTypes } from '../../sliders/sliders'

import { getEsPlan, getSliderResources } from './stackDeployment'

export type PlanState =
  | 'best_effort'
  | 'current'
  | 'genesis'
  | 'last_attempt'
  | 'last_history_attempt'
  | 'last_success'
  | 'most_recent'
  | 'pending'
  | 'previous_success'

interface GetTopology {
  (params: { resource: ElasticsearchResourceInfo }): ElasticsearchClusterTopologyElement[]
  (params: { resource: KibanaResourceInfo }): KibanaClusterTopologyElement[]
  (params: { resource: ApmResourceInfo }): ApmTopologyElement[]
  (params: { resource: AppSearchResourceInfo }): AppSearchTopologyElement[]
  (params: { resource: EnterpriseSearchResourceInfo }): EnterpriseSearchTopologyElement[]
  (params: { resource: any }): any[]
}

interface GetResources {
  (params: {
    deployment: DeploymentCreateRequest | DeploymentUpdateRequest
    resourceTypes?: string[]
  }): AnyPayload[]
  (params: { deployment: StackDeployment; resourceTypes?: string[] }): AnyResourceInfo[]
}

type ClusterTopologies =
  | ElasticsearchClusterTopologyElement[]
  | KibanaClusterTopologyElement[]
  | ApmTopologyElement[]
  | AppSearchTopologyElement[]
  | EnterpriseSearchTopologyElement[]

export type Resources =
  | ElasticsearchPayload
  | KibanaPayload
  | ApmPayload
  | AppSearchPayload
  | EnterpriseSearchPayload
  | IntegrationsServerPayload

export function getVersion({
  deployment,
}: {
  deployment: Pick<DeploymentGetResponse, 'resources'>
}): string | null {
  const lastPlanAttempt = getEsPlanFromGet({
    deployment,
    state: `best_effort`,
  })

  const version = getPlanVersion({ plan: lastPlanAttempt })

  if (version) {
    return version
  }

  // If the above method failed, e.g. in the case of a terminated deployment with
  // no effective ES resource, try to return the last version that was in use.
  const resource = maybeGetFirstEsClusterFromGet({ deployment })

  if (resource == null) {
    return null
  }

  const mustMatch = (planInfo) => Boolean(planInfo.plan && getPlanVersion({ plan: planInfo.plan }))

  // try last healthy plan
  const lastHealthyVersionedPlan = _getPlanInfoFromHistory({
    resource,
    mustMatch,
  })

  const lastHealthyVersion = getPlanVersion({ plan: lastHealthyVersionedPlan?.plan })

  if (lastHealthyVersion) {
    return lastHealthyVersion
  }

  // last resort: last unhealthy plan
  const lastUnhealthyVersionedPlan = _getPlanInfoFromHistory({
    resource,
    mustMatch,
    mustHealthy: false,
  })

  const lastUnhealthyVersion = getPlanVersion({ plan: lastUnhealthyVersionedPlan?.plan })

  return lastUnhealthyVersion || null
}

export function getVersionOnCreate({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): string | null {
  if (!deployment) {
    return null
  }

  const plan = getEsPlan({
    deployment,
  })

  return getPlanVersion({ plan })
}

export function getKibanaVersion({
  deployment,
}: {
  deployment: {
    resources?: DeploymentResources
  }
}): string | null {
  const { resources } = deployment
  const [resource] = resources?.kibana || []

  return getResourceVersion({ resource })
}

export function getResourceVersion({
  resource,
}: {
  resource: AnyResourceInfo | null | undefined
}): string | null {
  if (!resource) {
    return null
  }

  const planInfo = getPlanInfo({
    resource,
    state: `best_effort`,
  }) as AnyClusterPlanInfo | null

  return getPlanVersion({ plan: planInfo?.plan })
}

export function getPlanVersion({
  plan,
}: {
  plan: AnyPlan | null | undefined
}): VersionNumber | null {
  if (!plan) {
    return null
  }

  // Brute-forcing the lookup here to avoid constantly having to pass instance
  // types around when we know each resource is dedicated to one.
  for (const sliderInstanceType of getAllKnownSliderInstanceTypes()) {
    if (plan[sliderInstanceType]) {
      return (plan[sliderInstanceType].version as VersionNumber) || null
    }
  }

  return null
}

export function getCloudId({
  deployment,
}: {
  deployment: {
    resources?: DeploymentResources
  }
}): string | undefined {
  const esCluster = getFirstEsClusterFromGet({ deployment })

  if (!esCluster) {
    return
  }

  return esCluster.info.metadata.cloud_id
}

export function getClusterPlanInfo({
  deployment,
  sliderInstanceType,
  state = `current`,
}: {
  deployment: Pick<DeploymentGetResponse, 'resources'>
  sliderInstanceType: SliderInstanceType
  state?: PlanState
}): AnyClusterPlanInfo | null {
  const resource = getFirstSliderClusterFromGet({ deployment, sliderInstanceType })

  if (!resource) {
    return null
  }

  const planInfo = getPlanInfo({ resource, state })

  return planInfo
}

export function getSliderPlanFromGet<TPlan extends AnyPlan>({
  deployment,
  sliderInstanceType,
  state = `current`,
}: {
  deployment: Pick<DeploymentGetResponse, 'resources'>
  sliderInstanceType: SliderInstanceType
  state?: PlanState
}): TPlan | null {
  const planInfo = getClusterPlanInfo({
    deployment,
    sliderInstanceType,
    state,
  })

  if (planInfo === null) {
    return null
  }

  const plan = getPlanFromPlanInfo(planInfo) as TPlan | null

  return plan
}

export function getEsPlanFromGet({
  deployment,
  state = `current`,
}: {
  deployment: Pick<DeploymentGetResponse, 'resources'>
  state?: PlanState
}): ElasticsearchClusterPlan | null {
  return getSliderPlanFromGet<ElasticsearchClusterPlan>({
    deployment,
    state,
    sliderInstanceType: `elasticsearch`,
  })
}

export function getSourceAction({ resource }: { resource: AnyResourceInfo }) {
  return resource.info.plan_info.pending?.source?.action
}

function getPlanFromPlanInfo(planInfo?: AnyClusterPlanInfo): AnyPlan | null
function getPlanFromPlanInfo(
  planInfo?: ElasticsearchClusterPlanInfo,
): ElasticsearchClusterPlan | null
function getPlanFromPlanInfo(planInfo?: KibanaClusterPlanInfo): KibanaClusterPlan | null
function getPlanFromPlanInfo(planInfo?: ApmPlanInfo): ApmPlan | null
function getPlanFromPlanInfo(planInfo?: AppSearchPlanInfo): AppSearchPlan | null
function getPlanFromPlanInfo(planInfo?: AnyClusterPlanInfo): AnyPlan | null {
  if (!planInfo) {
    return null
  }

  if (!planInfo.plan) {
    return null
  }

  return planInfo.plan
}

export function maybeGetFirstEsClusterFromGet({
  deployment,
}: {
  deployment: {
    resources?: DeploymentResources
  }
}): ElasticsearchResourceInfo | undefined {
  return deployment.resources?.elasticsearch?.[0]
}

export function getFirstEsClusterFromGet(params: {
  deployment: { resources?: DeploymentResources }
}): ElasticsearchResourceInfo | null {
  const firstEs = maybeGetFirstEsClusterFromGet(params)

  return firstEs || null
}

export function getFirstSliderClusterFromGet<T extends SliderInstanceType>({
  deployment,
  sliderInstanceType,
}: {
  deployment: Pick<DeploymentGetResponse, 'resources'>
  sliderInstanceType?: T
}): ResourceInfoForSliderInstanceType<T> | null {
  const resources = getSliderResources({ deployment, sliderInstanceType })
  return resources?.[0] || null
}

// intended for `DeploymentGetResponse`, `DeploymentSearchResponse` objects
export function maybeGetRegionId(params: {
  deployment: { resources?: DeploymentResources }
}): string | undefined {
  return maybeGetFirstEsClusterFromGet(params)?.region
}

// intended for `DeploymentGetResponse`, `DeploymentSearchResponse` objects
export function getRegionId(params: { deployment: { resources?: DeploymentResources } }): string {
  const regionId = maybeGetRegionId(params)

  if (regionId == null) {
    throw new Error(`Failed to get region ID from deployment`)
  }

  return regionId
}

// intended for `DeploymentCreateRequest` objects
export function getRegionIdForCreate({
  deployment,
}: {
  deployment: {
    resources?: DeploymentCreateResources
  }
}): string | undefined {
  const esResources = deployment.resources?.elasticsearch

  if (!esResources) {
    return undefined
  }

  return esResources[0]?.region
}

// intended for `DeploymentCreateResponse`, `DeploymentUpdateResponse` objects

export function getPlatformId({
  deployment,
}: {
  deployment: {
    id: string
    resources?: DeploymentResources
  }
}): string | null {
  const regionId = getRegionId({ deployment })

  if (regionId === null) {
    return null
  }

  return getPlatform(regionId)
}

export const getPlanInfo = <T extends AnyResourceInfo>({
  resource,
  state = `current`,
}: {
  resource: T
  state?: PlanState
}):
  | Exclude<T['info']['plan_info']['current'], undefined>
  | Exclude<T['info']['plan_info']['pending'], undefined>
  | T['info']['plan_info']['history'][number]
  | null => {
  /*
   * The `best_effort` selector is most useful when you need a best effort result.
   * For example, when we want to ascertain the version for a deployment, the best option
   * would be to look at the last successful plan. A second choice might be looking
   * at the last unsuccessful attempt, like in the case of failed genesis plans.
   * A third choice would be the currently pending plan, which hasn't even finalized yet.
   */
  if (state === `best_effort`) {
    return (
      getPlanInfo({ resource, state: `last_success` }) ||
      getPlanInfo({ resource, state: `last_attempt` }) ||
      getPlanInfo({ resource, state: `pending` })
    )
  }

  const planInfos = resource.info.plan_info as T['info']['plan_info']

  if (state === `most_recent`) {
    const pendingPlan = planInfos.pending

    if (pendingPlan) {
      return pendingPlan
    }

    return getPlanInfo({ resource, state: `last_success` })
  }

  if (state === `last_success`) {
    const currentPlan = planInfos.current

    if (currentPlan && currentPlan.healthy) {
      return currentPlan
    }

    return _getPlanInfoFromHistory({ resource })
  }

  if (state === `previous_success`) {
    const currentPlan = planInfos.current as AnyClusterPlanInfo

    return _getPlanInfoFromHistory({
      resource,
      mustMatch: (planInfo) =>
        // exclude current plan
        !currentPlan.plan_attempt_id || planInfo.plan_attempt_id !== currentPlan.plan_attempt_id,
    })
  }

  if (state === `last_attempt`) {
    const currentPlan = planInfos.current

    if (currentPlan && !currentPlan.healthy) {
      return currentPlan
    }

    return getPlanInfo({ resource, state: `last_history_attempt` })
  }

  if (state === `last_history_attempt`) {
    return _getPlanInfoFromHistory({ resource, mustHealthy: false })
  }

  if (state === `genesis`) {
    const genesisPlanFromHistory = _getPlanInfoFromHistory({ resource, matchOldestFirst: true })

    if (genesisPlanFromHistory) {
      return genesisPlanFromHistory
    }

    const pendingPlan = planInfos.pending

    return pendingPlan || null
  }

  if (state === `pending`) {
    return planInfos.pending || null
  }

  if (state === `current`) {
    return planInfos.current || null
  }

  return null
}

export function _getPlanInfoFromHistory<T extends AnyResourceInfo>({
  resource,
  mustHealthy = true,
  mustMatch,
  matchOldestFirst = false,
}: {
  resource: T
  mustHealthy?: boolean
  mustMatch?: (planInfo: T['info']['plan_info']['history'][number]) => boolean
  matchOldestFirst?: boolean
}): T['info']['plan_info']['history'][number] | null {
  const plans = resource.info.plan_info.history
  const plan = matchOldestFirst
    ? [...plans].find((p) => matchesCriteria(p))
    : [...plans].reverse().find((p) => matchesCriteria(p))

  return plan || null

  function matchesCriteria(p: T['info']['plan_info']['history'][number]): boolean {
    const healthMatch = mustHealthy !== true || p.healthy
    const clauseMatch = mustMatch === undefined || mustMatch(p)
    return healthMatch && clauseMatch
  }
}

export const getTopology: GetTopology = ({ resource }) => {
  const planInfo = resource.info.plan_info.current

  if (!planInfo) {
    return []
  }

  const { plan } = planInfo

  if (!plan) {
    return []
  }

  return plan.cluster_topology || []
}

export const getTemplateTopology = ({ resource }: { resource: Resources }): ClusterTopologies => {
  const plan = resource.plan

  if (!plan) {
    return []
  }

  return plan.cluster_topology || []
}

export const getSizedTopology: GetTopology = ({ resource }) =>
  getTopology({ resource }).filter(isEnabledConfiguration)

export function hasSizedSliderResource({
  deployment,
  resourceType,
}: {
  deployment: StackDeployment
  resourceType: SliderInstanceType
}): boolean {
  const resources: AnyResourceInfo[] = deployment.resources[resourceType]
  return resources.some((resource) => isSizedSliderResource({ resource }))
}

export function isSizedSliderResource({ resource }: { resource: AnyResourceInfo }): boolean {
  const planInfo = resource.info.plan_info.current

  if (!planInfo) {
    return false
  }

  const { plan } = planInfo

  if (!plan) {
    return false
  }

  return isSizedPlan(plan)
}

export function isSizedPlan(plan: AnyPlan): boolean {
  const nodeConfigurations = plan.cluster_topology
  return isSizedTopology(nodeConfigurations)
}

function isSizedTopology(nodeConfigurations: AnyTopologyElement[] | undefined): boolean {
  return nodeConfigurations?.some(isEnabledConfiguration) ?? false
}

export function isSliderPlanActive(
  plan: AnyClusterPlanInfo | null,
  sliderInstanceType: SliderInstanceType,
): boolean {
  const configuration: AnyPlanConfiguration = plan?.plan?.[sliderInstanceType]

  if (!configuration) {
    return false
  }

  return configuration.version !== null
}

export const getResources: GetResources = ({ deployment, resourceTypes }) => {
  const sliderInstanceTypes = Array.isArray(resourceTypes)
    ? resourceTypes
    : Object.keys(deployment.resources)

  return flatMap(
    sliderInstanceTypes,
    (sliderInstanceType) => deployment.resources[sliderInstanceType],
  )
}

export const getResourceByRefId = ({
  deployment,
  resourceType,
  refId,
}: {
  deployment: StackDeployment
  resourceType: SliderInstanceType
  refId: string
}): AnyResourceInfo | undefined =>
  find<AnyResourceInfo>(deployment.resources[resourceType], { ref_id: refId })

export const getFirstResourceType = ({
  deployment,
}: {
  deployment: Pick<DeploymentGetResponse, 'resources'>
}): SliderInstanceType =>
  // We assume that any code that reaches this point would have exploded by now if there were no resources
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  keysOf(deployment.resources)[0]!

export function getDeploymentResources({
  deployment,
}: {
  deployment: Pick<DeploymentGetResponse, 'resources'>
}): AnyResourceInfo[] {
  return flatMap(
    getSupportedSliderInstanceTypes(),
    (sliderInstanceType) => getSliderResources({ deployment, sliderInstanceType }) || [],
  )
}

export function getDeploymentSettingsFromGet({
  deployment,
}: {
  deployment: StackDeployment
}): ElasticsearchClusterSettings | null {
  const esCluster = getFirstEsClusterFromGet({ deployment })

  if (esCluster === null) {
    return null
  }

  return esCluster.info.settings || null
}

export function isPlanSystemInitiated({
  planAttempt,
}: {
  planAttempt: AnyClusterPlanInfo | null
}): boolean {
  if (!planAttempt || !planAttempt.source) {
    return false
  }

  return !planAttempt.source?.user_id
}
