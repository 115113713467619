/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { useIntl } from 'react-intl'
import { useField } from 'formik'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCode,
  EuiFlexGroup,
  EuiPanel,
  EuiSpacer,
  EuiText,
  useEuiTheme,
} from '@elastic/eui'

import type { RoleMappingRule } from '@modules/cloud-api/v1/types'

import { ErrorPrompt } from '@/components/Security/Components/ErrorPrompt'
import SecurityDocsLink from '@/components/Security/SecurityDocsLink'

import { messages } from './messages'
import MappingValue from './MappingValue'

import type { SpecificRuleError } from '../types'

const MappingRules = () => {
  const { euiTheme } = useEuiTheme()
  const { formatMessage } = useIntl()
  const [ruleField, ruleMeta, ruleHelper] = useField<RoleMappingRule>('rule')

  const ruleError = typeof ruleMeta.error === 'string' ? ruleMeta.error : undefined
  const mappingRuleType = ruleField.value.any ? 'any' : 'all'
  const ruleValueArray = ruleField.value.all || ruleField.value.any

  const onAddValue = () => {
    ruleHelper.setValue({
      [mappingRuleType]: [...(ruleValueArray || []), { group: '' }],
    })
  }

  const onRemoveValue = (index: number) => {
    if (!ruleValueArray) {
      return
    }

    ruleValueArray.splice(index, 1)

    if (ruleValueArray.length === 0) {
      ruleHelper.setValue({})
    } else {
      ruleHelper.setValue({
        [mappingRuleType]: [...ruleValueArray],
      })
    }

    // If a mapping rule has error, when removing that rule, the error will be removed too
    const ruleFieldError = ruleMeta.error

    if (ruleFieldError && typeof ruleFieldError === 'object') {
      const ruleValuesErrors = (ruleFieldError as SpecificRuleError)[mappingRuleType]

      if (ruleValuesErrors && ruleValuesErrors[index]) {
        ruleValuesErrors.splice(index, 1)
        ruleHelper.setError(ruleFieldError)
      }
    }
  }

  const onChangeRuleType = () => {
    const newMappingRuleType = mappingRuleType === 'all' ? 'any' : 'all'
    ruleHelper.setValue({
      [newMappingRuleType]: ruleValueArray || [],
    })
  }

  const isRuleEmpty = !ruleValueArray || ruleValueArray.length === 0

  return (
    <EuiPanel hasShadow={false} hasBorder={true}>
      <EuiText>
        <h2>{formatMessage(messages.mappingRulesTitle)}</h2>
        <span>
          {formatMessage(messages.mappingRulesDescription, {
            code: (msg) => <EuiCode>{msg}</EuiCode>,
            lm: (msg) => <SecurityDocsLink fragment='ec_role_mappings'>{msg}</SecurityDocsLink>,
          })}
        </span>
      </EuiText>
      <EuiSpacer size='l' />
      {ruleError && <ErrorPrompt message={ruleError} />}
      {isRuleEmpty && <NoRules onClick={onAddValue} />}
      {!isRuleEmpty && (
        <EuiPanel
          hasShadow={false}
          hasBorder={true}
          style={{ backgroundColor: euiTheme.colors.lightestShade }}
        >
          <div style={{ paddingLeft: '8px' }}>
            <EuiButtonEmpty iconSide='right' iconType='arrowDown' onClick={onChangeRuleType}>
              {formatMessage(messages[`ruleType-${mappingRuleType}`])}
            </EuiButtonEmpty>
            <EuiSpacer />
            {ruleValueArray.map((_groupRule, index) => (
              <MappingValue
                ruleType={mappingRuleType}
                index={index}
                removeValue={onRemoveValue}
                key={index}
              />
            ))}
            <EuiSpacer />
            <EuiButtonEmpty onClick={onAddValue} iconType='plusInCircle'>
              {formatMessage(messages.add)}
            </EuiButtonEmpty>
          </div>
        </EuiPanel>
      )}

      <EuiSpacer size='l' />
    </EuiPanel>
  )
}

const NoRules = ({ onClick }: { onClick: () => void }) => {
  const { formatMessage } = useIntl()
  return (
    <EuiFlexGroup direction='column' alignItems='center'>
      <EuiSpacer size='s' />
      <EuiText>
        <h4>{formatMessage(messages.noRulesDefined)}</h4>
      </EuiText>
      <EuiText color='subdued'>
        <span>{formatMessage(messages.noRulesDefinedDescription)}</span>
      </EuiText>
      <EuiButton fill={true} onClick={onClick}>
        {formatMessage(messages.addRules)}
      </EuiButton>
    </EuiFlexGroup>
  )
}

export default MappingRules
