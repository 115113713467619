/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { createSaasActivatedUser } from '@/actions/saasUsers'
import { checkForOktaAndHandleLogin, loginAndRedirect } from '@/actions/auth'
import { getConfigForKey } from '@/selectors'
import { getMfa } from '@/reducers/auth'

import ServerlessUserRegistration from './ServerlessUserRegistration'

import type { ReduxState, ThunkDispatch } from '@/types/redux'
import type { DispatchProps, ConsumerProps, StateProps } from './types'

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const { search } = location
  return {
    locationQueryString: search,
    googleTrackingIdV4Enabled: getConfigForKey(state, `GOOGLE_ANALYTICS_TRACKING_ID_V4`) !== null,
    mfa: getMfa(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  createUser: (user) => dispatch(createSaasActivatedUser(user)),
  loginAndRedirect: ({ email, password, redirectTo, oktaRedirectUrl }) =>
    dispatch(loginAndRedirect({ oktaRedirectUrl, redirectTo, credentials: { email, password } })),
  onMfaSuccess: (args) => dispatch(checkForOktaAndHandleLogin(args)),
})

export default withRouter(
  withLDConsumer()(
    connect<StateProps, DispatchProps, ConsumerProps>(
      mapStateToProps,
      mapDispatchToProps,
    )(ServerlessUserRegistration),
  ),
)
