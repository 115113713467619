/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { getRegistrationSource } from '@/lib/urlUtils'
import {
  createPendingSaasUser,
  resetCreatePendingSassUserRequest,
  resetCreateSassUserRequest,
} from '@/actions/saasUsers'
import {
  getLoginRequest,
  createSaasUserRequest,
  createMarketplaceUserRequest,
} from '@/reducers/asyncRequests/registry'
import { getTheme } from '@/reducers/theme'

import UserRegistrationForm from './UserRegistrationForm'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

export const formId = 'cloud-signup-form'

const mapStateToProps = (
  state: ReduxState,
  { location, showSocialLogin = true, isGovCloud }: ConsumerProps,
): StateProps => {
  const { search } = location

  return {
    formId,
    theme: getTheme(state),
    source: getRegistrationSource(search),
    createUserRequest: createSaasUserRequest(state),
    loginRequest: getLoginRequest(state),
    showSocialLogin: showSocialLogin && !isGovCloud,
    createMarketplaceUserRequest: createMarketplaceUserRequest(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  createPendingSaasUser: (user) => dispatch(createPendingSaasUser(user)),
  resetCreatePendingSassUserRequest: () => dispatch(resetCreatePendingSassUserRequest()),
  resetCreateSassUserRequest: () => dispatch(resetCreateSassUserRequest()),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(UserRegistrationForm),
)
