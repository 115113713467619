/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useField } from 'formik'

import { EuiCallOut, EuiSpacer, EuiText } from '@elastic/eui'

import { useGetRoleMappingsQuery } from '@modules/security-idp-lib/hooks'
import type { RoleMapping } from '@modules/cloud-api/v1/types'

import type { ReactElement } from 'react'
import type { OrgOwnerMappingError } from '../types'

interface CalloutContext {
  title: ReactElement
  description: ReactElement
  color: 'primary' | 'danger'
  iconType: 'iInCircle' | 'alert'
}

const calloutContexts: Record<string, CalloutContext> = {
  firstRoleMapping: {
    title: (
      <FormattedMessage
        id='organization.security.role-mapping.org-owner-mapping.first-role-mapping-title'
        defaultMessage='Your first role mapping must include the "Organization owner" role'
      />
    ),
    description: (
      <FormattedMessage
        id='organization.security.role-mapping.org-owner-mapping.first-role-mapping-description'
        defaultMessage='Each organization requires at least one user with the Organization owner role to ensure continuous access and control over your organization settings. To avoid losing that access, the first role mapping of your SAML SSO configuration must include the "Organizational owner" role.'
      />
    ),
    color: 'primary',
    iconType: 'iInCircle',
  },
  FIRST_MAPPING_MUST_BE_ORG_OWNER: {
    title: (
      <FormattedMessage
        id='organization.security.role-mapping.org-owner-mapping.first-role-mapping-must-be-org-owner-title'
        defaultMessage='Missing "Organization owner" role'
      />
    ),
    description: (
      <FormattedMessage
        id='organization.security.role-mapping.org-owner-mapping.first-role-mapping-must-be-org-owner-description'
        defaultMessage='Your role mapping cannot be saved because it does not include the "Organization owner" role. To ensure continuous access and control over your organizational settings, you must assign the "Organization owner" role for your first role mapping.'
      />
    ),
    color: 'danger',
    iconType: 'alert',
  },
  CANNOT_REMOVE_LAST_ORG_OWNER_MAPPING: {
    title: (
      <FormattedMessage
        id='organization.security.role-mapping.org-owner-mapping.cannot-remove-last-org-owner-mapping-title'
        defaultMessage='Cannot remove the last "Organization owner" role mapping'
      />
    ),
    description: (
      <FormattedMessage
        id='organization.security.role-mapping.org-owner-mapping.cannot-remove-last-org-owner-mapping-description'
        defaultMessage='Your role mapping cannot be saved because this action would delete the last "Organization owner" role mapping. To ensure continuous access and control over your organizational settings, you must keep at least one enabled role mapping with the "Organization owner" role.'
      />
    ),
    color: 'danger',
    iconType: 'alert',
  },
  CANNOT_DISABLE_ORG_OWNER_LAST_MAPPING: {
    title: (
      <FormattedMessage
        id='organization.security.role-mapping.org-owner-mapping.cannot-disable-last-org-owner-mapping-title'
        defaultMessage='Cannot disable the last "Organization owner" role mapping'
      />
    ),
    description: (
      <FormattedMessage
        id='organization.security.role-mapping.org-owner-mapping.cannot-disable-last-org-owner-mapping-description'
        defaultMessage='Your role mapping cannot be saved because this action would disable the last "Organization owner" role mapping. To ensure continuous access and control over your organizational settings, you must keep at least one enabled role mapping with the "Organization owner" role.'
      />
    ),
    color: 'danger',
    iconType: 'alert',
  },
}

const getCalloutDetails = ({
  existingRoleMappings,
  error,
}: {
  existingRoleMappings: RoleMapping[]
  error: OrgOwnerMappingError | undefined
}) => {
  if (existingRoleMappings.length === 0 && !error) {
    return calloutContexts.firstRoleMapping
  }

  return calloutContexts[error || '']
}

const OrgOwnerMappingCallout = ({ organizationId }: { organizationId: string }) => {
  const { data: roleMappingsResponse } = useGetRoleMappingsQuery(organizationId)
  const [_, missingOrgOwnerMappingMeta] = useField('missingOrgOwnerMapping')
  const calloutContext = getCalloutDetails({
    existingRoleMappings: roleMappingsResponse?.mappings || [],
    error: missingOrgOwnerMappingMeta.error as OrgOwnerMappingError | undefined,
  })

  if (!calloutContext) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment />
  }

  const { title, description, color, iconType } = calloutContext

  return (
    <div>
      <EuiCallOut title={title} color={color} iconType={iconType} size='s'>
        <EuiText size='s'>{description}</EuiText>
      </EuiCallOut>
      <EuiSpacer size='l' />
    </div>
  )
}

export default OrgOwnerMappingCallout
