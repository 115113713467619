/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import {
  createSaasUserUrl,
  getSaasUserUrl,
  postActivateMarketplaceUrl,
  postSubscribeMarketplaceUrl,
  requestSaasUserDeletionUrl,
  searchSaasUsersUrl,
} from '@modules/cloud-api/v1/urls'
import type { ScrubSaasUserUrlParams } from '@modules/cloud-api/v1/urls'
import type {
  CreateSaasUserRequest,
  MarketplaceActivateRequest,
  MarketplaceActivationResponse,
  MarketplaceSubscribeRequest,
  SaasUpdateProfileRequestData,
  SaasUserResponse,
  SaasUsersSearchRequestData,
} from '@modules/cloud-api/v1/types'
import type { UserProfile, UserSubscription } from '@modules/ui-types'
import type { MarkRequired } from '@modules/ts-essentials'

import { createOrganization } from '@/actions/organizations'
import {
  ACTIVATE_MARKETPLACE_USER,
  CREATE_MARKETPLACE_USER,
  CREATE_PENDING_SAAS_USER,
  CREATE_SAAS_USER,
  FETCH_SAAS_USER,
  SCRUB_SAAS_USER,
  SUBSCRIBE_MARKETPLACE_USER,
  UPDATE_SAAS_USER_PROFILE,
} from '@/constants/actions'
import {
  REQUEST_USER_DELETION,
  RESET_SEARCH_USERS,
  SEARCH_USERS,
  SET_USER_SEARCH_TERM,
  SET_USER_SEARCH_TYPE,
} from '@/apps/adminconsole/constants/actions'
import { shouldCreateOrganization } from '@/lib/organizations'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'

import type { ThunkDispatch, AsyncAction, Action, ThunkAction } from '@/types/redux'

export function fetchSaasUser(userId: string) {
  const url = getSaasUserUrl({ userId })

  return asyncRequest({
    type: FETCH_SAAS_USER,
    url,
    meta: { userId },
    crumbs: [userId],
    handleUnauthorized: true,
  })
}

export function updateSaasUserProfile(userId: number, updates: SaasUpdateProfileRequestData) {
  const url = `api/v1/saas/users/${userId}/profile`

  return asyncRequest({
    type: UPDATE_SAAS_USER_PROFILE,
    method: `PUT`,
    url,
    meta: { userId },
    crumbs: [String(userId)],
    payload: updates,
    handleUnauthorized: true,
  })
}

export function createSaasUser(
  payload: MarkRequired<CreateSaasUserRequest, 'captcha_token'>,
): ThunkAction<Promise<AsyncAction<'CREATE_SAAS_USER', SaasUserResponse>>> {
  const url = createSaasUserUrl()

  return asyncRequest<typeof CREATE_SAAS_USER, SaasUserResponse>({
    type: CREATE_SAAS_USER,
    method: 'POST',
    url,
    payload,
  })
}
export const resetCreateSassUserRequest = () => resetAsyncRequest(CREATE_SAAS_USER)

// ts-unused-exports:disable-next-line
export function createSaasActivatedUser(
  payload: MarkRequired<CreateSaasUserRequest, 'captcha_token'>,
): ThunkAction<Promise<AsyncAction<'CREATE_SAAS_USER', SaasUserResponse>>> {
  const url = createSaasUserUrl({ activate: true })

  return asyncRequest<typeof CREATE_SAAS_USER, SaasUserResponse>({
    type: CREATE_SAAS_USER,
    method: 'POST',
    url,
    payload,
  })
}

export function createPendingSaasUser(
  payload: MarkRequired<CreateSaasUserRequest, 'captcha_token'>,
) {
  const url = createSaasUserUrl({ activate: false })

  return asyncRequest({
    type: CREATE_PENDING_SAAS_USER,
    method: 'POST',
    url,
    payload,
  })
}

export const resetCreatePendingSassUserRequest = () => resetAsyncRequest(CREATE_PENDING_SAAS_USER)

export function scrubSaasUser({ userId }: ScrubSaasUserUrlParams) {
  const url = `api/v1/saas/users/${userId}/_scrub`

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: SCRUB_SAAS_USER,
        method: 'POST',
        url,
        crumbs: [userId],
      }),
    ).then(({ payload }) => {
      dispatch({
        type: FETCH_SAAS_USER,
        payload,
        meta: { userId },
        crumbs: [userId],
      })
    })
}

export function requestUserDeletion(userId: string) {
  const url = requestSaasUserDeletionUrl({ userId })
  return asyncRequest({
    type: REQUEST_USER_DELETION,
    method: 'POST',
    url,
    meta: { userId },
    crumbs: [userId],
  })
}

export function subscribeMarketplaceUser(payload: {
  partner: NonNullable<UserSubscription>
  token: string
  profile: UserProfile
}) {
  const { profile, ...rest } = payload

  const url = postSubscribeMarketplaceUrl()

  return (dispatch: ThunkDispatch) => {
    if (shouldCreateOrganization({ profile })) {
      return dispatch(createOrganization({})).then(() =>
        dispatch(subscribeMarketplaceUserCall(rest)),
      )
    }

    return dispatch(subscribeMarketplaceUserCall(rest))
  }

  function subscribeMarketplaceUserCall(payload: MarketplaceSubscribeRequest) {
    return asyncRequest({
      type: SUBSCRIBE_MARKETPLACE_USER,
      method: 'POST',
      url,
      payload,
    })
  }
}

export function activateMarketplaceUser(
  payload: MarkRequired<MarketplaceActivateRequest, 'token'>,
  requestSettings?: any,
) {
  const url = postActivateMarketplaceUrl()

  return (dispatch: ThunkDispatch) =>
    dispatch(
      asyncRequest<typeof ACTIVATE_MARKETPLACE_USER, MarketplaceActivationResponse>({
        type: ACTIVATE_MARKETPLACE_USER,
        method: 'POST',
        url,
        payload,
        requestSettings,
        resetApmTransaction: false,
      }),
    )
}

export function createMarketplaceUser(
  payload: MarkRequired<CreateSaasUserRequest, 'captcha_token'>,
) {
  return (dispatch: ThunkDispatch) => {
    const url = createSaasUserUrl({ activate: true })

    return dispatch(
      asyncRequest<typeof CREATE_MARKETPLACE_USER, SaasUserResponse>({
        type: CREATE_MARKETPLACE_USER,
        method: 'POST',
        url,
        payload,
        resetApmTransaction: false,
      }),
    )
  }
}

export type UserSearchType = 'id' | 'email' | 'deployment_id'

export function setUserSearchType(
  searchType: UserSearchType,
): AsyncAction<typeof SET_USER_SEARCH_TYPE, string> {
  return {
    type: SET_USER_SEARCH_TYPE,
    meta: {},
    payload: searchType,
  }
}

export function setUserSearchTerm(
  searchTerm: string,
): AsyncAction<typeof SET_USER_SEARCH_TERM, string> {
  return {
    type: SET_USER_SEARCH_TERM,
    meta: {},
    payload: searchTerm,
  }
}

export function searchUsers(search_term: string) {
  const url = searchSaasUsersUrl()
  const payload: SaasUsersSearchRequestData = {
    search_term,
  }
  return asyncRequest({
    type: SEARCH_USERS,
    method: 'POST',
    url,
    payload,
  })
}

export function resetSearchUsers(): Action<typeof RESET_SEARCH_USERS> {
  return {
    type: RESET_SEARCH_USERS,
    meta: {},
  }
}

export const resetSaasUserProfileRequest = (...crumbs) =>
  resetAsyncRequest(UPDATE_SAAS_USER_PROFILE, crumbs)

export const resetScrubSaasUserRequest = (...crumbs) => resetAsyncRequest(SCRUB_SAAS_USER, crumbs)

export const resetRequestUserDeletionRequest = (...crumbs) =>
  resetAsyncRequest(REQUEST_USER_DELETION, crumbs)

export const resetSearchUsersRequest = (...crumbs) => resetAsyncRequest(SEARCH_USERS, crumbs)
