/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { useGetDomainsQuery } from '@modules/security-idp-lib/hooks'

import AddDomainFlyout from '../AddDomainFlyout'
import DomainClaimTable from '../DomainClaimsTable'
import SecurityDocsLink from '../SecurityDocsLink'

import type { Props } from './types'

const DomainClaims: React.FC<Props> = (props: Props) => {
  const {
    organizationId,
    permissions: {
      isLoading: isLoadingPermissions,
      hasViewDomainClaimPermission,
      hasUpdateDomainClaimPermission,
    },
    readonly,
  } = props
  const { data, isLoading: isLoadingDomains } = useGetDomainsQuery(organizationId)
  const [isDomainFlyoutOpen, setIsDomainFlyoutOpen] = useState(false)
  const domains = data?.domains

  const isLoading = isLoadingPermissions || isLoadingDomains

  if (!isLoading && !hasViewDomainClaimPermission) {
    return null
  }

  const hasNoDomains = !isLoading && domains?.length === 0
  return (
    <React.Fragment>
      <EuiPanel paddingSize='xl'>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiTitle size='m'>
            <h2>
              <FormattedMessage
                id='organization.domains.domain-table-title'
                defaultMessage='Domains'
              />
            </h2>
          </EuiTitle>
          {!hasNoDomains && !readonly && (
            <EuiButton
              onClick={() => setIsDomainFlyoutOpen(true)}
              disabled={!hasUpdateDomainClaimPermission}
            >
              Add domain
            </EuiButton>
          )}
        </EuiFlexGroup>
        <EuiSpacer size='m' />
        {hasNoDomains && !readonly ? (
          <NoDomains
            onOpenDomainFlyout={() => setIsDomainFlyoutOpen(true)}
            hasUpdateDomainClaimPermission={hasUpdateDomainClaimPermission}
            readonly={readonly}
          />
        ) : (
          <DomainClaimTable
            organizationId={organizationId}
            isLoading={isLoadingDomains}
            domains={domains}
            hasUpdateDomainClaimPermission={hasUpdateDomainClaimPermission}
            readonly={readonly}
          />
        )}
      </EuiPanel>
      {isDomainFlyoutOpen && <AddDomainFlyout closeFlyout={() => setIsDomainFlyoutOpen(false)} />}
    </React.Fragment>
  )
}

const NoDomains = ({
  onOpenDomainFlyout,
  hasUpdateDomainClaimPermission,
  readonly,
}: {
  onOpenDomainFlyout: () => void
  hasUpdateDomainClaimPermission: boolean
  readonly?: boolean
}) => (
  <EuiFlexGroup className='no-data-flex-group'>
    <EuiFlexItem className='no-data-left-panel'>
      <Description />
    </EuiFlexItem>
    <EuiText>
      {!readonly && (
        <EuiFlexItem className='no-data-right-panel'>
          <EuiEmptyPrompt
            className='no-data-prompt'
            color='subdued'
            title={
              <h4>
                <FormattedMessage
                  id='organization.domains.add-a-domain'
                  defaultMessage='Add a domain'
                />
              </h4>
            }
            body={
              <p>
                <FormattedMessage
                  id='organization.domains.add-a-domain-description'
                  defaultMessage='You must verify at least one domain to enable identity provider authentication for the users of your organization and to manage their roles.'
                />
              </p>
            }
            actions={
              <React.Fragment>
                <EuiButton
                  color='primary'
                  fill={true}
                  onClick={onOpenDomainFlyout}
                  disabled={!hasUpdateDomainClaimPermission}
                >
                  <FormattedMessage
                    id='organization.domains.add-domain'
                    defaultMessage='Add domain'
                  />
                </EuiButton>
                <EuiSpacer />
              </React.Fragment>
            }
          />
        </EuiFlexItem>
      )}
    </EuiText>
  </EuiFlexGroup>
)

const Description = () => (
  <EuiText color='subdued'>
    <FormattedMessage
      id='organization.domains.description'
      defaultMessage='Add and verify the domains that you own to invite and manage users through your identity provider. <lm>Learn more</lm>'
      values={{
        lm: (msg) => <SecurityDocsLink fragment='ec-saml-sso-claim-domain'>{msg}</SecurityDocsLink>,
      }}
    />
  </EuiText>
)

export default DomainClaims
