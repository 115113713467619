/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'

import { EuiSpacer } from '@elastic/eui'

import LandingPageOuterContainer from '@modules/access-management-components/LandingPageOuterContainer'
import type { FindIdpResult } from '@modules/cloud-api/v1/types'
import LandingPageInnerContainer from '@modules/access-management-components/LandingPageInnerContainer'

import EmailForSso from './EmailForSso'
import IdpSelection from './IdpSelection'
import { messages } from './messages'
import BackToLoginButton from './BackToLoginButton'

interface LocationState {
  idps?: FindIdpResult[]
}

const OrganizationIdpMatching = () => {
  const { location } = useHistory<LocationState | undefined>()
  const [idpList, setIdpList] = useState<FindIdpResult[] | undefined>(undefined)

  useEffect(() => {
    const idpListFromState = location.state?.idps

    if (idpListFromState) {
      setIdpList(idpListFromState)
    }
  }, [])

  return (
    <LandingPageOuterContainer isFlowV2={true}>
      <LandingPageInnerContainer
        title={<FormattedMessage {...messages.loginViaSso} />}
        panelProps={{
          'aria-live': 'polite',
        }}
        isFlowV2={true}
        panelCssOverride={css({ maxWidth: '410px' })}
      >
        {!idpList?.length ? (
          <EmailForSso onReceiveIdpList={setIdpList} />
        ) : (
          <IdpSelection idpList={idpList} />
        )}
        <EuiSpacer size='m' />
        <BackToLoginButton />
      </LandingPageInnerContainer>
    </LandingPageOuterContainer>
  )
}

export default OrganizationIdpMatching
