/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiTextColor, EuiTitle } from '@elastic/eui'

import { getPrettyProjectType } from '../../project-lib/display'

import type { ResourceType } from '../../discovery-questions-lib/utils'
import type { ProjectType } from '../../ui-types/projects'
import type { FunctionComponent } from 'react'

type RoleBadgeIcon = 'logoElasticsearch' | 'logoObservability' | 'logoSecurity' | 'logoElasticStack'

const PROJECT_TYPE_ICON = new Map<ProjectType, RoleBadgeIcon>([
  ['elasticsearch', 'logoElasticsearch'],
  ['observability', 'logoObservability'],
  ['security', 'logoSecurity'],
])

function getProjectTypeIcon(projectType?: ProjectType): RoleBadgeIcon {
  return (projectType && PROJECT_TYPE_ICON.get(projectType)) ?? 'logoElasticsearch'
}

interface HeaderOnboardingProps {
  hide: boolean
  resourceType: ResourceType
  projectType?: ProjectType
}

const HeaderOnboarding: FunctionComponent<HeaderOnboardingProps> = ({
  hide,
  resourceType,
  projectType,
}) => {
  if (hide || !projectType) {
    return null
  }

  return (
    <EuiTitle size='xs'>
      <h4>
        <EuiTextColor color='default'>
          <EuiFlexGroup direction='row' gutterSize='xs' alignItems='center'>
            <EuiFlexItem grow={false}>
              <EuiIcon
                data-test-id='onboarding-flow-project-icon'
                type={getProjectTypeIcon(projectType)}
                size='m'
              />
            </EuiFlexItem>
            {projectType !== 'elasticsearch' && (
              <EuiFlexItem grow={false}>
                <FormattedMessage
                  id={`onboarding-${resourceType}-flow-project.title`}
                  defaultMessage='Elastic for'
                />
              </EuiFlexItem>
            )}

            <EuiFlexItem grow={false}>
              <FormattedMessage {...getPrettyProjectType(projectType)} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiTextColor>
      </h4>
    </EuiTitle>
  )
}

export default HeaderOnboarding
