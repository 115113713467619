/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { EmailValidator } from './commons-validator-js'

const validator = new EmailValidator()

export default function validateEmail(email: string): boolean {
  if (email.trim().length !== email.length) {
    return false
  }

  return validator.isValid(email)
}

export function isElasticStaff(email: string): boolean {
  const emailRegex = /@(elastic\.co|elasticsearch\.com)$/i
  return emailRegex.test(email)
}

export function validateEmailLength(email: string): boolean {
  return email.length <= 256
}
