/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiButton,
  EuiCallOut,
  EuiCodeBlock,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiSpacer,
  EuiSteps,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import {
  useGenerateDomainVerificationCode,
  useVerifyDomainClaim,
} from '@modules/security-idp-lib/hooks'
import { addToast } from '@modules/cui/Toasts'
import type { ApiErrorCollection } from '@modules/query/types'

import { DomainValidator } from '../../../lib/commons-validator-js'

import { messages, UNKNOWN_ERROR, verificationErrorMessage } from './messages'

import type { AllProps } from './types'

const domainValidator = new DomainValidator()

const AddDomainFlyout = (props: AllProps) => {
  const [domainName, setDomainName] = useState('')
  const [isValidDomainName, setIsValidDomainName] = useState(true)
  const [verificationCode, setVerificationCode] = useState('')
  const [verificationError, setVerificationError] = useState('')
  const { formatMessage } = useIntl()

  const { closeFlyout, organizationId } = props

  const { mutate: generateVerificationCode, isLoading: isLoadingVerificationCode } =
    useGenerateDomainVerificationCode()

  const onGenerateVerificationCode = () => {
    if (!organizationId) {
      return
    }

    generateVerificationCode(
      {
        organizationId,
        domainName,
      },
      {
        onSuccess: (data) => {
          setVerificationCode(data.verification)
        },
      },
    )
  }

  const { mutate: verifyDomain, isLoading: isVerifying } = useVerifyDomainClaim()

  const onVerifyDomain = () => {
    setVerificationError('')
    const checkDomainNameValidity = domainValidator.isValid(domainName)

    if (!checkDomainNameValidity) {
      setIsValidDomainName(false)
      return
    }

    if (!organizationId) {
      return
    }

    verifyDomain(
      {
        organizationId,
        domainName,
      },
      {
        onSuccess: () => {
          closeFlyout()
          addToast({
            family: 'organization.security.add-domain-flyout.add-domain',
            color: 'success',
            iconType: 'check',
            title: (
              <FormattedMessage
                id='organization.security.add-domain-flyout.success-toast'
                defaultMessage='Domain {domainName} has been added.'
                values={{ domainName }}
              />
            ),
          })
        },
        onError: (error: ApiErrorCollection) => {
          if (error.statusCode === 449) {
            setVerificationError(`${error.statusCode}`)
            return
          }

          const errorCode = error.errors.map((err) => err.code).pop()

          setVerificationError(errorCode ?? UNKNOWN_ERROR)
        },
      },
    )
  }

  useEffect(() => {
    setVerificationCode('')
  }, [domainName])

  return (
    <EuiFlyout onClose={closeFlyout} maxWidth='448px'>
      <EuiFlyoutHeader hasBorder={true}>
        <EuiTitle size='m'>
          <h2>
            <FormattedMessage
              id='organization.security.add-domain-flyout.title'
              defaultMessage='Add and verify domain'
            />
          </h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiSpacer size='m' />
        <VerificationError verificationError={verificationError} />
        <EuiSpacer size='l' />
        <EuiSteps
          steps={[
            {
              title: formatMessage(messages.addDomain),
              children: (
                <AddDomainStep
                  {...{
                    domainName,
                    verificationCode,
                    setDomainName,
                    isValidDomainName,
                    setIsValidDomainName,
                    setVerificationError,
                    onGenerateVerificationCode,
                    isLoadingVerificationCode,
                  }}
                />
              ),
            },
            {
              title: formatMessage(messages.createDnsRecord),
              children: <VerifyCodeStep {...{ verificationCode, domainName }} />,
            },
          ]}
        />
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiButton onClick={closeFlyout}>
            <FormattedMessage
              id='organization.security.add-domain-flyout.cancel'
              defaultMessage='Cancel'
            />
          </EuiButton>
          <EuiButton fill={true} isLoading={isVerifying} onClick={onVerifyDomain}>
            <FormattedMessage
              id='organization.security.add-domain-flyout.verify-complete-button'
              defaultMessage='Verify and add domain'
            />
          </EuiButton>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )
}

const VerificationError = ({ verificationError }: { verificationError: string }) => {
  const { formatMessage } = useIntl()

  if (!verificationError) {
    return null
  }

  return (
    <EuiCallOut
      color='danger'
      title={formatMessage(verificationErrorMessage[`${verificationError}.title`])}
    >
      <EuiText>
        {formatMessage(verificationErrorMessage[`${verificationError}.description`])}
      </EuiText>
    </EuiCallOut>
  )
}

const AddDomainStep = ({
  domainName,
  setDomainName,
  isValidDomainName,
  setIsValidDomainName,
  setVerificationError,
  onGenerateVerificationCode,
  isLoadingVerificationCode,
}) => {
  const onSubmit = () => {
    const checkDomainNameValidity = domainValidator.isValid(domainName)

    if (!checkDomainNameValidity) {
      setIsValidDomainName(false)
      return
    }

    onGenerateVerificationCode(domainName)
  }

  return (
    <React.Fragment>
      <EuiFormRow
        label={
          <FormattedMessage
            id='organization.security.add-domain-flyout.domain-name'
            defaultMessage='Domain name'
          />
        }
        isInvalid={!isValidDomainName}
        error={
          domainName ? (
            <FormattedMessage
              id='organization.security.add-domain-flyout.domain-name-error'
              defaultMessage={`Invalid domain name: Check for typos, invalid characters, or an incorrect top-level domain (for example, '.com'). Make sure that there are no leading or trailing hyphens, or extra periods.`}
            />
          ) : (
            <FormattedMessage
              id='organization.security.add-domain-flyout.domain-name-required'
              defaultMessage='The domain name is required.'
            />
          )
        }
        onKeyDown={(evt) => evt.key === 'Enter' && onSubmit()}
      >
        <EuiFieldText
          onChange={(e) => {
            setIsValidDomainName(true)
            setVerificationError('')
            setDomainName(e.target.value.trim())
          }}
          value={domainName}
        />
      </EuiFormRow>

      <EuiButton onClick={onSubmit} isLoading={isLoadingVerificationCode}>
        <FormattedMessage
          id='organization.security.add-domain-flyout.generate-verification-code'
          defaultMessage='Generate verification code'
        />
      </EuiButton>
    </React.Fragment>
  )
}

const VerifyCodeStep = ({
  verificationCode,
  domainName,
}: {
  verificationCode: string
  domainName: string
}) => (
  <React.Fragment>
    <EuiText size='m'>
      {verificationCode ? (
        <FormattedMessage
          id='organization.security.add-domain-flyout.verify-domain-guide-with-code'
          defaultMessage='To verify that you own this domain, you must create a DNS TXT record {recordName} with your domain provider with the following content:'
          values={{
            recordName: (
              <strong
                style={{ overflowWrap: 'break-word' }}
              >{`_elastic_domain_challenge.${domainName}`}</strong>
            ),
          }}
        />
      ) : (
        <FormattedMessage
          id='organization.security.add-domain-flyout.verify-domain-guide'
          defaultMessage='To verify that you own this domain, you must create a DNS TXT record with your domain provider.'
        />
      )}
    </EuiText>
    <EuiSpacer size='s' />
    {verificationCode && (
      <EuiFlexGroup alignItems='center'>
        <EuiCodeBlock
          paddingSize='m'
          color='#7C609E'
          isCopyable={true}
          whiteSpace='pre-wrap'
          style={{ overflowWrap: 'break-word' }}
        >
          <EuiText size='s' style={{ fontWeight: 700, color: '#7C609E' }}>
            {verificationCode}
          </EuiText>
        </EuiCodeBlock>
      </EuiFlexGroup>
    )}
  </React.Fragment>
)

export default AddDomainFlyout
