/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { useState, type FunctionComponent } from 'react'
import { useHistory, useParams } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { jsx } from '@emotion/react'

import { EuiCallOut, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { Region as StackDeploymentRegion } from '@modules/ui-types'

import { useAnswersState, useOnboardingToken } from '../discovery-questions-lib/hooks'
import { initializeSteps, RESOURCE_TYPES, Step } from '../discovery-questions-lib/steps'
import {
  isElasticsearchServerless,
  ResourceType,
  toDefaultSolutionView,
} from '../discovery-questions-lib/utils'
import TrialFlowContainer from '../discovery-questions-pages/TrialFlowContainer'
import { useSessionStorageBackedState } from '../utils/hooks/useLocalStorage'
import ExperienceLevel from '../discovery-questions-components/ExperienceLevel'
import TrialIntent from '../discovery-questions-components/TrialIntent'
import SelectSearchProjectSubtype from '../discovery-questions-components/SelectSearchProjectSubtype'

import CreateResource from './components/CreateResource'
import HeaderOnboarding from './components/header'
import FullName from './components/FullName'
import {
  getProjectTypeFromUseCase,
  getStepUrl,
  getUseCaseFromProjectType,
  handleSetResourceType,
} from './helpers'
import { useCreateOnboardingProject } from './hooks/use-create-onboarding-project'
import { usePersistOnboardingQuestions } from './hooks/use-persist-onboarding-question'
import SolutionsOnboarding from './components/solutions'
import { useEbtOnboarding } from './hooks/use-ebt-onboarding'
import { ONBOARDING_COMBINE_STEPS, ONBOARDING_COMBINE_STEPS_WITH_SEARCH_TYPE } from './constants'

import type { Region } from '../cluster-user-api/v1/types'
import type { ElasticsearchOptimizedFor } from '../project-user-api/v1/types'

type OnboardingUrlParams = { questionId: Step; resourceType: ResourceType }

const OnboardingPage: FunctionComponent = () => {
  const [resourceCreationLaunched, setResourceCreationLaunched] = useState(false)
  const [region, setRegion] = useState<Region>()
  const [errors, setErrors] = useState<React.ReactNode[]>([])
  const history = useHistory()
  const onboardingTokenParams = useOnboardingToken()
  const [onboardingToken, setOnboardingToken] = useState(onboardingTokenParams)
  const { reportEbtEvent } = useEbtOnboarding()
  const { questionId: questionIdParams, resourceType: resourceTypeParams } =
    useParams<OnboardingUrlParams>()

  const [resourceType, setResourceType] = useState<ResourceType>(
    RESOURCE_TYPES.find((resource) => resource === resourceTypeParams) ?? RESOURCE_TYPES[0],
  )
  const [{ steps, getNextStep, initialState, getPrevStep }, setSteps] = useState(() =>
    initializeSteps({
      onboardingToken,
      resourceType,
      steps:
        resourceType === ResourceType.Serverless
          ? ONBOARDING_COMBINE_STEPS_WITH_SEARCH_TYPE
          : ONBOARDING_COMBINE_STEPS,
    }),
  )

  // validate questionId from the URL against predefined ids, otherwise fallback to the first step
  const questionId = steps.find((step) => step === questionIdParams) ?? steps[0]
  const { answers, setAnswer } = useAnswersState({
    initialState: initialState.discoveryQuestions || {},
  })

  const [projectSubtype, setProjectSubtype] = useSessionStorageBackedState<
    ElasticsearchOptimizedFor | undefined
  >('project-subtype', initialState.additionalParameters?.optimized_for)

  const {
    experience_level: experienceLevel,
    trial_intent: trialIntent,
    use_case: useCase,
    full_name: fullName,
    company,
  } = answers
  const projectType = getProjectTypeFromUseCase(useCase)

  const { putDiscoveryQuestions } = usePersistOnboardingQuestions({
    answers,
    setResourceCreationLaunched,
    setErrors,
  })
  const { launchProjectCreation } = useCreateOnboardingProject({
    answers,
    resourceType,
    setErrors,
    setResourceCreationLaunched,
    onboardingToken,
    projectSubtype,
  })
  const isSearchProjectServerless = isElasticsearchServerless(resourceType, useCase)

  const setResourceTypeHandler = (rt: ResourceType) => {
    handleSetResourceType({
      isSearchProjectServerless,
      onboardingToken,
      setOnboardingToken,
      setSteps,
      setResourceType,
      rt,
      useCase,
    })
  }

  function renderStep() {
    switch (questionId) {
      case Step.ExperienceLevel:
        return (
          <ExperienceLevel
            experienceLevel={experienceLevel}
            onChangeExperienceLevel={(experience_level) => {
              setAnswer({ experience_level })
            }}
            onSubmitExperienceLevel={(elValue) => {
              const nextStep = getNextStep(questionId)

              if (nextStep) {
                reportEbtEvent<'experience_level'>('experience_level', {
                  answer: elValue,
                  resource: resourceType,
                })
                history.push(
                  getStepUrl({ resourceType: resourceTypeParams, step: nextStep, onboardingToken }),
                )
              }
            }}
          />
        )
      case Step.TrialIntent:
        return (
          <TrialIntent
            trialIntent={trialIntent}
            onChangeTrialIntent={(trial_intent) => {
              setAnswer({ trial_intent })
            }}
            onSubmitTrialIntent={(tiValue) => {
              const nextStep = getNextStep(questionId)

              if (nextStep) {
                reportEbtEvent<'trial_intent'>('trial_intent', {
                  answer: tiValue,
                  resource: resourceType,
                })

                history.push(
                  getStepUrl({ resourceType: resourceTypeParams, step: nextStep, onboardingToken }),
                )
              }
            }}
          />
        )
      case Step.UseCase:
        return (
          <SolutionsOnboarding
            onNext={(projectTypePick) => {
              const useCaseUpdated = getUseCaseFromProjectType(projectTypePick)
              setAnswer({ use_case: useCaseUpdated })

              if (resourceType === ResourceType.Serverless && useCaseUpdated === 'search') {
                setSteps(
                  initializeSteps({
                    onboardingToken,
                    steps: ONBOARDING_COMBINE_STEPS_WITH_SEARCH_TYPE,
                    resourceType,
                  }),
                )
              } else {
                setSteps(
                  initializeSteps({
                    onboardingToken,
                    steps: ONBOARDING_COMBINE_STEPS,
                    resourceType,
                  }),
                )
              }

              const nextStep = getNextStep(questionId)

              if (nextStep) {
                reportEbtEvent<'use_case'>('use_case', {
                  answer: useCaseUpdated,
                  resource: resourceType,
                })
                history.push(
                  getStepUrl({ resourceType: resourceTypeParams, step: nextStep, onboardingToken }),
                )
              }
            }}
          />
        )
      case Step.CspRegion:
        return (
          <CreateResource
            solutionType={toDefaultSolutionView(answers.use_case)}
            hideSwitchToResource={resourceTypeParams === ResourceType.Stateful}
            isContinueStep={isSearchProjectServerless && !projectSubtype}
            onDeploymentCreation={({
              region: ebtRegion,
              version,
              platform,
              hardware,
            }: {
              region: StackDeploymentRegion
              version: string
              platform: string
              hardware: string
            }) => {
              reportEbtEvent('resource', {
                answer: {
                  region: ebtRegion,
                  version,
                  platform,
                  hardware,
                },
                resource: resourceType,
              })
              return putDiscoveryQuestions()
            }}
            onRegionConfirmed={(updatedRegion) => {
              reportEbtEvent('resource', {
                answer: {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  region: updatedRegion!,
                },
                resource: resourceType,
              })

              if (isSearchProjectServerless && !projectSubtype) {
                setRegion(updatedRegion)
                history.push(
                  getStepUrl({
                    resourceType: resourceTypeParams,
                    step: Step.SearchProjectSubtype,
                    onboardingToken,
                  }),
                )
              } else {
                setResourceCreationLaunched(true)
                putDiscoveryQuestions(() => launchProjectCreation(updatedRegion))
              }
            }}
            resourceType={resourceType}
            resourceCreationLaunched={resourceCreationLaunched}
            switchToResource={setResourceTypeHandler}
          />
        )
      case Step.SearchProjectSubtype:
        return (
          <SelectSearchProjectSubtype
            projectSubtype={projectSubtype}
            onChangeSearchProjectSubtype={(value: ElasticsearchOptimizedFor) =>
              setProjectSubtype(value)
            }
            onSearchProjectSubtypeCaseConfirmed={() => {
              reportEbtEvent<'project_subtype'>('project_subtype', {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                answer: projectSubtype!,
                resource: resourceType,
              })
              setResourceCreationLaunched(true)
              putDiscoveryQuestions(() => launchProjectCreation(region))
            }}
            isLaunchButton={true}
          />
        )
      default:
      case Step.FullName:
        return (
          <FullName
            resourceType={resourceType}
            fullName={fullName || ''}
            company={company || ''}
            onChangeFullName={(full_name) => setAnswer({ full_name })}
            onChangeCompany={(newCompany) => setAnswer({ company: newCompany })}
            setStep={() => {
              reportEbtEvent<'full_name'>('full_name', {
                answer: null,
                resource: resourceType,
              })
              const nextStep = getNextStep(questionId)

              if (nextStep) {
                history.push(
                  getStepUrl({ resourceType: resourceTypeParams, step: nextStep, onboardingToken }),
                )
              }
            }}
          />
        )
    }
  }

  return (
    <TrialFlowContainer
      data-test-id='discovery-questions.form'
      step={steps.indexOf(questionId)}
      totalSteps={steps.length}
      onGoBack={() => {
        const prevStep = getPrevStep(questionId)

        if (prevStep) {
          if (prevStep === Step.UseCase) {
            setAnswer({ use_case: undefined })
            setSteps(
              initializeSteps({
                onboardingToken,
                steps:
                  steps.indexOf(questionId) >= 3 || isSearchProjectServerless
                    ? ONBOARDING_COMBINE_STEPS_WITH_SEARCH_TYPE
                    : ONBOARDING_COMBINE_STEPS,
                resourceType,
              }),
            )
          }

          history.push(
            getStepUrl({ resourceType: resourceTypeParams, step: prevStep, onboardingToken }),
          )
        }
      }}
      header={
        <HeaderOnboarding
          hide={resourceTypeParams === ResourceType.Stateful}
          resourceType={resourceType}
          projectType={projectType}
        />
      }
      resourceType={resourceType}
      hideBgImage={questionId === Step.UseCase}
    >
      <EuiFlexGroup className='fs-unmask' direction='column'>
        <EuiFlexItem>
          {errors.map((message) => (
            <EuiCallOut
              title={
                <FormattedMessage
                  id='onboarding-page.error-title'
                  defaultMessage='Sorry, we were unable to create your project'
                />
              }
              color='danger'
              iconType='error'
              onDismiss={() => setErrors([])}
            >
              {message}
            </EuiCallOut>
          ))}
        </EuiFlexItem>
        <EuiFlexItem>{renderStep()}</EuiFlexItem>
      </EuiFlexGroup>
    </TrialFlowContainer>
  )
}

export default OnboardingPage
