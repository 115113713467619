/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx } from '@emotion/react'
import { Fragment, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiButton,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiIcon,
  EuiPopover,
  EuiTextColor,
} from '@elastic/eui'

import type { Organization } from '@modules/cloud-api/v1/types'
import type { UserProfile } from '@modules/ui-types'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import LeaveOrganizationModal from './LeaveOrganizationModal'
import RevokeInvitationModal from './RevokeInvitationModal'
import { hasSameEmail } from './lib'
import RemoveMemberModal from './RemoveMemberModal'

import type { FunctionComponent } from 'react'
import type { OrganizationMemberRow } from './types'

interface Props {
  profile: UserProfile | null
  organization: Organization
  organizationMemberRow: OrganizationMemberRow
  onEditRole: () => void
}

const OrganizationMemberActions: FunctionComponent<Props> = ({
  organizationMemberRow,
  organization,
  profile,
  onEditRole,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false)
  const [isRemoveMemberModalOpen, setRemoveMemberModalOpen] = useState(false)
  const [isRevokeInvitationModalOpen, setRevokeInvitationModalOpen] = useState(false)
  const [isLeaveOrganizationModalOpen, setLeaveOrganizationModalOpen] = useState(false)
  const { formatMessage } = useIntl()

  return (
    <Fragment>
      <EuiPopover
        id='organization-members-actions-popover'
        button={
          <EuiButton
            style={{ backgroundColor: 'transparent' }}
            aria-label={formatMessage(
              {
                id: 'organization.organization-member-actions.button.aria-label',
                defaultMessage: 'All actions for {name} column',
              },
              {
                name: organizationMemberRow.name,
              },
            )}
            iconType='boxesHorizontal'
            onClick={() => setPopoverOpen(!isPopoverOpen)}
          />
        }
        isOpen={isPopoverOpen}
        closePopover={() => setPopoverOpen(false)}
        panelPaddingSize='s'
        anchorPosition='downLeft'
      >
        <EuiContextMenuPanel>
          {renderEditRolesMenuItem()}
          {renderRemoveMemberMenuItem()}
        </EuiContextMenuPanel>
      </EuiPopover>

      {isRemoveMemberModalOpen && (
        <RemoveMemberModal
          organization={organization}
          organizationMemberRow={organizationMemberRow}
          onCloseModal={() => setRemoveMemberModalOpen(false)}
        />
      )}

      {isRevokeInvitationModalOpen && (
        <RevokeInvitationModal
          organization={organization}
          organizationMemberRow={organizationMemberRow}
          onCloseModal={() => setRevokeInvitationModalOpen(false)}
        />
      )}

      {isLeaveOrganizationModalOpen && profile && (
        <LeaveOrganizationModal
          profile={profile}
          organization={organization}
          onCloseModal={() => setLeaveOrganizationModalOpen(false)}
        />
      )}
    </Fragment>
  )

  function renderEditRolesMenuItem() {
    if (organizationMemberRow.isInvitation) {
      return null
    }

    const isCurrentUser = hasSameEmail(profile, organizationMemberRow)

    return (
      <PermissionsGate
        permissions={[
          {
            organizationId: organization.id,
            type: 'role-assignment',
            action: 'update',
          },
        ]}
      >
        {({ hasPermissions }) => (
          <EuiContextMenuItem
            key={`edit-role-${organizationMemberRow.id}`}
            icon='pencil'
            disabled={!hasPermissions || isCurrentUser}
            onClick={() => {
              setPopoverOpen(false)
              onEditRole()
            }}
          >
            {organization.enforce_authentication_method === 'sso' ? (
              <FormattedMessage
                id='organization.organization-member-actions.view-role'
                defaultMessage='View roles'
              />
            ) : (
              <FormattedMessage
                id='organization.organization-member-actions.edit-role'
                defaultMessage='Edit roles'
              />
            )}
          </EuiContextMenuItem>
        )}
      </PermissionsGate>
    )
  }

  function renderRemoveMemberMenuItem() {
    if (hasSameEmail(profile, organizationMemberRow)) {
      return (
        <PermissionsGate
          permissions={[
            {
              organizationId: organization.id,
              type: 'organization-members',
              action: 'leave',
            },
          ]}
        >
          {({ hasPermissions }) => (
            <EuiContextMenuItem
              key={`leave-organization-${organizationMemberRow.id}`}
              icon={<EuiIcon type='exit' size='m' color={getActionColor(hasPermissions)} />}
              disabled={!hasPermissions}
              onClick={() => {
                setPopoverOpen(false)
                setLeaveOrganizationModalOpen(true)
              }}
            >
              <EuiTextColor color={getActionColor(hasPermissions)}>
                <FormattedMessage
                  id='organization.organization-member-actions.leave-organization'
                  defaultMessage='Leave organization'
                />
              </EuiTextColor>
            </EuiContextMenuItem>
          )}
        </PermissionsGate>
      )
    }

    if (organizationMemberRow.isInvitation) {
      return (
        <PermissionsGate
          permissions={[
            {
              organizationId: organization.id,
              type: 'organization-invitation',
              action: 'delete-all',
            },
          ]}
        >
          {({ hasPermissions }) => (
            <EuiContextMenuItem
              key={`revoke-invitation-${organizationMemberRow.id}`}
              icon={<EuiIcon type='cross' size='m' color={getActionColor(hasPermissions)} />}
              disabled={!hasPermissions}
              onClick={() => {
                setPopoverOpen(false)
                setRevokeInvitationModalOpen(true)
              }}
            >
              <EuiTextColor color={getActionColor(hasPermissions)}>
                <FormattedMessage
                  id='organization.organization-member-actions.revoke-invitation'
                  defaultMessage='Revoke invite'
                />
              </EuiTextColor>
            </EuiContextMenuItem>
          )}
        </PermissionsGate>
      )
    }

    return (
      <PermissionsGate
        permissions={[
          {
            organizationId: organization.id,
            type: 'organization-members',
            action: 'delete',
          },
        ]}
      >
        {({ hasPermissions }) => (
          <EuiContextMenuItem
            key={`remove-member-${organizationMemberRow.id}`}
            icon={<EuiIcon type='cross' size='m' color={getActionColor(hasPermissions)} />}
            disabled={!hasPermissions}
            onClick={() => {
              setPopoverOpen(false)
              setRemoveMemberModalOpen(true)
            }}
          >
            <EuiTextColor color={getActionColor(hasPermissions)}>
              <FormattedMessage
                id='organization.organization-member-actions.remove-member'
                defaultMessage='Remove member'
              />
            </EuiTextColor>
          </EuiContextMenuItem>
        )}
      </PermissionsGate>
    )
  }

  function getActionColor(hasPermissions: boolean) {
    return hasPermissions ? 'danger' : 'inherit'
  }
}

export default OrganizationMemberActions
