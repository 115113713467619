/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiText } from '@elastic/eui'

import TemplateTitle from './TemplateTitle'

import type { FunctionComponent } from 'react'
import type { HardwareProfileDropdownProps as Props } from './types'

const HardwareProfileDropdown: FunctionComponent<Props> = ({ template, isDisabled, newBadge }) => {
  const trialDescription = (
    <FormattedMessage
      id='create-deployment-from-template.hardware-profile.trial'
      defaultMessage='Not available in trial'
    />
  )

  return (
    <Fragment>
      <TemplateTitle template={template} style={{ fontWeight: `bold` }} newBadge={newBadge} />

      <EuiSpacer size='xs' />

      <EuiText size='s' color='subdued'>
        {isDisabled ? trialDescription : template.description}
      </EuiText>
    </Fragment>
  )
}

export default HardwareProfileDropdown
