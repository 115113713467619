/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FormattedMessage, useIntl } from 'react-intl'
import React from 'react'

import {
  EuiAccordion,
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type { IdpResponse } from '@modules/security-idp-api/types'
import {
  getPemFormatCertificate,
  getRemainingDaysToExpiry,
  getX509CertificateInfo,
} from '@modules/security-idp-lib'

import { getCertExpiryInfoMessage, getToggleConfigMessage } from './messages'

import type { ReactNode } from 'react'

export const UserAuthenticationSummary = ({ idpInfo }: { idpInfo: IdpResponse }) => {
  const [isExpandedConfig, setIsExpandedConfig] = React.useState(false)
  const { formatMessage } = useIntl()

  const {
    sso_login_url,
    metadata_url,
    acs,
    sp_entity_id,
    configuration: {
      login_identifier_prefix,
      saml_idp: { public_certificate, sso_url, issuer },
    },
  } = idpInfo

  const onToggleConfig = () => {
    setIsExpandedConfig(!isExpandedConfig)
  }

  const x509Cert = getX509CertificateInfo(public_certificate[0] || '')
  const remainingDaysToCertExpiry = x509Cert ? getRemainingDaysToExpiry(x509Cert) : undefined

  return (
    <EuiAccordion
      id='organization.security.user-authentication.configurations'
      onToggle={onToggleConfig}
      forceState={isExpandedConfig ? 'open' : 'closed'}
      buttonContent={formatMessage(getToggleConfigMessage(isExpandedConfig))}
      paddingSize='s'
    >
      <EuiPanel hasBorder={true} hasShadow={false} paddingSize='l'>
        <EuiText>
          <FormattedMessage
            id='organization.security.user-authentication.provided-by-you'
            defaultMessage='PROVIDED BY YOU'
          />
        </EuiText>
        <EuiSpacer size='s' />
        <Row
          label={
            <FormattedMessage
              id='organization.security.user-authentication.idp-entity-id'
              defaultMessage='Identity Provider Entity ID'
            />
          }
          value={<EuiText>{issuer}</EuiText>}
        />
        <Row
          label={
            <FormattedMessage
              id='organization.security.user-authentication.idp-sso-url'
              defaultMessage='Identity Provider SSO URL'
            />
          }
          value={<EuiText>{sso_url}</EuiText>}
        />
        <Row
          label={
            <FormattedMessage
              id='organization.security.user-authentication.public-cert'
              defaultMessage='Public x509 certificate'
            />
          }
          value={
            <div>
              <EuiCodeBlock overflowHeight={90} fontSize='m' paddingSize='m' isCopyable={true}>
                {getPemFormatCertificate(public_certificate[0] || '')}
              </EuiCodeBlock>
              <EuiText color={remainingDaysToCertExpiry ? 'success' : 'danger'}>
                {formatMessage(getCertExpiryInfoMessage(x509Cert), {
                  days: remainingDaysToCertExpiry,
                })}
              </EuiText>
            </div>
          }
        />
        <Row
          label={
            <FormattedMessage
              id='organization.security.user-authentication.login-id-prefix'
              defaultMessage='Login identifier prefix'
            />
          }
          value={<EuiText>{login_identifier_prefix}</EuiText>}
        />
        <EuiHorizontalRule size='full' margin='xl' />
        <EuiText>
          <FormattedMessage
            id='organization.security.user-authentication.provided-by-elastic'
            defaultMessage='PROVIDED BY ELASTIC'
          />
        </EuiText>
        <EuiSpacer size='s' />
        <Row
          label={
            <div>
              <FormattedMessage
                id='organization.security.user-authentication.sp-entity-id'
                defaultMessage='Service provider Entity ID'
              />
              <br />
              <FormattedMessage
                id='organization.security.user-authentication.audience-uri'
                defaultMessage='(Audience URI)'
              />
            </div>
          }
          value={
            <EuiCodeBlock fontSize='m' paddingSize='m' isCopyable={true} whiteSpace='pre'>
              {sp_entity_id}
            </EuiCodeBlock>
          }
        />
        <Row
          label={
            <FormattedMessage
              id='organization.security.user-authentication.sp-acs-url'
              defaultMessage='Service provider ACS URL'
            />
          }
          value={
            <EuiCodeBlock fontSize='m' paddingSize='m' isCopyable={true} whiteSpace='pre'>
              {acs}
            </EuiCodeBlock>
          }
        />
        <Row
          label={
            <FormattedMessage
              id='organization.security.user-authentication.metadata-url'
              defaultMessage='Metadata URL'
            />
          }
          value={
            <EuiCodeBlock fontSize='m' paddingSize='m' isCopyable={true} whiteSpace='pre'>
              {metadata_url}
            </EuiCodeBlock>
          }
        />
        <Row
          label={
            <FormattedMessage
              id='organization.security.user-authentication.organization-login-url'
              defaultMessage='Organization login URL'
            />
          }
          value={
            <EuiCodeBlock fontSize='m' paddingSize='m' isCopyable={true} whiteSpace='pre'>
              {sso_login_url}
            </EuiCodeBlock>
          }
        />
      </EuiPanel>
    </EuiAccordion>
  )
}

const Row = ({ label, value }: { label: ReactNode; value: ReactNode }) => (
  <EuiFlexGroup css={css({ marginTop: '1.5rem' })}>
    <EuiFlexItem
      grow={1}
      css={css({
        minWidth: '0px',
        '.euiText': {
          fontWeight: 700,
        },
      })}
    >
      <EuiText>{label}</EuiText>
    </EuiFlexItem>
    <EuiFlexItem grow={2} css={css({ minWidth: '0px', overflowWrap: 'anywhere' })}>
      {value}
    </EuiFlexItem>
  </EuiFlexGroup>
)
