/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { IconType } from '@elastic/eui'

import type { Status } from '@modules/cloud-api/v1/types'

export const getCloudStatusColor = (indicator: string) => {
  if (indicator === 'major' || indicator === 'critical') {
    return 'danger'
  }

  if (indicator === 'none') {
    return 'success'
  }

  if (indicator === 'minor') {
    return 'warning'
  }

  return 'subdued'
}

export const getCloudStatusIconType = (status: Status): IconType => {
  const { indicator } = status
  let type = 'cloudSunny'

  if (indicator === 'minor') {
    type = 'cloudDrizzle'
  }

  if (indicator === 'major' || indicator === 'critical') {
    type = 'cloudStormy'
  }

  return type
}
