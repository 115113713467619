/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { noop } from 'lodash'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiFlexGroup } from '@elastic/eui'

import StepPanel from '@modules/cui/Step/StepPanel'
import { usePostInstantDeploymentMutation } from '@modules/deployment-creation-api/hooks'
import history from '@modules/utils/history'
import { CuiAlert } from '@modules/cui/Alert'
import { hasDefaultSolutionViewFeature } from '@modules/deployment-lib'
import type { MultiErrorResponse } from '@modules/cluster-user-api/v1/types'

// eslint-disable-next-line import/no-restricted-paths
import CreateStackDeploymentEditor from '@/components/StackDeploymentEditor/CreateStackDeploymentEditor'
// eslint-disable-next-line import/no-restricted-paths
import CreateDeploymentButton from '@/components/StackDeploymentEditor/CreateStackDeploymentEditor/CreateDeploymentButton'
// eslint-disable-next-line import/no-restricted-paths
import { createStackDeploymentRequest as extractCreateDeploymentRequest } from '@/reducers/asyncRequests/registry'

import DefaultSolutionViewSelector from './DefaultSolutionViewSelector'
import { insertSolutionType } from './lib'

// eslint-disable-next-line import/no-restricted-paths
import type { ReduxState } from '@/types/redux'
// eslint-disable-next-line import/no-restricted-paths
import type { CreateEditorComponentConsumerProps } from '@/components/StackDeploymentEditor/types'
import type { FC } from 'react'
import type { DefaultSolutionView, StepId } from './types'

export type DeploymentCreationWizardProps = CreateEditorComponentConsumerProps

const DeploymentCreationWizard: FC<DeploymentCreationWizardProps> = (createEditorConsumerProps) => {
  const [step, setStep] = useState<StepId>(`deployment-editor`)

  const [defaultSolutionView, setDefaultSolutionView] = useState<DefaultSolutionView | undefined>()

  const [claimedInstantStackDeploymentId, setClaimedInstantStackDeploymentId] = useState<
    string | undefined
  >()

  const [claimInstantDeploymentError, setClaimInstantDeploymentError] = useState<
    MultiErrorResponse | undefined
  >()

  const { mutateAsync: postInstantDeploymentMutate, isLoading: instantDeploymentIsLoading } =
    usePostInstantDeploymentMutation({
      onSuccess: (deploymentClaimResponse) => {
        const { deployment_id: deploymentId } = deploymentClaimResponse

        setClaimedInstantStackDeploymentId(deploymentId)
      },
      onError: (error) => {
        setClaimInstantDeploymentError(error)
      },
    })

  const createStackDeploymentRequest = useSelector((state: ReduxState) =>
    extractCreateDeploymentRequest(state),
  )

  const { editorState } = createEditorConsumerProps

  const actualEditorState = insertSolutionType(editorState, defaultSolutionView)

  const baseStyle = css({
    width: '100%',
    maxWidth: 820,
    alignSelf: 'center',
  })

  switch (step) {
    case `deployment-editor`: {
      const editorStackVersion = editorState._joltVersion

      const actualProps = {
        ...createEditorConsumerProps,
        initialSpacer: false,
        goToNextStep:
          editorStackVersion && hasDefaultSolutionViewFeature(editorStackVersion)
            ? () => setStep('default-solution-view')
            : null,
      }

      return (
        <StepPanel style={baseStyle} onGoBack={() => history.push('/home')}>
          <CreateStackDeploymentEditor {...actualProps} />
        </StepPanel>
      )
    }

    case `default-solution-view`:
      return (
        <StepPanel
          hasShadow={true}
          style={css([baseStyle, { padding: 60 }])}
          onGoBack={() => setStep('deployment-editor')}
          transparent={false}
        >
          <EuiFlexGroup direction='column' gutterSize='m'>
            {createStackDeploymentRequest.error && (
              <CuiAlert type='error' iconType='alert'>
                {createStackDeploymentRequest.error}
              </CuiAlert>
            )}
            {claimInstantDeploymentError &&
              claimInstantDeploymentError.errors.map((error, index) => (
                <CuiAlert key={index} type='error' iconType='alert'>
                  {error.message}
                </CuiAlert>
              ))}
            <DefaultSolutionViewSelector
              solutionView={defaultSolutionView}
              onChangeDefaultSolutionView={setDefaultSolutionView}
              onDefaultSolutionViewConfirmed={noop}
              footer={
                <EuiFlexGroup justifyContent='flexEnd'>
                  <CreateDeploymentButton
                    disabled={instantDeploymentIsLoading || defaultSolutionView === undefined}
                    claimInstantStackDeployment={(claimDeploymentRequestPayload) =>
                      postInstantDeploymentMutate({
                        body: claimDeploymentRequestPayload,
                      })
                    }
                    claimedInstantStackDeploymentId={claimedInstantStackDeploymentId ?? null}
                    renderClaimStackDeploymentError={noop}
                    editorState={actualEditorState}
                    showApiRequest={true}
                  />
                </EuiFlexGroup>
              }
            />
          </EuiFlexGroup>
        </StepPanel>
      )
    default:
      return null
  }
}

export default DeploymentCreationWizard
