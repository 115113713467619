/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'query-string'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { withTransaction } from '@elastic/apm-rum-react'

import { createMarketplaceUser } from '@/actions/saasUsers'
import { checkForOktaAndHandleLogin, loginAndRedirect } from '@/actions/auth'
import { createMarketplaceUserRequest } from '@/reducers/asyncRequests/registry'
import { getMfa } from '@/reducers/auth'

import PartnerSignupRegistration from './PartnerSignupRegistration'

import type { ReduxState, ThunkDispatch } from '@/types/redux'
import type { StateProps, DispatchProps, ConsumerProps } from './types'
import type { RouteComponentProps } from 'react-router'

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const query = parse(location.search.slice(1))
  const redirectTo = String(query.redirectTo)
  const partner = String(query.partner)

  return {
    redirectTo,
    partner,
    createMarketplaceUserRequest: createMarketplaceUserRequest(state),
    mfa: getMfa(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  convertUser: ({ email, password, captcha_token }) =>
    dispatch(
      createMarketplaceUser({
        email,
        password,
        has_accepted_terms_and_policies: true,
        captcha_token,
        source: 'gcp-marketplace',
      }),
    ),
  loginAndRedirect: ({ oktaRedirectUrl, redirectTo, credentials }) =>
    dispatch(loginAndRedirect({ oktaRedirectUrl, redirectTo, credentials })),
  checkForOktaAndHandleLogin: (data) => dispatch(checkForOktaAndHandleLogin(data)),
})

export default withRouter(
  connect<StateProps, DispatchProps, RouteComponentProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(withTransaction(`Partner signup`, `component`)(PartnerSignupRegistration)),
)
