/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { useOrganizationIdp } from '@modules/security-idp-lib/hooks'
import { useConfig } from '@modules/cui/ConfigContext'

import { userAuthenticationUrl } from '@/apps/userconsole/urls'
import UsageNotice from '@/apps/userconsole/components/NotificationBanner/UsageNotice'

import UserAuthenticationSummary from '../UserAuthenticationSummary'
import CertificateExpiryWarning from '../CertificateExpiryWarning'

import { getLoginMethodMessage } from './messages'

import type { Props } from './types'

const UserAuthentication = ({
  organizationId,
  enforceAuthenticationMethod,
  permissions: {
    isLoading: isLoadingPermission,
    hasUpdateOrganizationIdp,
    hasViewOrganizationIdp,
    hasListFeatureUsage,
  },
  readonly,
  accountDetails,
  fetchAccountDetails,
  enableUsageNoticeElasticCheck,
}: Props) => {
  const history = useHistory()
  const { isLoading: isLoadingIdp, data: idpInfo } = useOrganizationIdp(organizationId)

  const publicCertificate = idpInfo?.configuration.saml_idp.public_certificate[0]
  const isLoading = isLoadingPermission || isLoadingIdp

  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'

  useEffect(() => {
    if (!isAdminConsole && !accountDetails && hasListFeatureUsage) {
      fetchAccountDetails()
    }
  }, [isAdminConsole, accountDetails, hasListFeatureUsage])

  if (!isLoading && !hasViewOrganizationIdp) {
    return null
  }

  return (
    <EuiPanel paddingSize='xl'>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size='m'>
            <h2>
              <FormattedMessage
                id='organization.security.user-authentication.title'
                defaultMessage='User authentication'
              />
            </h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {hasUpdateOrganizationIdp && !readonly && (
            <EuiButton
              size='m'
              isLoading={isLoading}
              onClick={() => history.push(userAuthenticationUrl())}
            >
              <FormattedMessage
                id='user-authentication.configure-sso-button'
                defaultMessage='Configure SSO'
              />
            </EuiButton>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='l' />
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          {accountDetails && idpInfo?.configuration.enabled && (
            <Fragment>
              <UsageNotice
                accountDetails={accountDetails}
                overrideUsageLevel='enterprise'
                enableUsageNoticeElasticCheck={enableUsageNoticeElasticCheck}
              />
              <EuiSpacer size='m' />
            </Fragment>
          )}
          {publicCertificate && (
            <CertificateExpiryWarning publicCertificate={publicCertificate} readonly={readonly} />
          )}
          <UserAuthenticationInfo
            isEnabledSamlSso={idpInfo?.configuration.enabled}
            isEnforcedSamlSso={enforceAuthenticationMethod === 'sso'}
            idpInfo={idpInfo}
          />
        </Fragment>
      )}
    </EuiPanel>
  )
}

const UserAuthenticationInfo = ({ isEnabledSamlSso, isEnforcedSamlSso, idpInfo }) => {
  const { formatMessage } = useIntl()
  const loginMethodMessage = formatMessage(
    getLoginMethodMessage(isEnabledSamlSso, isEnforcedSamlSso),
  )

  return (
    <Fragment>
      <EuiFlexGroup className='no-data-flex-group'>
        <EuiFlexItem className='no-data-left-panel'>
          <EuiTitle size='xs'>
            <h3>
              <FormattedMessage id='user-authentication.login-identifier' defaultMessage='Login' />
            </h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem className='no-data-right-panel'>
          <EuiText>{loginMethodMessage}</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='l' />
      {isEnabledSamlSso && <UserAuthenticationSummary idpInfo={idpInfo} />}
    </Fragment>
  )
}

const Loading = () => (
  <EuiEmptyPrompt
    icon={<EuiLoadingSpinner size='l' />}
    title={
      <FormattedMessage
        id='organization.security.user-authentication.loading'
        defaultMessage='Loading user authentication information'
      />
    }
    color='subdued'
  />
)

export default UserAuthentication
