/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { parse as parseQuery, stringify } from 'query-string'

import type { AsyncRequestError } from '@modules/ui-types'
import { asAjaxRequestError } from '@modules/utils/ajax'
import { CuiAlert } from '@modules/cui/Alert'

import { messages } from './messages'

import type { ReactElement } from 'react'

interface Props {
  error: AsyncRequestError
  redirectTo?: string
  state?: string
  contextSwitchLocationState?: { [key: string]: string | boolean }
}

export const SsoError = ({ error, redirectTo, state }: Props) => {
  const parsedState = parseQuery(String(state))
  const originLoginIdentifier =
    typeof parsedState.loginIdentifier === `string` ? parsedState.loginIdentifier : undefined
  const redirectToFromState =
    typeof parsedState.redirectTo === `string` ? parsedState.redirectTo : undefined

  const errorCode =
    typeof error === `string`
      ? `idp.${error}` // if an error is a string, it's from IdP/Okta
      : asAjaxRequestError(error)
          ?.body?.errors?.map((err) => err.code)
          ?.pop()
  const errorResponseMessage =
    typeof error === `string`
      ? null
      : asAjaxRequestError(error)
          ?.body?.errors?.map((err) => err.message)
          ?.pop()

  const linkBackToLogin = (
    <Link to={{ pathname: '/login', search: stringify({ redirectTo }) }}>
      <FormattedMessage {...messages.errorLoginLink} />
    </Link>
  )
  const linkBackToSsoInit = originLoginIdentifier ? (
    <Link
      to={{
        pathname: `/login/sso/${originLoginIdentifier}`,
        search: stringify({ redirectTo: redirectToFromState }),
      }}
    >
      <FormattedMessage {...messages.pleaseTryAgain} />
    </Link>
  ) : (
    <FormattedMessage {...messages.pleaseTryAgain} />
  )

  let errorMessage: ReactElement | null = null

  if (errorCode && messages[errorCode]) {
    const values = { login: linkBackToLogin, pleaseTryAgain: linkBackToSsoInit }
    errorMessage = <FormattedMessage {...messages[errorCode]} values={values} />
  } else if (errorResponseMessage) {
    errorMessage = errorResponseMessage
  } else {
    errorMessage = <FormattedMessage {...messages.unknownError} />
  }

  return (
    <CuiAlert data-test-id='sso-error' type='error'>
      {errorMessage}
    </CuiAlert>
  )
}
