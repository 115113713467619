/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { useLocation } from 'react-router'
import { useContext } from 'react'
import { stringify } from 'query-string'

import { ssoLoginUrl } from '@modules/auth/urls'
import AuthContext from '@modules/auth/context'

import { initiateOrganizationSsoRedirect } from '@/actions/auth/auth'
import { getSignInQueryData } from '@/lib/urlUtils'

export const useSso = ({ loginIdentifier }: { loginIdentifier?: string }) => {
  const { search } = useLocation()
  const authContext = useContext(AuthContext)
  const signInQueryData = getSignInQueryData(search)
  const redirectTo = signInQueryData.redirectTo ?? signInQueryData.fromURI

  const initiateSsoRedirect = (loginIdentifierInput?: string) => {
    const _loginIdentifier = loginIdentifierInput || loginIdentifier
    authContext.setAuthContext({ method: 'byoidp' })

    if (_loginIdentifier) {
      return initiateOrganizationSsoRedirect({
        redirectUri: `${location.protocol}//${location.host}${ssoLoginUrl()}`,
        state: stringify({
          redirectTo,
          hasAcceptedTermsAndPolicies: true,
          loginIdentifier: _loginIdentifier,
        }),
        loginIdentifier: _loginIdentifier,
      })
    }
  }

  return {
    initiateSsoRedirect,
    redirectTo,
  }
}
