/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'

import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { organizationRoleMappingUrl } from '@/apps/userconsole/urls'

import { useGetRoleMappingsQuery } from '../../../../modules/security-idp-lib/hooks'
import RoleMappingsTable from '../RoleMappingsTable'
import SecurityDocsLink from '../SecurityDocsLink'

import { messages } from './messages'

import type { Props } from './types'

const RoleMappings = (props: Props) => {
  const {
    organizationId,
    permissions: {
      isLoading: isLoadingPermissions,
      hasViewRoleMappingPermission,
      hasUpdateRoleMappingPermission,
      hasDeleteRoleMappingPermission,
    },
    readonly,
  } = props
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { data, isLoading: isLoadingRoleMappings } = useGetRoleMappingsQuery(organizationId)
  const roleMappings = data?.mappings
  const isLoading = isLoadingPermissions || isLoadingRoleMappings

  if (!isLoading && !hasViewRoleMappingPermission) {
    return null
  }

  const hasNoRoleMappings = !isLoading && roleMappings?.length === 0

  const onClickCreateRoleMapping = () => {
    history.push(organizationRoleMappingUrl())
  }

  return (
    <EuiPanel paddingSize='xl'>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexGroup direction='column' style={{ gap: '0px' }}>
          <EuiTitle size='m'>
            <h2>{formatMessage(messages.roleMappingsTitle)}</h2>
          </EuiTitle>
          {!hasNoRoleMappings && (
            <div>
              <EuiSpacer size='s' />
              <EuiText color='subdued' size='s'>
                {formatMessage(messages.roleMappingsDescription, {
                  lm: (msg) => (
                    <SecurityDocsLink fragment='ec_role_mappings'>{msg}</SecurityDocsLink>
                  ),
                })}
              </EuiText>
            </div>
          )}
        </EuiFlexGroup>
        {!hasNoRoleMappings && !readonly && (
          <EuiButton disabled={!hasUpdateRoleMappingPermission} onClick={onClickCreateRoleMapping}>
            {formatMessage(messages.btnCreateRoleMapping)}
          </EuiButton>
        )}
      </EuiFlexGroup>
      <EuiSpacer size='m' />
      {hasNoRoleMappings && !readonly ? (
        <NoRoleMappings
          readonly={readonly}
          onClickCreateRoleMapping={onClickCreateRoleMapping}
          hasUpdateRoleMappingPermission={hasUpdateRoleMappingPermission}
        />
      ) : (
        <RoleMappingsTable
          organizationId={organizationId}
          roleMappings={roleMappings || []}
          isLoading={isLoading}
          hasDeleteRoleMappingPermission={hasDeleteRoleMappingPermission}
          hasUpdateRoleMappingPermission={hasUpdateRoleMappingPermission}
          readonly={readonly}
        />
      )}
    </EuiPanel>
  )
}

const NoRoleMappings = ({
  readonly,
  onClickCreateRoleMapping,
  hasUpdateRoleMappingPermission,
}: {
  readonly?: boolean
  onClickCreateRoleMapping: () => void
  hasUpdateRoleMappingPermission: boolean
}) => {
  const { formatMessage } = useIntl()
  return (
    <EuiFlexGroup className='no-data-flex-group'>
      <EuiFlexItem className='no-data-left-panel'>
        <EuiText color='subdued'>
          {formatMessage(messages.roleMappingsDescription, {
            lm: (msg) => <SecurityDocsLink fragment='ec_role_mappings'>{msg}</SecurityDocsLink>,
          })}
        </EuiText>
      </EuiFlexItem>
      {!readonly && (
        <EuiFlexItem className='no-data-right-panel'>
          <EuiText>
            <EuiEmptyPrompt
              className='no-data-prompt'
              color='subdued'
              title={<h4>{formatMessage(messages.titleCreateFirstRoleMapping)}</h4>}
              body={<p>{formatMessage(messages.description)}</p>}
              actions={
                <EuiButton
                  color='primary'
                  fill={true}
                  onClick={onClickCreateRoleMapping}
                  disabled={!hasUpdateRoleMappingPermission}
                >
                  {formatMessage(messages.btnCreateRoleMapping)}
                </EuiButton>
              }
            />
          </EuiText>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default RoleMappings
