/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { parse } from 'query-string'

import { checkForOktaAndHandleLogin, loginAndRedirect } from '@/actions/auth/auth'
import { createMarketplaceUser } from '@/actions/saasUsers'
import { getLoginRequest, createMarketplaceUserRequest } from '@/reducers/asyncRequests/registry'
import { getMfa } from '@/reducers/auth'

import MarketplaceSignupModalV2 from './MarketplaceSignupModalV2'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { RouteComponentProps } from 'react-router'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  {
    location,
  }: {
    location: RouteComponentProps['location']
  },
): StateProps => {
  const query = parse(location.search.slice(1))

  return {
    token: typeof query.token === 'string' ? query.token : undefined,
    offerType: typeof query.offer === 'string' ? query.offer : undefined,
    agreementId: typeof query.agreement === 'string' ? query.agreement : undefined,
    productId: typeof query.product === 'string' ? query.product : undefined,
    hasSubscription: Boolean(query.hasSubscription),
    createMarketplaceUserRequest: createMarketplaceUserRequest(state),
    loginRequest: getLoginRequest(state),
    bearerToken: state.auth?.token,
    mfa: getMfa(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  createMarketplaceUser: ({ email, password, captcha_token }) =>
    dispatch(
      createMarketplaceUser({
        email,
        password,
        captcha_token,
        has_accepted_terms_and_policies: true,
        source: 'aws-marketplace',
      }),
    ),
  loginAndRedirect: ({ credentials, oktaRedirectUrl, redirectTo }) =>
    dispatch(loginAndRedirect({ oktaRedirectUrl, redirectTo, credentials })),
  checkForOktaAndHandleLogin: (data) => dispatch(checkForOktaAndHandleLogin(data)),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(MarketplaceSignupModalV2),
)
