/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import moment from 'moment'
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
  EuiTitle,
  toSentenceCase,
  useEuiTheme,
} from '@elastic/eui'

import type { Incident } from '@modules/cloud-api/v1/types'
import { useGetCloudStatusQuery } from '@modules/cloud-lib/general/hooks'
import { CuiBeveledIcon } from '@modules/cui/BeveledIcon'

import { elasticSupportUrl } from '@/apps/userconsole/urls'
import ExternalLink from '@/components/ExternalLink'
import PortalTile from '@/components/Portal/components/PortalTile'

import { getCloudStatusColor, getCloudStatusIconType } from './helpers'

import type { FC } from 'react'

const CloudStatusTile: FC = () => {
  const theme = useEuiTheme()
  const { data, isFetching } = useGetCloudStatusQuery()

  if (!data) {
    return null
  }

  const { status, incidents } = data

  if (!status.indicator || status.indicator === 'none') {
    return null
  }

  return (
    <Fragment>
      <PortalTile data-test-id='portal-cloud-status-tile'>
        <EuiFlexGroup responsive={false} alignItems='center' gutterSize='s'>
          <EuiFlexItem grow={false}>
            <CuiBeveledIcon
              type={getCloudStatusIconType(status)}
              baseColor={getCloudStatusColor(status.indicator)}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiTitle size='xs'>
              <h2>
                <ExternalLink
                  showExternalLinkIcon={false}
                  color='text'
                  data-test-id='portal-cloud-status-tile-title-link'
                  href={elasticSupportUrl}
                >
                  <strong>
                    <FormattedMessage
                      id='cloud-portal.platform-status-title'
                      defaultMessage='Cloud status'
                    />
                  </strong>
                </ExternalLink>
              </h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='m' />

        {!status.indicator && isFetching ? (
          <Fragment>
            <EuiSpacer size='s' />
            <EuiSkeletonText lines={1} />
          </Fragment>
        ) : (
          <div css={css({ paddingLeft: theme.euiTheme.size.s })}>
            <EuiDescriptionList>
              <EuiDescriptionListTitle
                css={incidents.length === 0 && css({ fontWeight: 'normal' })}
              >
                <EuiHealth color={getCloudStatusColor(status.indicator)}>
                  {toSentenceCase(status.description)}
                </EuiHealth>
              </EuiDescriptionListTitle>

              <StatusIncidents incidents={incidents} />
            </EuiDescriptionList>
          </div>
        )}
      </PortalTile>

      <EuiSpacer size='l' />
    </Fragment>
  )
}

const StatusIncidents: FC<{ incidents: Incident[] }> = ({ incidents }) => {
  const theme = useEuiTheme()

  const parseDate = (strDate) => {
    const dateTime = moment(strDate)
    return {
      time: moment.utc(dateTime).format('LT'),
      date: moment.utc(dateTime).format('LL'),
    }
  }

  return (
    <div
      css={css({
        marginLeft: theme.euiTheme.size.s,
        paddingLeft: theme.euiTheme.size.m,
        borderLeft: `1px solid ${theme.euiTheme.colors.lightShade}`,
      })}
      data-test-id={`portal-cloud-status-incidents`}
    >
      {incidents.map((incident, index) => {
        const { time, date } = parseDate(incident.created_at)

        return (
          <Fragment key={incident.id}>
            {index > 0 && <EuiSpacer size='m' />}
            <EuiDescriptionListDescription>
              <ExternalLink
                href={incident.url}
                data-test-id={`portal-cloud-status-incident-item-link-${index}`}
              >
                {incident.name}
              </ExternalLink>
              <EuiSpacer size='xs' />
              <EuiText color='subdued' size='xs'>
                <FormattedMessage
                  id='cloud-platform-status-timestamp'
                  defaultMessage='Began at {time} on {date}'
                  values={{ time, date }}
                />
              </EuiText>
              <EuiSpacer size='xs' />
              <EuiText color='subdued' size='xs'>
                <span>
                  <FormattedMessage
                    id='cloud-platform-incident-current-status'
                    defaultMessage='Current status: {status}'
                    values={{ status: incident.status }}
                  />
                </span>
              </EuiText>
            </EuiDescriptionListDescription>
          </Fragment>
        )
      })}
    </div>
  )
}

export default CloudStatusTile
