/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiSpacer, EuiText, useEuiTheme } from '@elastic/eui'

import type { ResourceType } from '@modules/discovery-questions-lib/utils'

import bgPatternTop from './files/bg-pattern-top.svg'
import bgPatternBottom from './files/bg-pattern-bottom.svg'
import imgServerless from './files/bg-crossed-servers.svg'
import imgStatefull from './files/bg-clouds.svg'

import type { FC, ReactNode } from 'react'

type Props = {
  resourceType: ResourceType
}
type PanelComponents = {
  backgroundColor: string
  img: string
  title: ReactNode
  description: ReactNode
}

const RightSidePanelContent: FC<Props> = ({ resourceType }) => {
  const { formatMessage } = useIntl()
  const {
    euiTheme: {
      size: { m: euiSizeM },
      colors: { darkShade, mediumShade, ghost },
      breakpoint: { m: euiBreakpointM },
    },
    colorMode,
  } = useEuiTheme()

  const panelComponents: Record<ResourceType, PanelComponents> = {
    serverless: {
      backgroundColor: '#101C3F',
      img: imgServerless,
      title: (
        <FormattedMessage
          id='onboarding.side-panel.title.serverless'
          defaultMessage='Elastic Cloud Serverless'
        />
      ),
      description: (
        <FormattedMessage
          id='onboarding.side-panel.description.serverless'
          defaultMessage='our fully managed, autoscaling infrastructure for the easiest way to run your workloads.'
        />
      ),
    },
    stateful: {
      backgroundColor: '#0077CC',
      img: imgStatefull,
      title: (
        <FormattedMessage
          id='onboarding.side-panel.title.stateful'
          defaultMessage='Elastic Cloud Hosted'
        />
      ),
      description: (
        <FormattedMessage
          id='onboarding.side-panel.description.stateful'
          defaultMessage='for experts looking for full control: hosted requires you to manage clusters, upgrades, backup, retention, and scaling.'
        />
      ),
    },
  }

  const panelBg = panelComponents[resourceType].backgroundColor
  const panelImg = panelComponents[resourceType].img
  const panelTitle = panelComponents[resourceType].title
  const panelDescription = panelComponents[resourceType].description

  return (
    <EuiFlexGroup
      direction='column'
      gutterSize='none'
      justifyContent='spaceBetween'
      css={css({
        padding: `0 ${euiSizeM}`,
        background: panelBg,
        backgroundSize: 'cover',
        [`@media(max-width: ${euiBreakpointM})`]: {
          background: 'none',
          display: 'none',
        },
      })}
    >
      <EuiFlexItem
        css={css({
          background: `url("${bgPatternTop}") top right no-repeat`,
        })}
      >
        <EuiSpacer />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction='column' gutterSize='m' alignItems='center'>
          <EuiImage src={panelImg} alt='' />
          <EuiText
            textAlign='center'
            size='xs'
            color={colorMode === 'DARK' ? darkShade : mediumShade}
            css={css`
              font-weight: bold;
            `}
          >
            {formatMessage({
              id: 'onboarding.side-panel.deploying-on',
              defaultMessage: 'deploying trial on',
            }).toUpperCase()}
          </EuiText>
          <EuiText color={ghost} textAlign='center' size='s'>
            <h2>{panelTitle}</h2>
          </EuiText>
          <EuiText
            color={ghost}
            textAlign='center'
            css={css`
              max-width: 329px;
            `}
          >
            {panelDescription}
          </EuiText>
          <EuiSpacer size='xl' />
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem
        css={css({
          background: `url("${bgPatternBottom}") bottom left no-repeat`,
        })}
      >
        <EuiSpacer />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default RightSidePanelContent
