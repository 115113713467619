/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import type { SaasUserUpdateDiscoveryQuestions } from '@modules/cloud-api/v1/types'
import {
  OBSERVABILITY_GET_STARTED_URL,
  SEARCH_GET_STARTED_URL,
  SECURITY_GET_STARTED_URL,
} from '@modules/deployment-creation-lib/urls'
import type { ElasticsearchOptimizedFor } from '@modules/project-user-api/v1/types'
import type { NonEmptyArray } from '@modules/ts-utils'

import { USE_CASES_ID, ResourceType } from './utils'

import type {
  ExperienceLevelOptionsType,
  TrialIntentOptionsType,
  UseCaseOptionsType,
} from './utils'

export enum Step {
  FullName = 'full-name',
  ExperienceLevel = 'experience-level',
  TrialIntent = 'trial-intent',
  UseCase = 'use-case',
  SearchProjectSubtype = 'search-project-subtype',
  CspRegion = 'csp-region',
}

export const RESOURCE_TYPES: NonEmptyArray<ResourceType> = [
  ResourceType.Stateful,
  ResourceType.Serverless,
]

const DEFAULT_STEPS: NonEmptyArray<Step> = [
  Step.FullName,
  Step.ExperienceLevel,
  Step.TrialIntent,
  Step.UseCase,
  Step.CspRegion,
]

export const SEARCH_PROJECT_SUBTYPE_STEPS: NonEmptyArray<Step> = [
  Step.FullName,
  Step.ExperienceLevel,
  Step.TrialIntent,
  Step.UseCase,
  Step.SearchProjectSubtype,
  Step.CspRegion,
]

/**
 * Stricter version of `SaasUserUpdateDiscoveryQuestions`
 * TODO: Ideally this type should be used inside the components
 */
export interface UpdateDiscoveryQuestions extends SaasUserUpdateDiscoveryQuestions {
  use_case: UseCaseOptionsType
  trial_intent: TrialIntentOptionsType
  experience_level: ExperienceLevelOptionsType
}

export interface PrefilledData {
  discoveryQuestions?: Partial<UpdateDiscoveryQuestions>
  additionalParameters?: Partial<{
    optimized_for: ElasticsearchOptimizedFor
  }>
}

export interface OnboardingTokenPrefill {
  skippedSteps?: Step[]
  prefilledData?: PrefilledData
  kibanaDeepLink?: string
}

export enum OnboardingToken {
  // search
  GeneralPurpose = 'search',
  VectorSearch = 'vectorsearch',
  // NO conversions
  Connectors = 'connectors',
  Crawlers = 'crawlers',

  // search optimization
  General = 'general',
  Vector = 'vector',
  // NO conversion
  Timeseries = 'timeseries',

  // observability
  Observability = 'observability',
  APM = 'apm',

  // security
  Security = 'security',

  // vector optimized
  Playground = 'playground',
  // NO conversion
  Semantic = 'semantic',

  // serverless vector optimized
  ServerlessPlayground = 'serverless-playground',
}

const statefulOnboardingTokenPrefills: Partial<Record<OnboardingToken, OnboardingTokenPrefill>> = {
  // search
  [OnboardingToken.GeneralPurpose]: {
    skippedSteps: [Step.UseCase],
    prefilledData: {
      discoveryQuestions: { use_case: USE_CASES_ID.search },
    },
    kibanaDeepLink: SEARCH_GET_STARTED_URL,
  },
  [OnboardingToken.VectorSearch]: {
    skippedSteps: [Step.UseCase],
    prefilledData: {
      discoveryQuestions: { use_case: USE_CASES_ID.search },
    },
    kibanaDeepLink: `/app/enterprise_search/vector_search`,
  },
  [OnboardingToken.Connectors]: {
    skippedSteps: [Step.UseCase],
    prefilledData: {
      discoveryQuestions: { use_case: USE_CASES_ID.search },
    },
    kibanaDeepLink: `/app/enterprise_search/content/connectors`,
  },
  [OnboardingToken.Crawlers]: {
    skippedSteps: [Step.UseCase],
    prefilledData: {
      discoveryQuestions: { use_case: USE_CASES_ID.search },
    },
    kibanaDeepLink: `/app/enterprise_search/content/crawlers/new_crawler`,
  },

  // observability
  [OnboardingToken.Observability]: {
    skippedSteps: [Step.UseCase],
    prefilledData: {
      discoveryQuestions: { use_case: USE_CASES_ID.observability },
    },
    kibanaDeepLink: OBSERVABILITY_GET_STARTED_URL,
  },
  [OnboardingToken.APM]: {
    skippedSteps: [Step.UseCase],
    prefilledData: {
      discoveryQuestions: { use_case: USE_CASES_ID.observability },
    },
    kibanaDeepLink: `/app/home#/tutorial/apm`,
  },

  // security
  [OnboardingToken.Security]: {
    skippedSteps: [Step.UseCase],
    prefilledData: {
      discoveryQuestions: { use_case: USE_CASES_ID.security },
    },
    kibanaDeepLink: SECURITY_GET_STARTED_URL,
  },

  [OnboardingToken.Playground]: {
    skippedSteps: [Step.UseCase],
    prefilledData: {
      discoveryQuestions: { use_case: USE_CASES_ID.search },
    },
    kibanaDeepLink: `/app/enterprise_search/applications/playground`,
  },

  [OnboardingToken.Semantic]: {
    skippedSteps: [Step.UseCase],
    prefilledData: {
      discoveryQuestions: { use_case: USE_CASES_ID.search },
    },
    kibanaDeepLink: `/app/enterprise_search/semantic_search`,
  },
}

const serverlessOnboardingTokenPrefills: Partial<Record<OnboardingToken, OnboardingTokenPrefill>> =
  {
    // search optimizations
    [OnboardingToken.General]: {
      skippedSteps: [Step.UseCase, Step.SearchProjectSubtype],
      prefilledData: {
        discoveryQuestions: { use_case: USE_CASES_ID.search },
        additionalParameters: { optimized_for: 'general_purpose' },
      },
      kibanaDeepLink: `/app/elasticsearch`,
    },
    [OnboardingToken.Timeseries]: {
      // TODO: update with skippedSteps and 'optimized_for' when the latter will be available
      prefilledData: {
        discoveryQuestions: { use_case: USE_CASES_ID.search },
      },
      kibanaDeepLink: `/app/elasticsearch`,
    },
    [OnboardingToken.Vector]: {
      skippedSteps: [Step.UseCase, Step.SearchProjectSubtype],
      prefilledData: {
        discoveryQuestions: { use_case: USE_CASES_ID.search },
        additionalParameters: { optimized_for: 'vector' },
      },
      kibanaDeepLink: `/app/elasticsearch`,
    },
    [OnboardingToken.ServerlessPlayground]: {
      skippedSteps: [Step.UseCase, Step.SearchProjectSubtype],
      prefilledData: {
        discoveryQuestions: { use_case: USE_CASES_ID.search },
        additionalParameters: { optimized_for: 'vector' },
      },
      kibanaDeepLink: `/app/search_playground/chat`,
    },
    // observability
    [OnboardingToken.Observability]: {
      skippedSteps: [Step.UseCase],
      prefilledData: {
        discoveryQuestions: { use_case: USE_CASES_ID.observability },
      },
    },

    // security
    [OnboardingToken.Security]: {
      skippedSteps: [Step.UseCase],
      prefilledData: {
        discoveryQuestions: { use_case: USE_CASES_ID.security },
      },
    },
  }

export const initializeSteps = ({
  onboardingToken,
  resourceType,
  steps = DEFAULT_STEPS,
}: {
  onboardingToken?: OnboardingToken
  resourceType: ResourceType
  steps?: NonEmptyArray<Step>
}): {
  steps: NonEmptyArray<Step>
  getNextStep: (question: Step) => Step | null
  getPrevStep: (question: Step) => Step | null
  initialState: PrefilledData
} => {
  let actualSteps: NonEmptyArray<Step> = steps

  const onboardingTokenPrefills =
    resourceType === ResourceType.Serverless
      ? serverlessOnboardingTokenPrefills
      : statefulOnboardingTokenPrefills

  const vettedOnboardingToken =
    onboardingToken && onboardingToken in onboardingTokenPrefills ? onboardingToken : undefined

  if (vettedOnboardingToken) {
    const skippedSteps = onboardingTokenPrefills[vettedOnboardingToken]?.skippedSteps ?? []
    actualSteps = steps.filter((step) => !skippedSteps.includes(step)) as NonEmptyArray<Step>
  }

  const initialState =
    (vettedOnboardingToken && onboardingTokenPrefills[vettedOnboardingToken]?.prefilledData) ?? {}

  return {
    steps: actualSteps,
    initialState,
    getNextStep: (currentStep) => {
      const next = actualSteps[actualSteps.indexOf(currentStep) + 1]
      return next || null
    },
    getPrevStep: (currentStep) => {
      const prev = actualSteps[actualSteps.indexOf(currentStep) - 1]
      return prev || null
    },
  }
}

export const getKibanaDeploymentDeepLink = (onboardingToken?: OnboardingToken) =>
  onboardingToken && statefulOnboardingTokenPrefills[onboardingToken]?.kibanaDeepLink

export const getKibanaProjectDeepLink = (onboardingToken?: OnboardingToken) =>
  onboardingToken && serverlessOnboardingTokenPrefills[onboardingToken]?.kibanaDeepLink

export const isValidOnboardingToken = (
  maybeOnboardingToken: string | undefined,
): maybeOnboardingToken is OnboardingToken =>
  maybeOnboardingToken
    ? Object.values(OnboardingToken).includes(maybeOnboardingToken as OnboardingToken)
    : false
