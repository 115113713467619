/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import type { WithEuiThemeProps } from '@elastic/eui'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiText,
  EuiSpacer,
  htmlIdGenerator,
  EuiCheckbox,
  EuiFormLabel,
  EuiPanel,
  EuiFormHelpText,
  withEuiTheme,
} from '@elastic/eui'

import DocLink from '@/components/DocLink'
import CustomTextPopover from '@/components/CustomTextPopover'

import EnterOtherAccountRemoteDeployments from '../../components/EnterOtherAccountRemoteDeployments'
import TrustLevelSelector from '../../components/TrustLevelSelector'
import EnterNodeNames from '../../components/EnterNodeNames'

import EnterScopeId from './EnterScopeId'

import type { TrustLevel } from '@/lib/stackDeployments/selectors/crossClusterReplication'

type AtomicUpdate = {
  scopeId: string
  trustLevel: TrustLevel | null
  trustedClusterIds: string[]
  additionalNodeNames: string[]
}

type Props = AtomicUpdate &
  WithEuiThemeProps & {
    onChange: (atomicUpdate: AtomicUpdate) => void
  }
type State = AtomicUpdate & {
  id: string
  trustCommon: boolean
  trustAdditional: boolean
}

const makeId = htmlIdGenerator()

class DirectTrustClusterIds extends React.Component<Props, State> {
  state: State = {
    id: makeId(),
    trustCommon:
      this.props.trustLevel === 'all' ||
      (this.props.trustLevel === 'specific' && this.props.trustedClusterIds.length > 0),
    trustAdditional: this.props.additionalNodeNames.length > 0,
    scopeId: this.props.scopeId,
    trustLevel: this.props.trustLevel,
    trustedClusterIds: this.props.trustedClusterIds,
    additionalNodeNames: this.props.additionalNodeNames,
  }

  render(): JSX.Element {
    const { id, trustCommon, trustAdditional } = this.state

    const commonId = `${id}-common`
    const additionalId = `${id}-additional`

    return (
      <Fragment>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiCheckbox
              id={commonId}
              checked={trustCommon}
              onChange={() => {
                this.setState({ trustCommon: !trustCommon }, this.flush)
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiFormLabel htmlFor={commonId}>
                  <FormattedMessage
                    id='directTrustClusterIds.common.label'
                    defaultMessage='Trust clusters whose Common Name follows the Elastic pattern'
                  />
                </EuiFormLabel>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='s' />
            <EuiText size='s' style={{ maxWidth: `60rem` }}>
              <FormattedMessage
                id='directTrustClusterIds.common.text'
                defaultMessage='The {commonName} (CN) should have this format {pattern}. Trusted clusters must not
              have a blank CN.'
                values={{
                  commonName: this.renderCommonNameHelpText(),
                  pattern: <code>{'{node_id}.node.{cluster_id}.{scope_id}'}</code>,
                }}
              />
            </EuiText>
            {this.renderCommon()}
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='xl' />

        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiCheckbox
              id={additionalId}
              checked={trustAdditional}
              onChange={() => {
                this.setState({ trustAdditional: !trustAdditional }, this.flush)
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiFormLabel htmlFor={additionalId}>
                  <FormattedMessage
                    id='directTrustClusterIds.additional.label'
                    defaultMessage='Trust clusters whose Common Name follows a different naming convention'
                  />
                </EuiFormLabel>
              </EuiFlexItem>
            </EuiFlexGroup>{' '}
            <EuiSpacer size='s' />
            <EuiText size='s' style={{ maxWidth: `60rem` }}>
              To trust a cluster that does not follow the Elastic Cloud pattern, you'll have to add
              each node individually.
            </EuiText>
            {this.renderAdditional()}
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  renderCommon(): JSX.Element | null {
    if (!this.state.trustCommon) {
      return null
    }

    const { theme } = this.props

    const scopeIdLabel = (
      <FormattedMessage id='directTrustClusterIds.scopeId.label' defaultMessage='Scope ID' />
    )

    const trustLevelLabel = (
      <FormattedMessage id='directTrustClusterIds.trustLevel.label' defaultMessage='Trust' />
    )

    return (
      <EuiFlexGroup style={{ maxWidth: `40rem` }}>
        <EuiFlexItem>
          <EuiSpacer size='m' />
          <EuiPanel color='subdued' borderRadius='none' hasShadow={false}>
            <EuiFormRow label={scopeIdLabel}>
              <EnterScopeId
                style={{ backgroundColor: theme.euiTheme.colors.emptyShade }}
                scopeId={this.state.scopeId}
                onChange={(scopeId) => this.setState({ scopeId }, this.flush)}
              />
            </EuiFormRow>

            <EuiSpacer size='m' />

            <EuiFormRow label={trustLevelLabel}>
              <TrustLevelSelector
                trustLevel={this.state.trustLevel}
                onChange={(trustLevel) => this.setState({ trustLevel }, this.flush)}
              />
            </EuiFormRow>

            {this.state.trustLevel === 'specific' && ( // margin to align with radio label above
              <div style={{ marginLeft: `1.72rem` }}>
                <EuiSpacer size='s' />
                <EnterOtherAccountRemoteDeployments
                  inputStyle={{ backgroundColor: theme.euiTheme.colors.emptyShade }}
                  trustedClusterIds={this.state.trustedClusterIds}
                  onChange={(trustedClusterIds) => this.setState({ trustedClusterIds }, this.flush)}
                />
              </div>
            )}
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderAdditional(): JSX.Element | null {
    if (!this.state.trustAdditional) {
      return null
    }

    const { theme } = this.props

    return (
      <EuiFlexGroup style={{ maxWidth: `40rem` }}>
        <EuiFlexItem>
          <EuiSpacer size='m' />
          <EuiPanel color='subdued' borderRadius='none' hasShadow={false}>
            <EnterNodeNames
              inputStyle={{ backgroundColor: theme.euiTheme.colors.emptyShade }}
              additionalNodeNames={this.state.additionalNodeNames}
              onChange={(additionalNodeNames) => this.setState({ additionalNodeNames }, this.flush)}
            />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderCommonNameHelpText(): JSX.Element | null {
    return (
      <CustomTextPopover
        buttonText={<FormattedMessage id='common-name.text' defaultMessage='common name' />}
      >
        <EuiFormHelpText>
          <FormattedMessage
            id='common-name.description'
            defaultMessage='The certificates of your self-managed Elasticsearch cluster nodes contain a common name that usually refers to the hostname. {learnMore}'
            values={{
              learnMore: (
                <DocLink link='esCertutilParamsDocLink'>
                  <FormattedMessage id='common-name.learn-more' defaultMessage='Learn more' />
                </DocLink>
              ),
            }}
          />
        </EuiFormHelpText>
      </CustomTextPopover>
    )
  }

  flush = () => {
    const {
      trustCommon,
      trustAdditional,
      scopeId,
      trustLevel,
      trustedClusterIds,
      additionalNodeNames,
    } = this.state

    this.props.onChange(
      Object.assign(
        {},
        {
          // gate the update by each field's associated checkbox's state
          scopeId: trustCommon ? scopeId : ``,
          trustLevel: trustCommon ? trustLevel : null,
          trustedClusterIds: trustCommon ? trustedClusterIds : [],
          additionalNodeNames: trustAdditional ? additionalNodeNames : [],
        },
      ),
    )
  }
}

export default withEuiTheme(DirectTrustClusterIds)
