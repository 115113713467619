/* eslint-disable notice/notice */
/*
 * This code is forked from the `commons-validator-js`
 * https://github.com/wix-incubator/commons-validator-js/blob/master/src/EmailValidator.js

 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
*/

import { DomainValidator } from './DomainValidator'

export class EmailValidator {
  private _userPattern: RegExp

  private _emailPattern: RegExp

  private _domainValidator: DomainValidator

  private _allowTld: boolean

  /**
   * @param allowTld     Should TLDs be allowed?
   */
  constructor({ allowTld = false } = {}) {
    const specialChars = '\\(\\)<>@,;:\'\\\\\\"\\.\\[\\]'
    const validChars = '(\\\\.)|[^\\s' + specialChars + ']'
    const quotedUser = '("(\\\\"|[^"])*")'
    const word = '((' + validChars + "|')+|" + quotedUser + ')'
    const userRegex = '^\\s*' + word + '(\\.' + word + ')*$'
    this._userPattern = new RegExp(userRegex)

    const emailRegex = '^\\s*?(.+)@(.+?)\\s*$'
    this._emailPattern = new RegExp(emailRegex)

    this._domainValidator = new DomainValidator()
    this._allowTld = allowTld
  }

  _isValidDomain(domain) {
    if (this._allowTld) {
      return (
        this._domainValidator.isValid(domain) ||
        (domain[0] !== '.' && this._domainValidator.isValidTld(domain))
      )
    }

    return this._domainValidator.isValid(domain)
  }

  _isValidUser(user) {
    if (!user || user.length > 64) {
      return false
    }

    return user.match(this._userPattern)
  }

  isValid(email) {
    if (!email) {
      return false
    }

    if (email[email.length - 1] === '.') {
      return false
    }

    const groups = email.match(this._emailPattern)

    if (!groups) {
      return false
    }

    if (!this._isValidUser(groups[1])) {
      return false
    }

    if (!this._isValidDomain(groups[2])) {
      return false
    }

    return true
  }
}
