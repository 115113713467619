/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'

import {
  fetchOrganizationMemberships,
  resetUpdateRoleAssignmentsRequest,
  updateRoleAssignments,
} from '@/actions/organizations'
import { getProfile } from '@/apps/userconsole/reducers/profile'
import {
  addRoleAssignmentsRequest,
  deleteRoleAssignmentsRequest,
} from '@/reducers/asyncRequests/registry'
import { getOrganization } from '@/reducers/organizations'

import { hasSameEmail } from '../lib'

import OrganizationMemberRoleAssignments from './OrganizationMemberRoleAssignments'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { ConsumerProps, DispatchProps, StateProps } from './types'
import type { RoleAssignmentsDiff } from '@/components/Users/RoleAssignmentsPanel/lib'

const mapStateToProps = (
  state: ReduxState,
  { organizationId, member }: ConsumerProps,
): StateProps => ({
  isSaving:
    addRoleAssignmentsRequest(state, organizationId, member.id).inProgress ||
    deleteRoleAssignmentsRequest(state, organizationId, member.id).inProgress,
  isCurrentUser: hasSameEmail(getProfile(state), member),
  organization: getOrganization(state, organizationId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { organizationId, member }: ConsumerProps,
): DispatchProps => ({
  updateRoleAssignments: (
    roleAssignments: RoleAssignments,
    roleAssignmentsDiff: RoleAssignmentsDiff,
  ) =>
    dispatch(
      updateRoleAssignments({
        organizationId,
        userId: member.id,
        roleAssignments,
        roleAssignmentsDiff,
      }),
    ),
  resetUpdateRoleAssignmentsRequest: () =>
    dispatch(resetUpdateRoleAssignmentsRequest(organizationId, member.id)),
  fetchOrganizationMembers: () => dispatch(fetchOrganizationMemberships({ organizationId })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationMemberRoleAssignments)
