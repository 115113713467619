/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { find, isEmpty, isUndefined } from 'lodash'

import type {
  DeploymentTemplateInfoV2,
  GlobalDeploymentTemplateInfo,
} from '@modules/cloud-api/v1/types'
import type { SelectTemplateArgShape } from '@modules/deployment-creation-lib/types'

import { isHiddenTemplate, isMetadataLegacyTemplate } from '../deploymentTemplates/metadata'
import { rcompare } from '../semver'

export function selectTemplateWithSameCategory({
  deploymentTemplates,
  deploymentTemplate,
  globalDeploymentTemplate,
  setDeploymentTemplate,
  regionChanged,
  versionChanged,
}: SelectTemplateArgShape): void {
  const sameCategoryTemplate = getSameCategoryTemplate({
    deploymentTemplates,
    deploymentTemplate,
    globalDeploymentTemplate,
  })

  if (!sameCategoryTemplate) {
    const template = deploymentTemplates?.[0]

    if (template) {
      setDeploymentTemplate(template)
    }

    return
  }

  if (
    deploymentTemplate &&
    deploymentTemplate.id === sameCategoryTemplate.id &&
    !regionChanged &&
    !versionChanged
  ) {
    return
  }

  // `sameNameTemplate.instance_configurations` can be null if the templates were loaded
  // with `showInstanceConfigurations` set to false e.g. on the `Platform > Templates` page.
  // We load them with the flag set to true in the Create component hierarchy, but this guard
  // prevents us setting a template without instance configurations into the Redux state,
  // while we wait for the full configuration data to load
  if (!sameCategoryTemplate.instance_configurations) {
    return
  }

  setDeploymentTemplate(sameCategoryTemplate)
}

function getSameCategoryTemplate({
  deploymentTemplates,
  deploymentTemplate,
  globalDeploymentTemplate,
}: {
  deploymentTemplates?: DeploymentTemplateInfoV2[]
  deploymentTemplate?: DeploymentTemplateInfoV2
  globalDeploymentTemplate?: GlobalDeploymentTemplateInfo
}): DeploymentTemplateInfoV2 | null {
  const visibleTemplates = getVisibleAndNonLegacyTemplatesFromV2({ deploymentTemplates })

  if (isEmpty(visibleTemplates)) {
    return null
  }

  if (!deploymentTemplate) {
    if (!globalDeploymentTemplate) {
      return null
    }

    const selectedGlobalTemplate = find(visibleTemplates, {
      template_category_id: globalDeploymentTemplate.template_category_id,
    })

    if (!selectedGlobalTemplate) {
      return null
    }

    return selectedGlobalTemplate
  }

  const basisForCategory = globalDeploymentTemplate || deploymentTemplate

  const possibleTemplatesForGlobalSelection = visibleTemplates.filter(
    (visibleTemplate) =>
      visibleTemplate.template_category_id === basisForCategory.template_category_id,
  )

  // If 0 matches exist, return null
  // If 1 match exists, choose it.
  // If > 1 match exists, choose template with the highest min_version
  switch (possibleTemplatesForGlobalSelection.length) {
    case 0:
      return null
    case 1:
      // Obviously we've checked the length already
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return possibleTemplatesForGlobalSelection[0]!
    default:
      return getHighestMinVersionTemplate({
        deploymentTemplates: possibleTemplatesForGlobalSelection,
      })
  }
}

export function getVisibleAndNonLegacyTemplatesFromV2({
  deploymentTemplates,
}: {
  deploymentTemplates?: DeploymentTemplateInfoV2[]
}): DeploymentTemplateInfoV2[] {
  if (!deploymentTemplates) {
    return []
  }

  return deploymentTemplates
    .filter((template) => !isHiddenTemplate(template))
    .filter((template) => !isMetadataLegacyTemplate(template))
    .sort(getTemplateSortOrder)
}

function getTemplateSortOrder(a, b) {
  if (!isUndefined(a.order) && !isUndefined(b.order)) {
    return a.order - b.order
  }

  return 0
}

export function getHighestMinVersionTemplate({
  deploymentTemplates,
}: {
  deploymentTemplates: DeploymentTemplateInfoV2[]
}): DeploymentTemplateInfoV2 {
  const sortedTemplates = deploymentTemplates.sort((a, b) => {
    if (!a.min_version && !b.min_version) {
      return 0
    }

    if (!a.min_version) {
      return 1
    }

    if (!b.min_version) {
      return -1
    }

    return rcompare(a.min_version, b.min_version)
  })

  // This function is guaranteed to be called with a non-empty array
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return sortedTemplates[0]!
}

export function getCorrectTemplateForGlobalTemplate({
  deploymentTemplates,
  globalTemplate,
}: {
  deploymentTemplates: DeploymentTemplateInfoV2[]
  globalTemplate: GlobalDeploymentTemplateInfo
}) {
  const matchingDeploymentTemplates = deploymentTemplates.filter(
    (visibleTemplate) =>
      visibleTemplate.template_category_id === globalTemplate.template_category_id,
  )

  switch (matchingDeploymentTemplates.length) {
    case 0:
      return null
    case 1:
      return matchingDeploymentTemplates[0]
    default:
      return getHighestMinVersionTemplate({
        deploymentTemplates: matchingDeploymentTemplates,
      })
  }
}
