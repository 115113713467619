/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { css, jsx } from '@emotion/react'
import { useQueryClient } from 'react-query'

import {
  EuiBetaBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiLink,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useEuiTheme,
  useEuiBackgroundColor,
} from '@elastic/eui'

import { useAddSaasUserToWaitListMutation } from '@modules/cloud-lib/users/hooks'
import { useLocalStorage } from '@modules/utils/hooks/useLocalStorage'
import { CuiAlert } from '@modules/cui/Alert'
import LocalStorageKey from '@modules/utils/localStorageKeys'
import { useGetOrganizationProfileQuery } from '@modules/profile-lib/hooks'
import JoinServerlessWaitListButton from '@modules/project-user-components/JoinServerlessWaitListPanel/JoinServerlessWaitListButton'
import type { ProfileState } from '@modules/ui-types'
import { CuiFeatureEnabled, CuiFeatureFlagGate } from '@modules/cui/FeatureFlagGate'
import Feature from '@modules/utils/feature'
import bannerImageLight from '@modules/project-user-lib/images/waitlist_banner_svg_image.svg'
import bannerImageDark from '@modules/project-user-lib/images/waitlist_banner_svg_image_dark.svg'

import type { IntlShape } from 'react-intl'

type Props = {
  intl: IntlShape
  profile: ProfileState
}

const messages = defineMessages({
  close: {
    id: `join-wait-list-panel.close-icon-label`,
    defaultMessage: `Dismiss`,
  },
  waitList: {
    id: `join-wait-list-panel.title-icon-label`,
    defaultMessage: `Wait list`,
  },
  preview: {
    id: `join-wait-list-panel.title-preview-label`,
    defaultMessage: `Preview`,
  },
})

const JoinServerlessWaitListPanel: React.FunctionComponent<Props> = ({ intl, profile }) => {
  const { formatMessage } = intl
  const queryClient = useQueryClient()
  const { euiTheme, colorMode } = useEuiTheme()
  const bannerImage = colorMode === 'LIGHT' ? bannerImageLight : bannerImageDark
  const panelContentStyles = css({
    position: 'relative',
    background: `${useEuiBackgroundColor('success')} url("${bannerImage}") no-repeat -50px bottom`,
  })

  const titleBadgeStyles = css({
    backgroundColor: euiTheme.colors.success,
    inlineSize: '28px',
    lineHeight: '28px',
    borderRadius: '28px',
  })

  const userId = profile?.user_id.toString()
  const organizationId = profile?.organization_id
  const {
    data: organizationProfile,
    isFetching: isFetchingOrganizationProfile,
    isError: isFetchingOrganizationProfileError,
  } = useGetOrganizationProfileQuery({ organizationId })
  const addSaasUserToServerlessWaitListMutation = useAddSaasUserToWaitListMutation({
    organizationId,
    userId,
  })

  const addToWaitList = () => {
    addSaasUserToServerlessWaitListMutation.mutate(undefined, {
      onSuccess: () => queryClient.invalidateQueries('getOrganizationProfile'),
    })
  }

  const [dismissed, setDismissed] = useLocalStorage(
    `${LocalStorageKey.joinServerlessWaitListDismissed}_${profile?.txid}`,
    false,
  )

  const onDismissJoinWaitListPanel = () => {
    setDismissed(true)
  }

  if (dismissed || isFetchingOrganizationProfileError) {
    return null
  }

  return (
    <CuiFeatureFlagGate feature={Feature.serverless} ldFlag='showServerlessWaitlistBanner'>
      <CuiFeatureEnabled>
        {addSaasUserToServerlessWaitListMutation.error && (
          <Fragment>
            <CuiAlert type='danger' data-test-id='add-to-wait-list-error'>
              {addSaasUserToServerlessWaitListMutation.error}
            </CuiAlert>

            <EuiSpacer size='m' />
          </Fragment>
        )}
        <EuiPanel paddingSize='m' css={panelContentStyles}>
          <EuiButtonIcon
            css={css({ position: 'absolute', right: '18px' })}
            data-test-id='dismiss-panel-icon-button'
            iconType='cross'
            onClick={onDismissJoinWaitListPanel}
            aria-label={formatMessage(messages.close)}
          />

          <EuiFlexGroup direction='column' gutterSize='m'>
            <EuiFlexItem>
              <EuiFlexGroup
                gutterSize='m'
                justifyContent='flexStart'
                alignItems='center'
                responsive={false}
              >
                <EuiFlexItem grow={false}>
                  <EuiBetaBadge
                    label={formatMessage(messages.waitList)}
                    color='accent'
                    iconType='cheer'
                    css={titleBadgeStyles}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiTitle size='xs'>
                    <h4>
                      <FormattedMessage
                        id='join-waitlist-panel.title'
                        defaultMessage='Introducing serverless preview!'
                      />
                    </h4>
                  </EuiTitle>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiFlexGroup gutterSize='m' direction='column' alignItems='flexEnd'>
                <EuiFlexItem grow={3}>
                  <EuiText size='s'>
                    <FormattedMessage
                      id='join-waitlist-panel.description'
                      defaultMessage='Elastic’s fully managed serverless solutions are now available in preview. With this new architecture, indexing and search are now decoupled, allowing for greater performance and stability. Forget about managing nodes, shards, data tiers and scaling to maintain the health and performance of your deployments. We’re now doing that for you.
            <link>Learn more</link>'
                      values={{
                        link: (content) => (
                          <EuiLink href='https://ela.st/join-serverless-waitlist' target='_blank'>
                            {content}
                          </EuiLink>
                        ),
                      }}
                    />
                  </EuiText>
                  <EuiSpacer size='s' />
                  <EuiText size='s'>
                    <FormattedMessage
                      id='join-waitlist-panel.description-term'
                      defaultMessage='By continuing, you agree to the <link>Preview Terms of Use.</link>'
                      values={{
                        link: (content) => (
                          <EuiLink href='https://ela.st/serverless-preview-terms' target='_blank'>
                            {content}
                          </EuiLink>
                        ),
                      }}
                    />
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <JoinServerlessWaitListButton
                    addToWaitList={addToWaitList}
                    isLoading={
                      isFetchingOrganizationProfile ||
                      addSaasUserToServerlessWaitListMutation.isLoading
                    }
                    organizationProfile={organizationProfile}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>

        <EuiSpacer />
      </CuiFeatureEnabled>
    </CuiFeatureFlagGate>
  )
}

export default injectIntl(JoinServerlessWaitListPanel)
