/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText } from '@elastic/eui'

import type { AnyClusterPlanInfo, AnyResourceInfo, SliderInstanceType } from '@modules/ui-types'
import { CuiLink } from '@modules/cui/Link'

import { isPlanSystemInitiated } from '@/lib/stackDeployments/selectors/fundamentals'
import { parsePlanAttemptError } from '@/lib/stackDeployments/selectors/configurationChangeAttempts'
import DocLink from '@/components/DocLink'

import { supportUrl } from '../../urlBuilder'
import { getConfigForKey } from '../../../store'
import { PlanChangeErrorTypes } from '../../../reducers/lib/errorTypes'

import configurationChangeErrorDescriptions from './configurationChangeErrorMessages'
import OutOfCapacityCulprits from './OutOfCapacityCulprits'

import type {
  PlanAttemptErrorDetails,
  ParsedPlanAttemptError,
} from '@/lib/stackDeployments/selectors/configurationChangeAttempts'
import type { ReactElement, ReactNode } from 'react'

type ConfigurationChangeError = {
  title: ReactElement
  description?: ReactNode
  size?: 's' | 'm'
}

type DetailedConfigurationChangeError = ConfigurationChangeError & {
  details: PlanAttemptErrorDetails
}

export function parseConfigurationChangeError({
  resource,
  resourceType,
  planAttempt,
  linkify,
}: {
  resource: AnyResourceInfo
  resourceType: SliderInstanceType
  planAttempt: AnyClusterPlanInfo
  linkify?: boolean
}): DetailedConfigurationChangeError | null {
  const parsedError = parsePlanAttemptError({ planAttempt })

  if (!parsedError) {
    return null
  }

  const configurationChangeError = describeConfigurationChangeError({
    resource,
    resourceType,
    planAttempt,
    linkify,
    parsedError,
  })

  const { details } = parsedError

  return { ...configurationChangeError, details }
}

function describeConfigurationChangeError({
  resource,
  resourceType,
  planAttempt,
  linkify,
  parsedError,
}: {
  errorType?: string
  resource: AnyResourceInfo
  resourceType: SliderInstanceType
  planAttempt: AnyClusterPlanInfo
  linkify?: boolean
  parsedError: ParsedPlanAttemptError
}): ConfigurationChangeError {
  const { errorType } = parsedError

  switch (errorType) {
    case PlanChangeErrorTypes.NOT_ENOUGH_CAPACITY:
      return describeCapacityError({
        resource,
        resourceType,
        planAttempt,
        linkify,
      })

    case PlanChangeErrorTypes.TIMEOUT:
      return describeTimeoutError()

    case PlanChangeErrorTypes.CANCELLED_PLAN:
      return describeCancelError()

    case PlanChangeErrorTypes.TOPOLOGY_CHANGE_ROLLING_STRATEGY:
      return describeRollingTopologyError()

    default:
      return parseErrorMessage({ parsedError, linkify, planAttempt })
  }
}

function parseErrorMessage({
  parsedError,
  linkify,
  planAttempt,
}: {
  parsedError: ParsedPlanAttemptError
  linkify?: boolean
  planAttempt: AnyClusterPlanInfo
}): ConfigurationChangeError {
  const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`

  const isSystemInitiated = isPlanSystemInitiated({ planAttempt })
  const isAutoscalingFacilitated = planAttempt.source?.facilitator === `autoscaling`

  return {
    title: (
      <FormattedMessage
        id='configuration-change-errors.generic-title'
        defaultMessage='There was a problem applying this configuration change'
      />
    ),
    description: (
      <EuiText size='s'>
        {isUserConsole && (
          <p>
            {getContactSupportDescription(isAutoscalingFacilitated, isSystemInitiated, linkify)}
          </p>
        )}
        {getConfigurationChangeErrorDescription({ parsedError })}
      </EuiText>
    ),
  }
}

function describeTimeoutError(): ConfigurationChangeError {
  return {
    title: (
      <FormattedMessage
        id='configuration-change-errors.timeout-title'
        defaultMessage='This configuration change timed out'
      />
    ),
    description: (
      <FormattedMessage
        id='configuration-change-errors.timeout-description'
        defaultMessage='A timeout occurred while applying the configuration change. This may happen when the requested changes are time consuming.'
      />
    ),
  }
}

function describeCancelError(): ConfigurationChangeError {
  return {
    title: (
      <FormattedMessage
        id='configuration-change-errors.cancel-title'
        defaultMessage='This configuration change was cancelled'
      />
    ),
    size: `s`,
  }
}

function describeRollingTopologyError(): ConfigurationChangeError {
  return {
    title: (
      <FormattedMessage
        id='configuration-change-errors.rolling-topology-title'
        defaultMessage='This configuration change was invalid'
      />
    ),
    description: (
      <EuiText>
        <p>
          <FormattedMessage
            id='configuration-change-errors.rolling-topology-description'
            defaultMessage='You cannot perform a major version upgrade and change the cluster topology (memory, number of zones, dedicated master nodes, etc...) at the same time. You need to perform these configuration changes separately.'
          />
        </p>
        <p>
          <FormattedMessage
            id='configuration-change-errors.rolling-topology-solution'
            defaultMessage='For cluster topology changes, apply the configuration change by creating new nodes. For major version upgrades, perform a rolling upgrade.'
          />
        </p>
      </EuiText>
    ),
  }
}

function describeCapacityError({
  resource,
  resourceType,
  planAttempt,
  linkify,
}: {
  resource: AnyResourceInfo
  resourceType: SliderInstanceType
  planAttempt: AnyClusterPlanInfo
  linkify?: boolean
}) {
  const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`

  if (isUserConsole) {
    return describeTemporarilyOutOfCapacityError()
  }

  return describeOutOfCapacityError({
    resource,
    resourceType,
    planAttempt,
    linkify,
  })
}

function describeTemporarilyOutOfCapacityError(): ConfigurationChangeError {
  return {
    title: (
      <FormattedMessage
        id='configuration-change-errors.temporarily-out-of-capacity-title'
        defaultMessage='Temporarily out of capacity'
      />
    ),
    description: (
      <FormattedMessage
        id='configuration-change-errors.temporarily-out-of-capacity-description'
        defaultMessage="It's not you, it's us. We couldn't fulfill your request, but we've alerted our engineers to add more capacity to our pool of resources in the zones that you requested. This typically takes less than an hour and we'll reach out to you."
      />
    ),
  }
}

function describeOutOfCapacityError({
  resource,
  resourceType,
  planAttempt,
  linkify,
}: {
  resource: AnyResourceInfo
  planAttempt: AnyClusterPlanInfo
  resourceType: SliderInstanceType
  linkify?: boolean
}) {
  return {
    title: (
      <FormattedMessage
        id='configuration-change-errors.not-enough-capacity-title'
        defaultMessage='Out of capacity'
      />
    ),
    description: (
      <OutOfCapacityCulprits
        resource={resource}
        resourceType={resourceType}
        planAttempt={planAttempt}
        linkify={linkify}
      />
    ),
  }
}

function getContactSupportDescription(
  isAutoscalingFacilitated: boolean,
  isSystemInitiated: boolean,
  linkify?: boolean,
) {
  const contactSupportText = (
    <FormattedMessage
      id='configuration-change-errors.contact-support'
      defaultMessage='contact Support'
    />
  )

  const contactSupport = linkify ? (
    <CuiLink to={supportUrl()}>{contactSupportText}</CuiLink>
  ) : (
    contactSupportText
  )

  if (isAutoscalingFacilitated) {
    return (
      <FormattedMessage
        id='configuration-change-errors.generic-message-autoscaling'
        defaultMessage='There was a problem with autoscaling applying changes to your deployment. If the problem persists, please {contactSupport}.'
        values={{ contactSupport }}
      />
    )
  }

  if (isSystemInitiated) {
    return (
      <FormattedMessage
        id='configuration-change-errors.generic-message-system'
        defaultMessage='This was a system initiated change and an internal ticket has been raised for resolution. Please {contactSupport} if you have further questions.'
        values={{ contactSupport }}
      />
    )
  }

  return (
    <FormattedMessage
      id='configuration-change-errors.generic-message'
      defaultMessage='Please try again and if the problem persists {contactSupport}.'
      values={{ contactSupport }}
    />
  )
}

function getConfigurationChangeErrorDescription({
  parsedError,
}: {
  parsedError: ParsedPlanAttemptError
}): ReactNode {
  const { details, errorType, message: rawMessage } = parsedError
  const prettyMessage = rawMessage.replace(/^Unexpected error during step: /, ``)

  const genericDescription = (
    <p data-test-id='configuration-change-error-description'>{prettyMessage}</p>
  )

  if (!errorType) {
    return genericDescription
  }

  const [category, failure] = errorType.split(':')
  const description =
    category && failure ? configurationChangeErrorDescriptions[category][failure] : undefined

  if (!description) {
    return genericDescription
  }

  if (category === `ClusterFailure` && failure === `InstancesAreNotRunning`) {
    return (
      <FormattedMessage
        data-test-id='configuration-change-error-description'
        {...description}
        values={{
          link: (
            <DocLink link='configChangeErrors'>
              <FormattedMessage
                id='configuration-change-errors.link-to-docs'
                defaultMessage='Learn more'
              />
            </DocLink>
          ),
          ...details,
        }}
      />
    )
  }

  return (
    <FormattedMessage
      data-test-id='configuration-change-error-description'
      {...description}
      values={details}
    />
  )
}

export function isDryRunAttempt({ planAttempt }: { planAttempt: AnyClusterPlanInfo }) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!planAttempt) {
    return false
  }
  // eslint-disable-next-line
  const step_id = planAttempt?.error ? planAttempt.error?.details?.step_id || '' : ''
  return (
    // eslint-disable-next-line
    planAttempt?.plan?.transient?.plan_configuration?.dry_run ||
    // this is very hacky and will be replaced as soon as CP-9241 is done
    step_id === 'Dry run plan has finished successfully and intentionally aborted the next steps'
  )
}

export function getDryRunErrorDescription() {
  return {
    title: (
      <FormattedMessage
        id='dry-run-title'
        defaultMessage='This was a dry run, no changes were applied'
      />
    ),
    description: (
      <FormattedMessage
        id='dry-run-message-description'
        defaultMessage='Everything is working as expected and no changes were made to your deployment.'
      />
    ),
  }
}
