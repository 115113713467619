/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  roleMappingsTitle: {
    id: 'organization.security.role-mappings.title-role-mappings',
    defaultMessage: 'Role mappings',
  },
  roleMappingsDescription: {
    id: 'organization.security.role-mappings.description-role-mappings',
    defaultMessage:
      'Define the roles assigned to members of the organization when they log in with SSO. <lm>Learn more</lm>',
  },
  titleCreateFirstRoleMapping: {
    id: 'organization.security.role-mappings.title-create-first-role-mapping',
    defaultMessage: 'Create your first role mapping',
  },
  description: {
    id: 'organization.security.role-mappings.description-create-first-role-mapping',
    defaultMessage: 'Role mappings control which roles are assigned to your users.',
  },
  btnCreateRoleMapping: {
    id: 'organization.security.role-mappings.btn-create-role-mapping',
    defaultMessage: 'Create role mapping',
  },
})
