/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  roleChangeSuccess: {
    id: `organization.organization-member-role-assignments.success`,
    defaultMessage: `Role changes successfully applied.`,
  },
  roleChangeFailure: {
    id: `organization.organization-member-role-assignments.failure`,
    defaultMessage: `Failed to apply role changes.`,
  },
  cancelFlyout: {
    id: `organization.organization-member-role-assignments.cancel`,
    defaultMessage: `Cancel`,
  },
  save: {
    id: `organization.organization-member-role-assignments.save`,
    defaultMessage: `Save`,
  },
  userId: {
    id: `organization.organization-member-role-assignments.user-id`,
    defaultMessage: `User ID`,
  },
  roles: {
    id: `organization.organization-member-role-assignments.roles`,
    defaultMessage: `Roles`,
  },
  ssoEnforcementCannotEditRoles: {
    id: `organization.organization-member-role-assignments.sso-enforcement-cannot-edit-roles`,
    defaultMessage: `This organization enforces SSO login and roles are determined by the SSO role mapping configuration.`,
  },
  cancelEdit: {
    id: `organization.organization-member-role-assignments.cancel-edit`,
    defaultMessage: `Cancel edit`,
  },
  edit: {
    id: `organization.organization-member-role-assignments.edit`,
    defaultMessage: `Edit`,
  },
  warningSsoEnabledTitle: {
    id: `organization.organization-member-role-assignments.warning-sso-enabled-title`,
    defaultMessage: `Role changes can be overwritten`,
  },
  warningSsoEnabledMessage: {
    id: `organization.organization-member-role-assignments.warning-sso-enabled-message`,
    defaultMessage: `This organization has enabled SSO login. If an SSO role mapping exists and applies to this user, their roles will reset as defined in the role mapping when they log in with SSO, and any changes you make here will be lost.`,
  },
  warningSsoEnforcedTitle: {
    id: `organization.organization-member-role-assignments.warning-sso-enforced-title`,
    defaultMessage: `Role changes cannot be made`,
  },
  warningSsoEnforcedMessage: {
    id: `organization.organization-member-role-assignments.warning-sso-enforced-message`,
    defaultMessage: `This organization enforces SSO login. Roles are determined by the SSO role mapping configuration and cannot be edited here.`,
  },
})
