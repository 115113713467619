/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import SecurityDocsLink from '../../SecurityDocsLink'

const RoleMappingHeader = ({ isEditMode }: { isEditMode: boolean }) => (
  <div>
    <EuiTitle size='l'>
      <h2>
        {isEditMode ? (
          <FormattedMessage
            id='organization.security.role-mapping.edit-role-mapping'
            defaultMessage='Edit role mapping'
          />
        ) : (
          <FormattedMessage
            id='organization.security.role-mapping.create-role-mapping'
            defaultMessage='Create role mapping'
          />
        )}
      </h2>
    </EuiTitle>
    <EuiSpacer size='m' />
    <EuiText>
      <FormattedMessage
        id='organization.security.edit-role-mapping.description'
        defaultMessage='Use role mappings to control which roles are assigned to your users based on their attributes in your Identity Provider configuration. <lm>Learn more</lm>'
        values={{
          lm: (message) => (
            <SecurityDocsLink fragment='ec_role_mappings'>{message}</SecurityDocsLink>
          ),
        }}
      />
    </EuiText>
  </div>
)

export default RoleMappingHeader
