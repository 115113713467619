/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import type { ApmBase } from '@elastic/apm-rum'

import { blockCurrentApmTransaction, setApmUserContext, startPageActions } from '@modules/utils/apm'
import MfaForm from '@modules/mfa-login/MfaForm'
import type { SaasAuthMfaDeviceResponse, SaasAuthResponse } from '@modules/cloud-api/v1/types'

import MarketplaceExistingUserNotice from '@/components/UserAuthNotice/MarketplaceExistingUserNotice'

import LandingPageContainer from '../../../../../../components/LandingPageContainer'
import UserRegistrationForm from '../../../../../../components/UserRegistration/Form'

import type { AllProps } from './types'

type GetCurrentTransaction = ReturnType<ApmBase['getCurrentTransaction']>

class PartnerSignupRegistration extends Component<AllProps> {
  startedTransaction: GetCurrentTransaction = undefined

  componentDidMount(): void {
    if (!this.startedTransaction) {
      this.startedTransaction = startPageActions(`AWS MP`)
    }
  }

  render(): JSX.Element {
    const { mfa } = this.props

    return (
      <LandingPageContainer title={this.renderFormTitle()} subtitle={this.renderFormSubTitle()}>
        <MarketplaceExistingUserNotice partner={'aws'} />
        {mfa.mfa_required && mfa.state_id ? (
          this.renderMfa()
        ) : (
          <UserRegistrationForm
            createUser={this.convertUser}
            showSocialLogin={false}
            isMarketplace={true}
          />
        )}
      </LandingPageContainer>
    )
  }

  renderMfa(): JSX.Element | undefined {
    const { mfa, checkForOktaAndHandleLogin, token, agreementId, productId } = this.props

    if (mfa && mfa.state_id && token && agreementId && productId) {
      const encodedToken = decodeURIComponent(token).replace(/ /g, '+')
      const encodedAgreementId = decodeURIComponent(agreementId).replace(/ /g, '+')
      const encodedProductId = decodeURIComponent(productId).replace(/ /g, '+')
      return (
        <MfaForm
          devices={mfa.mfa_devices as SaasAuthMfaDeviceResponse[]}
          stateId={mfa.state_id}
          isFlowV2={false}
          onSuccess={(data: SaasAuthResponse) => {
            const {
              token,
              okta_session_id,
              session_expiration_time,
              require_email_verification,
              email_verified,
            } = data
            checkForOktaAndHandleLogin({
              token,
              okta_session_id,
              session_expiration_time,
              require_email_verification,
              email_verified,
              redirectTo: `/marketplace-conversion#token=${encodedToken}&partner=aws&agreementId=${encodedAgreementId}&productId=${encodedProductId}`,
            })
          }}
        />
      )
    }

    return undefined
  }

  renderFormTitle(): JSX.Element {
    return (
      <FormattedMessage
        id='marketplace-registration-signup-title'
        defaultMessage='Create your account'
      />
    )
  }

  renderFormSubTitle(): JSX.Element {
    return (
      <FormattedMessage
        id='marketplace-registration-signup-subtitle-aws'
        defaultMessage='Elastic Cloud powered by AWS Marketplace'
      />
    )
  }

  getContextSwitchLocationState = (): { hasExistingSubscription: boolean } | undefined => {
    const { history } = this.props
    const {
      location: { state: locationState },
    } = history

    return locationState
  }

  convertUser = ({ email, password, captcha_token }) => {
    const { createMarketplaceUser, loginAndRedirect, token, agreementId, productId } = this.props
    blockCurrentApmTransaction()

    return createMarketplaceUser({ email, password, captcha_token }).then(({ payload }) => {
      if (!password || !token || !productId || !agreementId) {
        return
      }

      setApmUserContext(String(payload?.user.user_id), email)

      const encodedToken = decodeURIComponent(token).replace(/ /g, '+')
      const encodedAgreementId = decodeURIComponent(agreementId).replace(/ /g, '+')
      const encodedProductId = decodeURIComponent(productId).replace(/ /g, '+')

      return loginAndRedirect({
        credentials: { email, password },
        redirectTo: `/marketplace-conversion#token=${encodedToken}&partner=aws&agreementId=${encodedAgreementId}&productId=${encodedProductId}`,
      })
    })
  }
}

export default PartnerSignupRegistration
