/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { orderBy } from 'lodash'
import { css, jsx } from '@emotion/react'

import { EuiText } from '@elastic/eui'

import { CuiTable } from '@modules/cui/Table'
import type { CuiTableColumn } from '@modules/cui/Table'
import type { Price } from '@modules/billing-api/v1/types'

import { responsiveTableStyles } from '../styles'
import { getDisplayPrices } from '../utils'
import { PromotionalPeriodBadge } from '../PromotionalPeriodBadge'

import type { DisplayPrice } from '../types'

interface Props {
  prices: Price[]
  isLoading: boolean
}

const PriceList = ({ prices, isLoading }: Props) => {
  const columns: Array<CuiTableColumn<DisplayPrice>> = [
    {
      label: (
        <FormattedMessage id='pricing-list.serverless.product-column' defaultMessage='Product' />
      ),
      render: ({ name, isTier, preview_prices }: DisplayPrice) => {
        if (isTier) {
          return <i css={css({ marginLeft: 12 })}>{name}</i>
        }

        return (
          <p>
            {name} {preview_prices && <PromotionalPeriodBadge />}
          </p>
        )
      },
    },
    {
      label: <FormattedMessage id='pricing-list.serverless.price-column' defaultMessage='Price' />,
      render: ({ unit_amount, preview_unit_amount, isRootTier }: DisplayPrice) => {
        if (isRootTier) {
          return null
        }

        return (
          <EuiText size='s'>
            <code>${Number(preview_unit_amount || unit_amount || 0).toFixed(4)}</code>
          </EuiText>
        )
      },
      width: '90px',
      align: 'right',
    },
    {
      label: <FormattedMessage id='pricing-list.serverless.unit-column' defaultMessage='Unit' />,
      render: ({ unit, pricing_period, isRootTier }: DisplayPrice) => {
        if (!unit || isRootTier) {
          return null
        }

        return `${unit}` + (pricing_period ? `-${pricing_period}` : '')
      },
      width: '180px',
      align: 'left',
    },
  ]

  return (
    <div css={responsiveTableStyles}>
      <CuiTable
        initialLoading={isLoading}
        rows={getDisplayPrices(orderBy(prices, ['name']))}
        columns={columns}
        data-test-id='serverless-price-list'
        responsive={false}
      />
    </div>
  )
}

export default PriceList
