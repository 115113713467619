/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useQuery } from 'react-query'

import type { QueryHookOptions } from '@modules/query/types'
import { getSaasStatusUrl } from '@modules/cloud-api/v1/urls'
import { fetchAsJson } from '@modules/query/helpers'
import type { Summary } from '@modules/cloud-api/v1/types'

export const useGetCloudStatusQuery = (options?: QueryHookOptions) =>
  useQuery(
    'getCloudStatus',
    () =>
      fetchAsJson<Summary>(getSaasStatusUrl(), {
        method: 'get',
      }),
    options,
  )
