/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { countBy, isEmpty } from 'lodash'

import type {
  RoleAssignments,
  RoleMapping,
  RoleMappingGroupRule,
  RoleMappingRule,
} from '@modules/cloud-api/v1/types'
import { isRoleAssignmentsEmpty } from '@modules/role-assignments-lib'

import { formMessages } from './messages'

import type { OrgOwnerMappingError, FormError, RuleError } from './types'
import type { IntlShape, MessageDescriptor } from 'react-intl'

export const validateForm =
  ({
    formatMessage,
    originalRoleMapping,
    roleMappings = [],
    organizationId,
  }: {
    formatMessage: IntlShape['formatMessage']
    originalRoleMapping?: RoleMapping | undefined
    roleMappings: RoleMapping[] | undefined
    organizationId: string
  }): ((values: RoleMapping) => FormError) =>
  (values) => {
    const errors: FormError = {}

    const getMessage = (message: MessageDescriptor | undefined) => {
      if (message) {
        return formatMessage(message)
      }

      return undefined
    }

    const orgOwnerError = validateOrgOwner({
      roleMappings,
      originalRoleMapping,
      newRoleAssignments: values.role_assignments,
      newEnabledState: values.enabled,
      organizationId,
    })
    orgOwnerError && (errors.missingOrgOwnerMapping = orgOwnerError)

    const nameError = getMessage(
      validateName({
        roleMappings,
        originalRoleMappingName: originalRoleMapping?.name,
        name: values.name,
      }),
    )
    nameError && (errors.name = nameError)

    if (isRoleAssignmentsEmpty(values.role_assignments)) {
      errors.role_assignments = formatMessage(formMessages.roleAssignmentsRequired)
    }

    const ruleMappingType = values.rule.all ? 'all' : 'any'
    const mappingRuleArray = values.rule.all || values.rule.any

    const ruleError = getMessage(
      validateRule({
        rule: values.rule,
        mappingRuleArray,
      }),
    )
    ruleError && (errors.rule = ruleError)

    if (typeof errors.rule !== 'string' && mappingRuleArray && mappingRuleArray.length > 0) {
      const innerRuleError = validateMappingRules(mappingRuleArray, ruleMappingType, formatMessage)
      innerRuleError && (errors.rule = innerRuleError)
    }

    return isEmpty(errors) ? {} : errors
  }

export const validateOrgOwner = ({
  roleMappings,
  originalRoleMapping,
  newRoleAssignments,
  newEnabledState,
  organizationId,
}: {
  roleMappings: RoleMapping[]
  originalRoleMapping?: RoleMapping | undefined
  newRoleAssignments: RoleAssignments | undefined
  newEnabledState: boolean
  organizationId: string
}): OrgOwnerMappingError | undefined => {
  const isOrgOwnerRole = (roleAssignments: RoleAssignments | undefined) =>
    roleAssignments?.organization?.find(
      (role) => role.role_id === 'organization-admin' && role.organization_id === organizationId,
    )
  const isEnabledOrgOwnerRole = (roleAssignments: RoleAssignments | undefined, enabled: boolean) =>
    isOrgOwnerRole(roleAssignments) && enabled

  if (roleMappings.length === 0 && !isEnabledOrgOwnerRole(newRoleAssignments, newEnabledState)) {
    return 'FIRST_MAPPING_MUST_BE_ORG_OWNER'
  }

  if (roleMappings.length === 0) {
    return
  }

  const orgOwnerRoleMappings = roleMappings.filter((roleMapping) =>
    isEnabledOrgOwnerRole(roleMapping.role_assignments, roleMapping.enabled),
  )
  const isTheLastOrgOwnerRole =
    roleMappings.length >= 1 &&
    orgOwnerRoleMappings.length === 1 &&
    orgOwnerRoleMappings[0]?.name === originalRoleMapping?.name

  if (isTheLastOrgOwnerRole && !isOrgOwnerRole(newRoleAssignments)) {
    return 'CANNOT_REMOVE_LAST_ORG_OWNER_MAPPING'
  }

  if (isTheLastOrgOwnerRole && !newEnabledState) {
    return 'CANNOT_DISABLE_ORG_OWNER_LAST_MAPPING'
  }

  return undefined
}

const validateName = ({
  roleMappings,
  originalRoleMappingName,
  name,
}: {
  roleMappings: RoleMapping[]
  originalRoleMappingName?: string
  name: string
}): MessageDescriptor | undefined => {
  if (!name) {
    return formMessages.nameRequired
  }

  const existingRoleMapping = roleMappings.find((roleMapping) => roleMapping.name === name)

  if (existingRoleMapping && existingRoleMapping.name !== originalRoleMappingName) {
    return formMessages.roleMappingNameExists
  }

  return undefined
}

const validateRule = ({
  rule,
  mappingRuleArray,
}: {
  rule: RoleMappingRule
  mappingRuleArray: RoleMappingGroupRule[] | undefined
}): MessageDescriptor | undefined => {
  if ((!rule.all && !rule.any) || !mappingRuleArray || mappingRuleArray.length === 0) {
    return formMessages.ruleRequired
  }

  return undefined
}

function validateMappingRules(
  mappingRuleArray: RoleMappingGroupRule[],
  ruleMappingType: string,
  formatMessage,
): RuleError | undefined {
  const ruleErrors: RuleError = {}

  const groupNames = mappingRuleArray.map((el) => el.group)
  const groupCount = countBy(groupNames)
  ruleErrors[`${ruleMappingType}`] = ruleErrors[`${ruleMappingType}`] || []
  const ruleTypeErrors: string[] = ruleErrors[`${ruleMappingType}`] as string[]

  mappingRuleArray.forEach((attribute, index) => {
    const groupValue = attribute.group

    if (!groupValue) {
      ruleTypeErrors[index] = formatMessage(formMessages.attributeValueRequired)
      return
    }

    if ((groupCount[groupValue] ?? 0) > 1) {
      ruleTypeErrors[index] = formatMessage(formMessages.duplicateGroupValue)
    }
  })

  return isEmpty(ruleErrors) || (isEmpty(ruleErrors.any) && isEmpty(ruleErrors.all))
    ? undefined
    : ruleErrors
}
