/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import ReactDOM from 'react-dom'

import DriftChatIframe from './DriftChatIframe'

import type { ReactNode } from 'react'
import type { ChatApi } from './DriftChatIframe'
import type { AllProps } from './types'

export type Props = AllProps & {
  children: (props: { triggerChat: () => void }) => ReactNode | null
}

export function DriftChat({ children, profile, ...props }: Props) {
  const [readyToRenderTrigger, setReadyToRenderTrigger] = React.useState(false)
  const [chatApi, setChatApi] = React.useState<ChatApi | null>(null)

  if (!profile) {
    return null
  }

  return (
    <React.Fragment>
      {readyToRenderTrigger &&
        children({
          triggerChat: () => {
            chatApi?.toggle()
          },
        })}
      {ReactDOM.createPortal(
        <DriftChatIframe
          {...props}
          profile={profile}
          onReady={(_chatApi) => {
            setChatApi(_chatApi)
          }}
          onPlaybookFired={() => {
            setReadyToRenderTrigger(true)
          }}
          styles={{
            // reset default button positioning
            bottom: 'auto',
            left: 'auto',
            // force position to the top the page without overlapping with the header
            top: 32,
            right: 0,
          }}
        />,
        document.body,
      )}
    </React.Fragment>
  )
}
