/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { noop } from 'lodash'

import { getFeaturesUsageUrl } from '@modules/cloud-api/v1/urls'
import type { GetFeaturesUsageUrlParams } from '@modules/cloud-api/v1/urls'

import asyncRequest, { resetAsyncRequest } from '../../../../actions/asyncRequests'
import { SAVE_BILLING_DETAILS, FETCH_USAGE_DETAILS } from '../../constants/actions'

import type { ThunkAction } from '@/types/redux'

export type FetchUsageDetailsProps = GetFeaturesUsageUrlParams

export function fetchUsageDetails(props: FetchUsageDetailsProps): ThunkAction {
  const url = getFeaturesUsageUrl(props)

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: FETCH_USAGE_DETAILS,
        url,
      }),
    ).catch(noop)
}

export const resetSaveBillingDetailsRequest = () => resetAsyncRequest(SAVE_BILLING_DETAILS)
