/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  loadingDomains: {
    id: `organization.security.domain-claims-table.loading-domains`,
    defaultMessage: `Loading domains`,
  },
  noDomains: {
    id: `organization.security.domain-claims-table.no-domains`,
    defaultMessage: `No verified domains`,
  },
  removeDomain: {
    id: `organization.security.domain-claims-table.remove-domain`,
    defaultMessage: `Remove domain`,
  },
  removeDomainDisabledIdpConfigured: {
    id: `organization.security.domain-claims-table.remove-domain-disabled-idp-configured`,
    defaultMessage: `You have only one verified domain. You cannot remove it while SSO is enabled.`,
  },
  removeDomainConfirmation: {
    id: `organization.security.domain-claims-table.remove-domain-confirmation`,
    defaultMessage: `Confirm to remove domain`,
  },
})

export const removeDomainErrorMessages = defineMessages({
  unknownError: {
    id: `organization.security.domain-claims-table.remove-domain-err.unknown`,
    defaultMessage: `Unknown error while removing domain. Try again later.`,
  },
  'org.idp.idp_configured': {
    id: `organization.security.domain-claims-table.remove-domain-err.idp-enabled`,
    defaultMessage: `You must have at least one verified domain when SSO is in use. Disable SSO or add another domain to remove this domain.`,
  },
})
