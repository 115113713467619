/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router'

import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiSpacer, EuiText } from '@elastic/eui'

import { useGetOrganizationSsoFindIdp } from '@modules/security-idp-lib/hooks'
import { ssoLoginUrl } from '@modules/auth/urls'

import { useSso } from '@/components/Sso/lib'

type Props = {
  onGoBack: () => void
  onSignUpWithEmail: () => void
  email: string
}

const SsoSwitch = ({ onGoBack, onSignUpWithEmail, email }: Props) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { data } = useGetOrganizationSsoFindIdp({
    email: email || '',
    options: { enabled: !!email },
  })
  const { idps } = data || {}
  const { initiateSsoRedirect } = useSso({ loginIdentifier: idps?.[0]?.login_identifier })

  if (!idps) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment />
  }

  const onLoginWithSso = () => {
    if (idps.length === 1) {
      initiateSsoRedirect()
      return
    }

    if (idps.length > 1) {
      history.push({ pathname: ssoLoginUrl(), state: { idps, from: pathname } })
    }
  }

  return (
    <EuiFlexGroup direction='column' alignItems='center'>
      <EuiText size='s' textAlign='center'>
        <h2>
          <FormattedMessage
            id='user-registration-form.sso-switch.title'
            defaultMessage='Your email is associated with at least one existing Elastic Cloud organization'
          />
        </h2>
        <EuiSpacer size='m' />
        <p>
          <FormattedMessage
            id='user-registration-form.sso-switch.description'
            defaultMessage='Sign up with your email to create your own Elastic Cloud organization and start a trial, or log in with SSO to join one of the organizations associated with your email domain.'
          />
        </p>
      </EuiText>
      <EuiFlexGroup direction='column' style={{ width: 'min(320px, 100%)' }}>
        <EuiButton fill={true} fullWidth={true} onClick={onSignUpWithEmail}>
          <FormattedMessage
            id='user-registration-form.sign-up-with-email'
            defaultMessage='Sign up with email'
          />
        </EuiButton>
        <EuiButton fullWidth={true} onClick={onLoginWithSso}>
          <FormattedMessage
            id='user-registration-form.login-with-sso'
            defaultMessage='Log in with SSO'
          />
        </EuiButton>
        <EuiButtonEmpty onClick={onGoBack}>
          {'← '}
          <FormattedMessage
            id='user-registration-form.go-back-to-user-registration-form'
            defaultMessage='Go back'
          />
        </EuiButtonEmpty>
      </EuiFlexGroup>
    </EuiFlexGroup>
  )
}

export default SsoSwitch
