/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EuiButton, EuiCallOut, EuiFieldText, EuiSpacer, EuiText, EuiTextAlign } from '@elastic/eui'

import { useGetOrganizationSsoFindIdp } from '@modules/security-idp-lib/hooks'
import type { FindIdpResult } from '@modules/cloud-api/v1/types'

import TermsOfService from '@/components/CreateAccountForm/TermsOfService'
import validateEmail from '@/lib/validateEmail'

import { errorMessages, messages } from '../messages'
import { useSso } from '../../lib'

const EmailForSso = ({
  onReceiveIdpList,
}: {
  onReceiveIdpList: (idpList: FindIdpResult[]) => void
}) => {
  const { formatMessage } = useIntl()
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { initiateSsoRedirect } = useSso({})
  const {
    data,
    isFetching: isFetchingIdpList,
    error,
    refetch: refetchIdpList,
  } = useGetOrganizationSsoFindIdp({ email, options: { enabled: false, cacheTime: 0 } }) // cacheTime: 0 to ensure useQuery doesn't take the cached value when the same email is submitted.
  const { idps } = data || {}

  useEffect(() => {
    setErrorMessage(null)

    if (error) {
      if (error.errors?.[0]) {
        setErrorMessage(
          errorMessages[error.errors[0].code]
            ? formatMessage(errorMessages[error.errors[0]?.code])
            : error.errors[0].message,
        )
        return
      }

      setErrorMessage(formatMessage(errorMessages.unknownErrorFindingIdp))
      return
    }

    if (idps?.length === 0) {
      setErrorMessage(formatMessage(errorMessages.errorNoIdpDetected))
      return
    }

    if (!idps || isFetchingIdpList) {
      return
    }

    if (idps.length === 1) {
      initiateSsoRedirect(idps[0]?.login_identifier)
      return
    }

    if (idps.length > 0) {
      onReceiveIdpList(idps)
      return
    }
  }, [data, error, isFetchingIdpList]) // we need isFetchingIdpList to ensure if the same email is submitted consecutively (e.g: users use back button and resubmit), useEffect will be triggered again.

  const onSubmit = (event) => {
    event.preventDefault()

    if (!validateEmail(email)) {
      setErrorMessage(formatMessage(messages.invalidEmail))
      return
    }

    refetchIdpList()
  }

  return (
    <Fragment>
      <form onSubmit={onSubmit}>
        <EuiFieldText
          value={email}
          name='email'
          icon={'email'}
          onChange={(e) => setEmail(e.target.value)}
          onSubmit={onSubmit}
          placeholder={formatMessage(messages.email)}
          aria-label={formatMessage(messages.email)}
        />
        <EuiSpacer size='m' />
        <TermsOfService />
        <EuiSpacer size='m' />
        <EuiButton
          onClick={onSubmit}
          isDisabled={!email}
          isLoading={isFetchingIdpList}
          fill={true}
          fullWidth={true}
          type='submit'
        >
          <FormattedMessage {...messages.loginButton} />
        </EuiButton>
      </form>
      <EuiSpacer size='m' />
      {errorMessage && <ErrorPrompt message={errorMessage} />}
    </Fragment>
  )
}

const ErrorPrompt = ({ message }: { message: string }) => (
  <EuiCallOut color='danger' iconType='alert'>
    <EuiText size='m' textAlign='center' color='danger'>
      <EuiTextAlign textAlign='left'>{message}</EuiTextAlign>
    </EuiText>
  </EuiCallOut>
)

export default EmailForSso
