/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  updateOrganization,
  fetchOrganizationIfNeeded,
  resetUpdateOrganizationRequest,
} from '@/actions/organizations'
import { updateOrganizationRequest } from '@/reducers/asyncRequests/registry'
import { getOrganization } from '@/reducers/organizations'

import { LoginEnforcement } from './LoginEnforcement'

import type {
  DispatchProps,
  AuthenticationEnforcementParams,
  ConsumerProps,
  StateProps,
} from './types'
import type { ThunkDispatch } from '@/types/redux'

const mapStateToProps = (state, { organizationId }: ConsumerProps): StateProps => ({
  updateOrganizationRequest: updateOrganizationRequest(state, organizationId),
  organization: getOrganization(state, organizationId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { organizationId }: ConsumerProps,
): DispatchProps => ({
  updateLoginEnforcement: (authenticationEnforcement: AuthenticationEnforcementParams) =>
    dispatch(updateOrganization({ organizationId, organization: authenticationEnforcement })),
  fetchOrganizationIfNeeded: () => dispatch(fetchOrganizationIfNeeded({ organizationId })),
  resetUpdateOrganizationRequest: () => dispatch(resetUpdateOrganizationRequest(organizationId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginEnforcement)
