/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { capitalize } from 'lodash'

import type { ChangeSourceInfo, ClusterPlanStepInfo } from '@modules/cloud-api/v1/types'

import descriptions from './planMessageDescriptions'

type StepDescription = {
  status?: string
  value: string
}

function formatStep(stepId: string): string {
  if (!stepId) {
    return stepId
  }

  return capitalize(stepId.replace(/-/g, ` `))
}

export function describePlanAttemptStep(
  planAttemptMessage: ClusterPlanStepInfo,
  { isCancelled, isDryRun = false }: { isCancelled?: boolean; isDryRun?: boolean } = {},
): StepDescription {
  const { step_id: stepId, status } = planAttemptMessage
  const description = descriptions.get(stepId) || formatStep(stepId)

  // An array of stepIds that should be returning an error status
  const errors = [`step-failed`, `rollback`, `step-rollback`, `error`]

  if (isCancelled) {
    return {
      status: `error`,
      value: `Cancelling changes`,
    }
  }

  if (stepId === `plan-completed` || stepId === `success`) {
    if (status === `error` && isDryRun) {
      return {
        // Currently, the dry run has a step indicating a failure to apply changes (as expected),
        // but this failure refers to the inability to apply changes, which may not truly be considered a failure.
        // In CP-9241, we will change the logic around what is considered a failure in the dry run.
        // for the moment the UI will just change the message in the case of a dry run until we implement what's on CP-9241.
        status: `success`,
        value: 'Plan successful (Dry run)',
      }
    }

    if (status === `error`) {
      return {
        status: `error`,
        value: `Plan failed`,
      }
    }

    return {
      status: `success`,
      value: description,
    }
  }

  if (errors.includes(stepId)) {
    return {
      status: `error`,
      value: description,
    }
  }

  return {
    value: description,
  }
}

export function describePlanAttemptSource({ source }: { source: ChangeSourceInfo }): string | null {
  if (typeof source.action !== `string`) {
    return null
  }

  if (source.action.endsWith(`move-instances`)) {
    return `Moving nodes`
  }

  return null
}
