/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  loginButton: {
    id: `cloud-sso-page.domain-match.login-button`,
    defaultMessage: `Log in`,
  },
  loginViaSso: {
    id: `cloud-sso-page.domain-match.login-via-sso`,
    defaultMessage: `Log in to your account with SSO`,
  },
  selectOrganization: {
    id: `cloud-sso-page.domain-match.select-organization`,
    defaultMessage: `Select the organization you want to log in to`,
  },
  onlyBelongToOneOrganization: {
    id: `cloud-sso-page.domain-match.only-belong-to-one-organization`,
    defaultMessage: `You can only belong to one Elastic Cloud organization at a time. You must leave your current organization before joining another one.`,
  },
  nextButton: {
    id: `cloud-sso-page.domain-match.next-button`,
    defaultMessage: `Next`,
  },
  goBackButton: {
    id: `cloud-sso-page.domain-match.go-back-button`,
    defaultMessage: `Go back`,
  },
  email: {
    id: `cloud-sso-page.domain-match.email`,
    defaultMessage: `Email`,
  },
  invalidEmail: {
    id: `cloud-sso-page.domain-match.invalid-email`,
    defaultMessage: `Enter a valid email address.`,
  },
})

export const errorMessages = defineMessages({
  'auth.sso_not_supported_by_org': {
    id: `cloud-sso-page.domain-match.sso-not-supported-by-org`,
    defaultMessage: `Your Elastic organization doesn't support SSO. Check the email that you entered or log in with a different method.`,
  },
  unknownErrorFindingIdp: {
    id: `cloud-sso-page.domain-match.unknown-error`,
    defaultMessage: `An unknown error occurred while searching for matching identity providers. Try again later.`,
  },
  errorNoIdpDetected: {
    id: `cloud-sso-page.domain-match.no-idp-found`,
    defaultMessage: `This email isn't associated with an SSO account. Check the email that you entered or log in with a different method.`,
  },
})
