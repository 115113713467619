/* eslint-disable notice/notice */
/*
 * This code is forked from the `commons-validator-js`
 * https://github.com/wix-incubator/commons-validator-js/blob/master/src/DomainValidator.js

 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
*/

import * as punycode from 'punycode'

import { includes } from 'lodash'

import * as Domains from './domains'

export class DomainValidator {
  private _domainRegex: RegExp

  constructor() {
    const domainLabelRegex = '[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?'
    const topLabelRegex = '[a-zA-Z](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?'
    const domainNameRegex = '^(?:' + domainLabelRegex + '\\.)*(' + topLabelRegex + ')\\.?$'
    this._domainRegex = new RegExp(domainNameRegex)
  }

  _chompLeadingDot(str) {
    if (str[0] === '.') {
      return str.substring(1)
    }

    return str
  }

  _unicodeToASCII(input) {
    return punycode.toASCII(input)
  }

  _arrayContains(sortedArray, key) {
    // TODO: use binary search
    return includes(sortedArray, key)
  }

  isValidCountryCodeTld(ccTld) {
    const key = this._chompLeadingDot(this._unicodeToASCII(ccTld).toLowerCase())
    return this._arrayContains(Domains.countryCodeTlds, key)
  }

  isValidGenericTld(gTld) {
    const key = this._chompLeadingDot(this._unicodeToASCII(gTld).toLowerCase())
    return this._arrayContains(Domains.genericTlds, key)
  }

  isValidInfrastructureTld(iTld) {
    const key = this._chompLeadingDot(this._unicodeToASCII(iTld).toLowerCase())
    return this._arrayContains(Domains.infrastructureTlds, key)
  }

  isValidTld(tld) {
    const asciiTld = this._unicodeToASCII(tld)
    return (
      this.isValidInfrastructureTld(asciiTld) ||
      this.isValidGenericTld(asciiTld) ||
      this.isValidCountryCodeTld(asciiTld)
    )
  }

  extractTld(domain) {
    if (!domain) {
      return false
    }

    const asciiDomain = this._unicodeToASCII(domain)

    if (asciiDomain.length > 253) {
      return false
    }

    const groups = asciiDomain.match(this._domainRegex)

    if (groups) {
      return groups[1]
    }

    return null
  }

  isValid(domain) {
    if (!domain) {
      return false
    }

    const asciiDomain = this._unicodeToASCII(domain)

    if (asciiDomain.length > 253) {
      return false
    }

    const groups = asciiDomain.match(this._domainRegex)

    if (groups && groups.length > 1) {
      return this.isValidTld(groups[1]) && groups[0] !== groups[1]
    }

    return false
  }
}
