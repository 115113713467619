/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation, useQuery } from 'react-query'

import {
  deleteDomainClaim,
  generateDomainVerificationCode,
  getDomainClaims,
  getOrganizationIdp,
  getRoleMappings,
  getOrganizationSsoFindIdp,
  updateOrganizationIdp,
  updateRoleMappings,
  verifyDomainClaim,
  deleteRoleMappings,
} from '@modules/security-idp-api'
import { queryClient } from '@modules/query'
import type { ApiErrorCollection, QueryFunctionReturnType } from '@modules/query/types'
import type { IdpConfiguration } from '@modules/security-idp-api/types'
import type { FindIdpResults, RoleMapping } from '@modules/cloud-api/v1/types'
import type { MultiErrorResponse } from '@modules/project-user-api/v1/types'

import type { UseQueryOptions } from 'react-query'

const domainClaimsKey = (organizationId: string) => [
  'organization',
  organizationId,
  'domains',
  'domain-claims',
]
const idpKey = (organizationId: string) => ['organization', organizationId, 'idp']

export const roleMappingKey = (organizationId: string) => [
  'organization',
  organizationId,
  'role-mappings',
]

export const useGetDomainsQuery = (organizationId: string | undefined) =>
  useQuery({
    enabled: !!organizationId,
    queryKey: organizationId && domainClaimsKey(organizationId),
    queryFn: () => getDomainClaims(organizationId as string),
  })

export const useGenerateDomainVerificationCode = () =>
  useMutation({
    mutationFn: ({ organizationId, domainName }: { organizationId: string; domainName: string }) =>
      generateDomainVerificationCode(organizationId, domainName),
  })

export const useVerifyDomainClaim = () =>
  useMutation({
    mutationFn: ({ organizationId, domainName }: { organizationId: string; domainName: string }) =>
      verifyDomainClaim(organizationId, domainName),
    onSuccess: (_, { organizationId }: { organizationId: string; domainName: string }) => {
      queryClient.invalidateQueries(domainClaimsKey(organizationId))
    },
  })

export const useDeleteDomainClaim = () =>
  useMutation({
    mutationFn: ({ organizationId, domainName }: { organizationId: string; domainName: string }) =>
      deleteDomainClaim(organizationId, domainName),
    onSuccess: (_, { organizationId }: { organizationId: string; domainName: string }) => {
      queryClient.invalidateQueries(domainClaimsKey(organizationId))
    },
  })

export const useOrganizationIdp = (organizationId: string | undefined) =>
  useQuery({
    enabled: !!organizationId,
    queryKey: organizationId && idpKey(organizationId),
    queryFn: () => getOrganizationIdp(organizationId as string),
    refetchOnWindowFocus: false,
    retry: (failureCount, error: ApiErrorCollection) =>
      !!(error.statusCode && error.statusCode >= 500 && failureCount < 3), //Only retry 2 times if it is 5XX error
  })

export const useOrganizationIdpMutation = () =>
  useMutation({
    mutationFn: ({
      organizationId,
      idpConfiguration,
    }: {
      organizationId: string
      idpConfiguration: IdpConfiguration
    }) => updateOrganizationIdp({ organizationId, idpConfiguration }),
    onSuccess: async (
      { configuration },
      { organizationId }: { organizationId: string; idpConfiguration: IdpConfiguration },
    ) => {
      const previousIdpInfo = queryClient.getQueryData(idpKey(organizationId))

      queryClient.setQueryData(idpKey(organizationId), {
        ...(previousIdpInfo || {}),
        configuration: { ...configuration },
      })
      queryClient.invalidateQueries(idpKey(organizationId))
    },
  })

export const useGetRoleMappingsQuery = (organizationId: string | undefined) =>
  useQuery({
    enabled: !!organizationId,
    queryKey: organizationId && roleMappingKey(organizationId),
    queryFn: () => getRoleMappings(organizationId as string),
  })

export const useUpdateRoleMappings = () =>
  useMutation<
    QueryFunctionReturnType<typeof updateRoleMappings>,
    MultiErrorResponse,
    { organizationId: string; mappings: RoleMapping[] }
  >({
    mutationFn: ({ organizationId, mappings }) => updateRoleMappings({ organizationId, mappings }),
    onSuccess: (_, { organizationId }) => {
      queryClient.invalidateQueries(roleMappingKey(organizationId))
    },
  })

export const useGetOrganizationSsoFindIdp = ({
  email,
  options,
}: {
  email: string
  options?: UseQueryOptions<unknown, ApiErrorCollection, FindIdpResults>
}) =>
  useQuery<unknown, ApiErrorCollection, FindIdpResults>({
    ...options,
    enabled: options?.enabled ?? !!email,
    queryKey: ['organization', 'sso', 'find-idp', email],
    queryFn: () => getOrganizationSsoFindIdp(email),
  })

export const useDeleteRoleMappings = () =>
  useMutation<
    QueryFunctionReturnType<typeof deleteRoleMappings>,
    MultiErrorResponse,
    { organizationId: string }
  >({
    mutationFn: ({ organizationId }) => deleteRoleMappings({ organizationId }),
    onSuccess: (_, { organizationId }) => {
      queryClient.invalidateQueries(roleMappingKey(organizationId))
    },
  })
