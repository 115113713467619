/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { flatMap, isEmpty, uniq } from 'lodash'

import {
  EuiCallOut,
  EuiErrorBoundary,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'

import { updateDeploymentUrl } from '@modules/cloud-api/v1/urls'
import type { ElasticsearchClusterPlan, PlanStrategy } from '@modules/cloud-api/v1/types'
import type { AnyTopologyElement } from '@modules/ui-types'
import { CuiRouterLinkButtonEmpty } from '@modules/cui/RouterButton'
import { CuiAlert } from '@modules/cui/Alert'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import { isEsStopped } from '@/lib/stackDeployments/selectors/configurationChanges'
import { getVersion, getSliderPlanFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import { getSliderPlan, getEsPlan } from '@/lib/stackDeployments/selectors/stackDeployment'
import { getDeploymentNodeConfigurations } from '@/lib/stackDeployments/selectors/topologyElements'
import { canEnableAutoscaling } from '@/lib/stackDeployments/selectors/autoscaling'
import { deploymentUrl } from '@/lib/urlBuilder'
import { getSupportedSliderInstanceTypes } from '@/lib/sliders/support'
import { getTopologiesFromTemplate } from '@/lib/deploymentTemplates/getTopologiesFromTemplate'
import { validateUpdateRequest } from '@/lib/stackDeployments/validation'
import { sanitizeUpdateRequestBeforeSend } from '@/lib/stackDeployments/updates'
import { defaultStrategy } from '@/lib/stackDeployments/strategies'
import { promoteConfigurationEntriesToPlan } from '@/lib/stackDeployments/configuration'
import { exceededTrialNodes } from '@/lib/stackDeployments/trials'

import DangerButton from '../../../DangerButton'
import ExternalLink from '../../../ExternalLink'
import ApiRequestExample from '../../../ApiRequestExample'
import FailoverOptions from '../FailoverOptions'
import Strategies from '../../../DeploymentConfigure/Strategies'
import StackDeploymentUpdateDryRunWarnings from '../../../StackDeploymentUpdateDryRunWarnings'
import StackDeploymentUpdateDryRunWarningCheck from '../../../StackDeploymentUpdateDryRunWarningCheck'
import { ArchitectureSummary } from '../../../Topology/DeploymentTemplates/components/ArchitectureViz'
import AutoscalingToggle from '../../../Autoscaling/AutoscalingToggle'
import { signUp } from '../../../../apps/userconsole/urls'
import CreditCardModalButton from '../../../../apps/userconsole/components/Billing/CreditCard/CreditCardModalButton'
import DeploymentInfrastructure from '../../../Topology/DeploymentTemplates/components/DeploymentInfrastructure'
import DeploymentConfigurationChangeExplain from '../../../StackDeploymentConfigurationChange/StackConfigurationChangeExplain/DeploymentConfigurationChangeExplain'

import NodeRolesWarning from './NodeRolesWarning'

import type { AllProps } from './types'

class EditDeploymentFromTemplate extends Component<AllProps> {
  static defaultProps = {
    architectureSummary: ArchitectureSummary,
  }

  componentDidMount() {
    const {
      editorState: { regionId },
      fetchInstanceConfigurationsIfNeeded,
      invalidateDerivedSettings,
    } = this.props

    fetchInstanceConfigurationsIfNeeded(regionId).then(invalidateDerivedSettings)
  }

  render() {
    const {
      architectureSummary,
      basedOnAttempt,
      editorState,
      hideConfigChangeStrategy,
      hideExtraFailoverOptions,
      instanceConfigurations,
      isHeroku,
      onWidePlanChange,
      updateStackDeploymentRequest,
      inTrial,
    } = this.props

    const ArchitectureSummaryComponent = architectureSummary!

    const { deployment, deploymentUnderEdit, deploymentTemplate } = editorState

    const displayName = deployment.name

    const version = this.getVersion()
    const strategy = this.getStrategy()
    const pendingPlan = this.getPlan()
    const configurationIds = this.getConfigurationIds()

    const didLoadEveryInstanceConfiguration: boolean =
      configurationIds.length === 0 ||
      Boolean(
        instanceConfigurations &&
          configurationIds.every((id) =>
            instanceConfigurations.some((instanceConfiguration) => instanceConfiguration.id === id),
          ),
      )

    if (!didLoadEveryInstanceConfiguration || pendingPlan?.cluster_topology == null) {
      return <EuiSkeletonText lines={6} />
    }

    const exceededNodes = exceededTrialNodes({ deployment, inTrial, instanceConfigurations })

    const displayAutoscalingToggle = canEnableAutoscaling({ deploymentTemplate, version })

    return (
      <EuiErrorBoundary>
        {isHeroku && (
          <Fragment>
            <EuiCallOut
              color='warning'
              title={
                <FormattedMessage
                  id='edit-deployment-from-template.heroku-frozen-topology-title'
                  defaultMessage='Some features are not available to Heroku users'
                />
              }
            >
              <FormattedMessage
                id='edit-deployment-from-template.heroku-frozen-topology-description'
                defaultMessage="You can edit user settings, change plugins, and upgrade versions. However, you can't edit the size per zone or number of availability zones. Want to take advantage of these features and more? {signupToElasticCloud} today."
                values={{
                  signupToElasticCloud: (
                    <ExternalLink href={signUp()}>
                      <FormattedMessage
                        id='edit-deployment-from-template.sign-up'
                        defaultMessage='Sign up to Elastic Cloud'
                      />
                    </ExternalLink>
                  ),
                }}
              />
            </EuiCallOut>

            <EuiSpacer size='m' />
          </Fragment>
        )}

        <EuiFlexGroup gutterSize='xl'>
          <EuiFlexItem grow={false}>
            {displayAutoscalingToggle && (
              <AutoscalingToggle
                onChangeAutoscaling={this.onChangeAutoscaling}
                deployment={deployment}
                deploymentTemplate={deploymentTemplate}
              />
            )}

            {this.renderNodeConfigurations()}

            <EuiTitle size='s'>
              <div>
                <FormattedMessage
                  id='edit-deployment-from-template.settings'
                  defaultMessage='Settings'
                />
              </div>
            </EuiTitle>

            <EuiSpacer size='m' />

            {hideConfigChangeStrategy || (
              <Fragment>
                <Strategies strategy={strategy} onUpdate={this.setStrategy} />
                <EuiSpacer size='m' />
              </Fragment>
            )}

            <FailoverOptions
              deployment={deploymentUnderEdit}
              plan={pendingPlan}
              onChange={onWidePlanChange}
              hideExtraFailoverOptions={hideExtraFailoverOptions}
              basedOnAttempt={basedOnAttempt}
            />

            <EuiSpacer size='xl' />

            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>{this.renderSaveButton()}</EuiFlexItem>
              <EuiFlexItem grow={false}>
                <CuiRouterLinkButtonEmpty
                  to={this.getDeploymentUrl()}
                  className='editCluster-cancel'
                >
                  <FormattedMessage
                    id='edit-deployment-from-template.cancel'
                    defaultMessage='Cancel'
                  />
                </CuiRouterLinkButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>

            {updateStackDeploymentRequest.error && (
              <Fragment>
                <EuiSpacer size='m' />
                <CuiAlert type='error'>{updateStackDeploymentRequest.error}</CuiAlert>
              </Fragment>
            )}
          </EuiFlexItem>

          <EuiErrorBoundary>
            <ArchitectureSummaryComponent
              sticky={true}
              regionId={editorState.regionId}
              instanceConfigurations={instanceConfigurations}
              nodeConfigurations={this.getNodeConfigurations()}
              deploymentName={displayName}
              deploymentVersion={version}
              isTrialConverting={exceededNodes.length > 0}
              exceededTrialInstances={exceededNodes}
              resetNodeToTrial={this.resetNodeToTrial}
              shouldFetchPriceInTrial={inTrial}
              render={(className, content) => (
                <EuiFlexItem grow={false} className={className}>
                  {content}
                </EuiFlexItem>
              )}
            />
          </EuiErrorBoundary>
        </EuiFlexGroup>
      </EuiErrorBoundary>
    )
  }

  renderNodeConfigurations() {
    const { editorState, onScriptingChange, onChange, onPlanChange, instanceConfigurations } =
      this.props

    const { deployment, deploymentUnderEdit, deploymentTemplate } = editorState
    const plan = getEsPlan({ deployment })

    if (plan === null) {
      throw new Error(`Plan cannot be null`) // sanity
    }

    const versionConfig = this.getVersionConfig()

    const lastSuccessfulPlan = this.getLastSuccessfulPlan()
    const hasInitialPlan = Boolean(lastSuccessfulPlan)

    const templateInfo = deploymentTemplate || {
      instance_configurations: instanceConfigurations,
      deployment_template: undefined,
    }

    const isDeploymentTerminated =
      hasInitialPlan && isEsStopped({ deployment: deploymentUnderEdit })

    return (
      <Fragment>
        {isDeploymentTerminated && (
          <Fragment>
            <CuiAlert type='warning'>
              <FormattedMessage
                id='deployment-stopped-status.deployment-restart'
                defaultMessage='To restore this deployment with its previous configuration, use {link}'
                values={{
                  link: (
                    <Link to={this.getDeploymentUrl()}>
                      <FormattedMessage
                        id='deployment-stopped-status.manage-deployment'
                        defaultMessage='Manage deployment'
                      />
                    </Link>
                  ),
                }}
              />
            </CuiAlert>
            <EuiSpacer />
          </Fragment>
        )}
        {!isDeploymentTerminated && (
          <DeploymentInfrastructure
            showUserSettings={true}
            instanceConfigurations={instanceConfigurations}
            deployment={deployment}
            deploymentUnderEdit={deploymentUnderEdit}
            templateInfo={templateInfo}
            versionConfig={versionConfig}
            onChange={(nodeConfiguration) => (path, value) =>
              onChange(nodeConfiguration, path, value)}
            onPlanChange={onPlanChange}
            onPluginsChange={this.onPluginsChange}
            onScriptingChange={onScriptingChange}
          />
        )}
      </Fragment>
    )
  }

  renderApplyPlanModal = () => {
    const { editorState } = this.props
    const { deployment, deploymentUnderEdit } = editorState
    const deploymentId = deploymentUnderEdit.id

    const deploymentUpdateRequest = this.getUpdatePayload()

    return (
      <Fragment>
        <StackDeploymentUpdateDryRunWarnings
          deploymentId={deploymentId}
          deployment={deploymentUpdateRequest}
          spacerAfter={true}
        />

        <NodeRolesWarning deployment={deployment} deploymentUnderEdit={deploymentUnderEdit} />

        <DeploymentConfigurationChangeExplain
          deployment={deployment}
          deploymentUnderEdit={deploymentUnderEdit}
          pruneOrphans={deploymentUpdateRequest.prune_orphans}
        />
      </Fragment>
    )
  }

  renderSaveButton = () => {
    const { editorState, updateStackDeploymentRequest, inTrial, instanceConfigurations } =
      this.props

    const { deployment, deploymentUnderEdit } = editorState
    const deploymentId = deploymentUnderEdit.id

    const validationErrors = validateUpdateRequest({ deploymentUnderEdit, deployment })

    if (inTrial) {
      const exceededNodes = exceededTrialNodes({ deployment, inTrial, instanceConfigurations })

      if (exceededNodes.length > 0) {
        return (
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <CreditCardModalButton
                fill={true}
                type='full'
                onSendBillingDetailsSuccess={() => this.saveChanges()}
              >
                <FormattedMessage
                  id='deployment-create.add-billing-info'
                  defaultMessage='Save and add billing information'
                />
              </CreditCardModalButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    }

    return (
      <StackDeploymentUpdateDryRunWarningCheck deploymentId={deploymentId}>
        {({ dryRunCheckPassed }) => (
          <Fragment>
            <PermissionsGate
              permissions={[
                {
                  type: 'deployment',
                  action: 'update',
                  id: deploymentId,
                },
              ]}
            >
              {({ hasPermissions }) => (
                <DangerButton
                  data-test-id='editDeployment-submitBtn'
                  color='primary'
                  disabled={!isEmpty(validationErrors) || !hasPermissions}
                  isBusy={updateStackDeploymentRequest.inProgress}
                  fill={true}
                  onConfirm={() => this.saveChanges()}
                  modal={{
                    title: (
                      <FormattedMessage
                        id='edit-deployment.modal.title'
                        defaultMessage='Save configuration settings?'
                      />
                    ),
                    body: this.renderApplyPlanModal,
                    confirmButtonDisabled: !dryRunCheckPassed,
                    className: `editDeployment-modal`,
                  }}
                >
                  <FormattedMessage id='edit-deployment-from-template.save' defaultMessage='Save' />
                </DangerButton>
              )}
            </PermissionsGate>

            <ApiRequestExample
              method='PUT'
              endpoint={updateDeploymentUrl({ deploymentId })}
              body={this.getUpdatePayload()}
            />
          </Fragment>
        )}
      </StackDeploymentUpdateDryRunWarningCheck>
    )
  }

  getPlan = (props: AllProps = this.props): ElasticsearchClusterPlan | null => {
    const { editorState } = props
    const { deployment } = editorState
    return getEsPlan({ deployment })
  }

  getLastSuccessfulPlan = () => {
    const { editorState } = this.props
    const { deploymentUnderEdit } = editorState
    const esPlan = getSliderPlanFromGet<ElasticsearchClusterPlan>({
      sliderInstanceType: `elasticsearch`,
      deployment: deploymentUnderEdit,
      state: `last_success`,
    })

    return esPlan
  }

  getConfigurationIds(): string[] {
    const { editorState } = this.props
    const { deployment, deploymentTemplate } = editorState

    // if we have a template, source instance configurations from that
    if (deploymentTemplate) {
      const nodeConfigurations = this.getNodeConfigurationsFromTemplate()
      return getNodeConfigurationIds(nodeConfigurations)
    }

    const nodeConfigurations = getDeploymentNodeConfigurations({ deployment })

    return getNodeConfigurationIds(nodeConfigurations)

    function getNodeConfigurationIds(nodeConfigs): string[] {
      const allConfigurationIds: string[] = nodeConfigs
        .map((each) => each.instance_configuration_id)
        .filter((each) => each != null)

      return uniq(allConfigurationIds)
    }
  }

  getNodeConfigurations() {
    const { editorState } = this.props
    const { deployment } = editorState

    const plans = getSupportedSliderInstanceTypes().map((sliderInstanceType) =>
      getSliderPlan({ sliderInstanceType, deployment }),
    )

    const nodeConfigurations = flatMap(plans, (plan) => plan?.cluster_topology ?? [])

    return nodeConfigurations.filter(Boolean)
  }

  getNodeConfigurationsFromTemplate() {
    const { editorState } = this.props
    const { deploymentTemplate } = editorState

    if (!deploymentTemplate) {
      return []
    }

    return getTopologiesFromTemplate({ deploymentTemplate: deploymentTemplate.deployment_template })
  }

  resetNodeToTrial = ({ nodeConfiguration, topologyElementProp }) => {
    const { editorState, onChange } = this.props
    const { deploymentTemplate } = editorState

    if (!deploymentTemplate) {
      return
    }

    const instanceConfiguration = deploymentTemplate.instance_configurations.find(
      ({ id }) => id === nodeConfiguration.instance_configuration_id,
    )

    const plan =
      instanceConfiguration &&
      getSliderPlan({
        sliderInstanceType: instanceConfiguration.instance_type,
        deployment: editorState.deployment,
      })

    const configs: AnyTopologyElement[] = (plan && plan.cluster_topology) || []

    const config = configs.find(
      ({ instance_configuration_id }) =>
        instance_configuration_id === nodeConfiguration.instance_configuration_id,
    )

    const defaultConfig = this.getOriginalInstanceConfiguration({
      nodeConfiguration,
      instanceType: instanceConfiguration?.instance_type,
    })

    if (config === undefined) {
      return
    }

    if (topologyElementProp === `zone_count`) {
      const defaultZoneCountInNodeConfiguration = defaultConfig?.zone_count ?? 0
      const value = Math.max(defaultZoneCountInNodeConfiguration, 1)
      onChange(config, [topologyElementProp], value)
    }

    if (topologyElementProp === `size`) {
      const sizeValuePath = [`size`, `value`]
      onChange(config, sizeValuePath, defaultConfig?.size?.value)
    }
  }

  getOriginalInstanceConfiguration({ nodeConfiguration, instanceType }) {
    const { editorState } = this.props
    const { deploymentUnderEdit } = editorState
    const originalPlan = getSliderPlanFromGet<ElasticsearchClusterPlan>({
      sliderInstanceType: instanceType,
      deployment: deploymentUnderEdit,
    })

    return originalPlan?.cluster_topology.find(
      ({ instance_configuration_id }) =>
        instance_configuration_id === nodeConfiguration.instance_configuration_id,
    )
  }

  onPluginsChange = ({ plugins, path = [`elasticsearch`, `enabled_built_in_plugins`] }) => {
    this.props.onPlanChange(`elasticsearch`, path, plugins)
  }

  onChangeAutoscaling = (deployment) => {
    const { onStateChange } = this.props

    onStateChange({ deployment })
  }

  getVersion = (): string => {
    const { editorState } = this.props
    const { deploymentUnderEdit } = editorState
    const version = getVersion({ deployment: deploymentUnderEdit })!
    return version
  }

  getVersionConfig = () => {
    const {
      editorState: { deployment },
      esVersions,
    } = this.props

    const plan = getEsPlan({ deployment })
    const version = this.getVersion()

    if (!plan || !esVersions || !version) {
      return
    }

    const currentVersionConfig = esVersions.find(
      (versionConfig) => versionConfig.version === version,
    )

    return currentVersionConfig
  }

  getStrategy = (): PlanStrategy => {
    const plan = this.getPlan()

    if (!plan || !plan.transient) {
      return defaultStrategy
    }

    return plan.transient.strategy || defaultStrategy
  }

  setStrategy = (strategy: PlanStrategy) => {
    const { onStrategyChange } = this.props
    onStrategyChange(strategy)
  }

  getDeploymentUrl = () => {
    const { editorState } = this.props
    const { id } = editorState
    return deploymentUrl(id)
  }

  getUpdatePayload = () => {
    const { editorState } = this.props
    const { deployment } = editorState

    const sanitizedDeployment = sanitizeUpdateRequestBeforeSend({ deployment })

    // relocate topology-level settings to plan-level as we leave in case they
    // weren't moved at the start of editing but can be moved now
    promoteConfigurationEntriesToPlan({ deployment: sanitizedDeployment })

    return sanitizedDeployment
  }

  saveChanges() {
    const { editorState, updateDeployment } = this.props
    const { regionId, id } = editorState
    const updatePayload = this.getUpdatePayload()

    updateDeployment({
      regionId,
      deploymentId: id,
      deployment: updatePayload,
    })
  }
}

export default injectIntl(EditDeploymentFromTemplate)
