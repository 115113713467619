/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'

import type { ReactElement } from 'react'

export const EditUserAuthenticationFormRow = ({
  label,
  valueElement,
  hasLabelPadding = true,
}: {
  label: ReactElement
  valueElement: ReactElement
  hasLabelPadding?: boolean
}) => (
  <EuiFlexGroup
    css={css({
      marginTop: '32px',
    })}
  >
    <EuiFlexItem
      grow={1}
      css={css({
        paddingTop: hasLabelPadding ? '12px' : '0px',
        minWidth: '0px',
      })}
    >
      <EuiText css={css({ fontWeight: 700 })}>{label}</EuiText>
    </EuiFlexItem>
    <EuiFlexItem
      grow={2}
      css={css({
        minWidth: '0px',
      })}
    >
      {valueElement}
    </EuiFlexItem>
  </EuiFlexGroup>
)
