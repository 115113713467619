/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiComboBox, EuiSpacer, EuiText } from '@elastic/eui'

import { messages } from '../messages'
import { useSso } from '../../lib'

import type { Props } from './types'

const IdpSelection = ({ idpList }: Props) => {
  const firstLoginIdentifier = idpList[0]?.login_identifier
  const { initiateSsoRedirect } = useSso({ loginIdentifier: firstLoginIdentifier })
  const [loginIdentifier, setLoginIdentifier] = useState(firstLoginIdentifier)
  const [isRedirectingToIdp, setIsRedirectToIdp] = useState(false)

  const idpOptions = idpList.map((idp) => ({
    value: idp.login_identifier,
    label: idp.organization_name,
  }))

  const onSelectIdp = () => {
    setIsRedirectToIdp(true)
    // This timeout will help to enable the button again if user clicks back from IDP SSO page. There is no way to detect this action, even history.action === 'POP' doesn't work here.
    setTimeout(() => {
      setIsRedirectToIdp(false)
    }, 4000)
    initiateSsoRedirect(loginIdentifier)
  }

  const onChangeIdp = (selectedOptions) => {
    setLoginIdentifier(selectedOptions[0]?.value)
  }

  return (
    <Fragment>
      <EuiText size='m' color='subdued' textAlign='center'>
        <FormattedMessage {...messages.selectOrganization} />
      </EuiText>
      <EuiSpacer size='l' />
      <EuiComboBox
        options={idpOptions}
        singleSelection={{ asPlainText: true }}
        selectedOptions={idpOptions.filter((option) => option.value === loginIdentifier)}
        onChange={onChangeIdp}
        isClearable={false}
      />
      <EuiSpacer size='l' />
      <EuiText size='xs' color='subdued'>
        <FormattedMessage {...messages.onlyBelongToOneOrganization} />
      </EuiText>
      <EuiSpacer size='m' />
      <EuiButton onClick={onSelectIdp} fill={true} fullWidth={true} isLoading={isRedirectingToIdp}>
        <FormattedMessage {...messages.loginButton} />
      </EuiButton>
    </Fragment>
  )
}

export default IdpSelection
